import {
  Autocomplete,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  DeleteErrorLogs,
  fetchPostTypeListForNewswire,
  getErrorList,
  getNewswirePostsWithFilters,
  getProductList,
  getUnverifiedProducer,
} from "api/api";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";

import Button from "@mui/material/Button";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import {
  Chip,
  Divider,
  Grid,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Loader from "ui-component/Loader";
import { useNavigate, useParams } from "react-router";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  fetchOrganizationNewswirePosts,
  fetchOrganizationPlans,
} from "api/api";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import { handleDateTime } from "utils/common";

const headers = [
  { label: "Event Name", key: "eventName" },
  { label: "Event Category", key: "eventCategory" },
  { label: "Event Description", key: "eventDescription" },
  { label: "Start Date", key: "startDateTime" },
  { label: "End Date", key: "endDateTime" },
  { label: "Timezone", key: "eventTimeZone" },
  { label: "Event Privacy", key: "eventPrivacy" },
  { label: "Event Mode", key: "eventMode" },
];

export default function NewswirePost({ getIMSproductlookupData }) {
  const [columns, setColumns] = useState([]);

  const [data, setData] = useState([]);

  const [pending, setPending] = React.useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [loader,setLoading] = useState(false)
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [postTypeList, setPostTypeList] = useState([]);

  const [filterPostTypeId,setFilterPostTypeId] = useState('all');
  const [filterSearchText,setFilterSearchText] = useState('');

  const theme = useTheme();

  // Media query to check screen size, e.g., smaller screens (600px and below)
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const handleOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };


  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const fetchNewswirePosts = async () => {
    setPending(true);
    setLoading(true)
    await getNewswirePostsWithFilters(page-1,perPage,filterPostTypeId,filterSearchText)
      .then((res) => {
        setData(res.data.data);
        setTotalRows(res.data.totalCount);
      })
      .catch((err) => {});
    setPending(false);
    setLoading(false)
  };


  const subHeaderComponentMemo2 = React.useMemo(() => {
    
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            alignItems: "center",
            backgroundColor: "background.paper",
            padding: 1,
            gap: 1,
            // boxShadow: 1,
            
            // borderRadius: 2,
            // backgroundColor:'pink',
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <TextField
            id="search-posts"
            label="Search Posts by organization name & title"
            variant="outlined"
            value={filterSearchText}
            onChange={(e)=>{setFilterSearchText(e.target.value)}}
            size="medium"
            // fullWidth
            sx={{"& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "0px", // Make the border square
              }, minWidth: 300 }}
          />
          <FormControl variant="outlined" size="medium" sx={{"& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "0px", // Make the border square
              }, minWidth: 300 }}>
            <InputLabel id="post-visibility-label">Post Type</InputLabel>
            <Select
              labelId="post-visibility-label"
              id="post-visibility"
                value={filterPostTypeId}
                onChange={(e)=>{
                    setFilterPostTypeId(e.target.value)
                }}
              label="Post Visibility"
            >
                <MenuItem selected value={'all'}>All</MenuItem>
                {
                    postTypeList.length>0 && postTypeList?.map(({displayName,id,postType
                        })=>{
                        return <MenuItem value={id}>{displayName}</MenuItem>
                    })
                }
              
            </Select>
          </FormControl>
          {/* <Button variant="contained" color="primary" onClick={() => {}}>
            Search
          </Button> */}
        </Box>
      </>
    );
  }, [postTypeList,filterPostTypeId,filterSearchText]);

  // Modal Styles
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen
      ? "90%"
      : isMediumScreen
      ? "80%"
      : isLargeScreen
      ? "70%"
      : "70%",
    maxHeight: "80vh", // Prevent overflow on small screens
    minHeight: "80vh",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    overflowY: "auto", // Add scrolling for overflow content
    p: 4,
  };




  const fetchResources = async()=>{
    try {
        const postTypeResponse = await fetchPostTypeListForNewswire();
        setPostTypeList(postTypeResponse.data)
        
    } catch (error) {
        alert('getting error while fetching the resources!')
    }
  }

  useEffect(() => {
    setColumns([
      {
        name: "Post Type",
        selector: (row) => row.postType,
        sortable: false,
        maxWidth: "200px",
      },
      {
        name: "Visibility",
        selector: (row) => row.visibility,
        sortable: true,
        maxWidth: "600px",
      },
      {
        name: "Title",
        selector: (row) => row.title,
        sortable: true,
      },
      {
        name: "Created At",
        selector: (row) => handleDateTime(row.createdAt),
        sortable: true,
        maxWidth: "600px",
      },
      {
        name: "View Details",
        selector: (row) => (
          <Button variant="outlined" onClick={() => handleOpen(row)}>
            Details
          </Button>
        ),
        sortable: true,
        maxWidth: "600px",
      },
    ]);
    fetchResources()
  }, []);



  const handlePageChange = (page) => {
    setPage(page);
  };

  useEffect(() => {
    fetchNewswirePosts();
  }, [page, perPage,filterPostTypeId,filterSearchText]);

  return (
    <>
    {loader === true && <Loader/>} 
      <Box mt={1} overflow={"hidden"}>
        <DataTable
          customStyles={{
            subHeader: {
              style: {},
            },
            header: {
              style: {
                borderTopLeftRadius: "10px", // Top left corner
                borderTopRightRadius: "10px", // Top right corner
              },
            },
          }}
          title={<p>{"Newswire Posts"}</p>}
          columns={columns}
          data={data}
          pagination
          subHeader={true}
          subHeaderComponent={subHeaderComponentMemo2}
          persistTableHead
          fixedHeader
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          highlightOnHover
        />
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Stack direction="row" alignItems="center" spacing={2} mb={2}>
              <Avatar
                src={selectedRow?.images}
                alt={`image`}
                sx={{ width: 100, height: 100 }}
              />
              <Typography variant="h3">{selectedRow?.title}</Typography>
            </Stack>
          
          </Box>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            General Post Detail
          </Divider>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle1" color="text.secondary">
                Post Type:
              </Typography>
              <Typography sx={{ paddingLeft: "4px" }}>
                {selectedRow?.postType}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle1" color="text.secondary">
                Post visibility:
              </Typography>
              <Typography sx={{ paddingLeft: "4px" }}>
                {selectedRow?.visibility}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle1" color="text.secondary">
                Pinned By:
              </Typography>
              <Typography sx={{ paddingLeft: "4px" }}>
                {" "}
                {selectedRow?.pinCount}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle1" color="text.secondary">
                Created At:
              </Typography>
              <Typography sx={{ paddingLeft: "4px" }}>
                {" "}
                {handleDateTime(selectedRow?.createdAt)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle1" color="text.secondary">
                Updated At:
              </Typography>
              <Typography sx={{ paddingLeft: "4px" }}>
                {" "}
                {handleDateTime(selectedRow?.updatedAt)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle1" color="text.secondary">
                Created by:
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Typography sx={{ paddingLeft: "4px" }}>
                  {" "}
                  {selectedRow?.userName}
                </Typography>
                <Avatar alt="Remy Sharp" src={selectedRow?.userProfileImage} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="subtitle1" color="text.secondary">
                Description:
              </Typography>
              <Typography sx={{ paddingLeft: "4px" }}>
                {" "}
                {selectedRow?.description}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Divider textAlign="left" sx={{ mb: 2 }}>
                Post Categories
              </Divider>
              <Typography variant="subtitle1" color="text.secondary">
                Categories:
              </Typography>
              <Stack direction={"row"} spacing={1} sx={{ overflowX: "auto" }}>
                {selectedRow?.postCategoryList?.map((e, index) => (
                  <Chip key={index} label={e} color="primary" />
                ))}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
