import { Box, ListItem, ListItemText, ListSubheader, SpeedDial, TextField, Tooltip, Typography } from "@mui/material";
import {
  DeleteErrorLogs,
  fetchOrganizationPlans,
  get_orgUsers,
  getErrorList,
  getOrganizationPendingUsers,
  getProductList,
  getUnverifiedProducer,
} from "api/api";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { Link, useParams,useNavigate } from "react-router-dom";
import Loader from "ui-component/Loader";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";




export default function PendingUsers() {
  const [columns, setColumns] = useState([]);
  const navigate = useNavigate()
  const [data, setData] = useState([]);
  const [loader, setLoader] = React.useState(false);
  const [pending, setPending] = React.useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const [searcgOption, setSearchOption] = useState([]);
  const [searcValueByuser, setSearchValueByuser] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [metaData, setMetaData] = useState({});

  const [hoveredRow, setHoveredRow] = useState(null);

  const params = useParams()

  const SearchHandle = async (e) => {
   
  };


  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const HandleApplyFilter = async () => {
    setPending(true);
    setLoader(true);
    await getOrganizationPendingUsers(params.organizationId, page, perPage)
      .then((res) => {
        setData(res.data.data);
        setMetaData(res.data.metaData)
        setTotalRows(res.data.count);
      })
      .catch((err) => {});
    setPending(false);
    setLoader(false);
  };
  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state?.selectedRows.map((r) => r.id));
  }, []);

  

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div>
  
      </div>
    );
  }, [SearchHandle, searcValueByuser]);



  const handleDateTime = (value) => {
    const date = moment.utc(value); // Parse the input as a UTC date
    const formattedDate = date.local().format("DD-MMM-YY hh:mm Z"); // Convert to local timezone and format
    return formattedDate;
};
function getTimeDifference(endDate) {
  // Convert endDate to a moment object
  const end = moment.utc(endDate);

  // Get the current date as a moment object
  const now = moment.utc();

  // Calculate the difference in years, months, and days
  const years = end.diff(now, 'years');
  const months = end.diff(now, 'months') % 12; // remainder after years
  const days = end.diff(now, 'days') % 30; // approximate number of days, assuming 30 days in a month

  // Construct a readable format
  let result = '';

  if (years > 0) {
    result += `${years} year${years > 1 ? 's' : ''} `;
  }

  if (months > 0) {
    result += `${months} month${months > 1 ? 's' : ''} `;
  }

  if (days > 0) {
    result += `${days} day${days > 1 ? 's' : ''} `;
  }

  // Trim the result to remove any trailing spaces
  result = result.trim();

  // If there's no difference, indicate that it's today or in the past
  if (!result) {
    result = '0 days';
  }

  return result;
}

  const ExpandedComponent = ({ data }) => (
    <div
      style={{
        width: "100%",
        padding: "20px 40px",
        display: "flex",
        flexDirection: "column",
        rowGap: "10px",
      }}
    >
      {
        data.planStatus==='Expired' ? <span>
        <strong>Expired status : </strong>
        <span>{data?.expiredStatus || 'Not available'}</span>
      </span>:<></>
      }
      {
        data.planStatus==='Active' ? <span>
        <strong>Plan Ends In:</strong>
        <span>{getTimeDifference(data.endDate)}</span>
      </span>:<></>
      }
      
      
    </div>
  );

  useEffect(() => {
    setColumns([
      {
        name: "Created At",
        selector: (row,index) => handleDateTime(row.createdAt),
        sortable: false,
        maxWidth: "200px",
      },
      {
        name: "User Name",
        selector: (row) => row.name,
        sortable: true,
        maxWidth: "600px",
      },
      {
        name: "Email",
        selector: (row) => row.email,
        sortable: true,
        minWidth: "250px",
        maxWidth: "600px",
      },
      {
        name: "Type",
        selector: (row) => row.type,
        sortable: true,
        maxWidth: "600px",
        cell: row => (
          <Tooltip title={row.type==='Requested' ? 'Waiting for admin approval':'Invited by Admin'} arrow>
            <span>{row.type}</span>
          </Tooltip>
        ), 
      },
      {
        name: "Role",
        selector: (row) => row.role,
        sortable: true,
        maxWidth: "600px",
      },
      {
        name: "Job Title",
        selector: (row) => row.jobTitle,
        sortable: true,
        maxWidth: "600px",
      },
      {
        name: "Mobile Number",
        selector: (row) => row.mobileNumber,
        sortable: true,
        maxWidth: "600px",
      },
     
    ]);
  }, []);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleBack = () => {
    navigate(`/dashboard/organization-setting/${params.organizationId}`);
  };

  useEffect(() => {
    HandleApplyFilter();
  }, [page, perPage]);

  return (
    <>
      {loader === true && <Loader />}
      <Box>
      <DataTable
        title={<div>
           <ListSubheader
            style={{
              fontSize: "20px",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              width: "100px",
            }}
            onClick={() => handleBack()}
          >
            {/* <Link to={"/dashboard/user-list"}> */}
            <KeyboardBackspaceIcon /> Back
            {/* </Link> */}
          </ListSubheader>
           <p>Organization's Pending Users - {metaData?.organizationName || 'No name'} ({metaData.userCount}) </p>
          
        </div>}
        columns={columns}
        data={data}
        progressPending={pending}
        pagination
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        fixedHeader
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        highlightOnHover
        // selectableRows
        // contextActions={contextActions}
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
        // row exapndable
        expandableRows={false}
        expandableRowsComponent={ExpandedComponent}
        expandOnRowClicked={false}
        expandOnRowDoubleClicked={false}
        expandableRowsHideExpander={false}
      />
      </Box>
    </>
  );
}
