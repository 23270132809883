import React from "react";
import * as XLSX from "xlsx";
import DataViewtable from "./DataViewtable";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/system";
import { useState } from "react";
import LocationErrorViewModal from "./LocationErrorViewModal";
import {
  importConsumerLocationData,
  validationForLocationSheet,
} from "api/api";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import CheckIcon from "@mui/icons-material/Check";
import { v1 as uuidv1 } from "uuid";
import { useEffect } from "react";
import Loader from "ui-component/Loader";
import { CloudDownload, CloudUploadSharp } from "@mui/icons-material";
import ConsumerLocationImportTest from "./ConsumerLocationImportTest.xlsx";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function LocationImport() {
  const [allData, setAllData] = useState([]);
  const [missingHeadersData, setErrorheaderlist] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [excelValid, setExcelValid] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [error, setError] = useState({
    isError: false,
    message: "",
  });

  const [excelIncorrectFormat, setExcelIncorrectFormat] = useState(false);

  const navigate = useNavigate();

  const params = useParams();
  // modal
  const [open, setModalOpen] = useState(false);
  const [chunkSize, setChunkSize] = useState(50);
  const [rowData, setRowData] = useState([]);

  const [errorId, setErrorId] = useState(null);

  const [loading, setLoading] = useState(false);

  const checkLocationImport = async (data) => {
    setLoading(true);
    await validationForLocationSheet(data, params.id)
      .then((res) => {
        setExcelValid(true);
      })
      .catch((err) => {
        setIsValid(false);
        setExcelValid(false);
        const respone = err.response.data;
        setError({ isError: true, message: respone?.message });
        setAllData((pre) => {
          const updatedData = pre.map((e) => {
            const obj = {
              ...e,
              hasError: e.id === respone.id,
            };

            return obj;
          });
          return updatedData;
        });
      });
    setLoading(false);
  };

  const handleFileupload = (e) => {
    setLoading(true);
    setResponseData([]);
    setErrorId(null);
    setSubmit(false);
    setIsValid(true);
    setExcelValid(false);

    const reader = new FileReader();
    reader.readAsArrayBuffer(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const parsedData = XLSX.utils.sheet_to_json(sheet);
      // setAllData(parsedData);

      let headers = [];
      let allvalidData = [];

      for (let key in sheet) {
        if (key[0] === "!") continue;
        // parse out the column, row, and value
        const row = parseInt(key.substring(1));
        const value = sheet[key].v;

        // Store the header values
        if (row === 1) {
          headers.push(value);
        }
      }

      const defaultHeaders = [
        "LocationName",
        "ParentLocation",
        "PrimaryContact",
        "CountryCode",
        "PhoneNumber",
        "Address",
        "Direction",
        "DoorCode",
      ];

      const missingHeaders = defaultHeaders.filter(
        (header) => !headers.includes(header)
      );

      if (missingHeaders.length === 0) {
        let isMinimumOneError = false;
        for (const data of parsedData) {
          let errorList = [];
          let error = false;

          const checkLocationname = () => {
            if (!data.LocationName) {
              errorList.push("Location name required");
              error = true;
              setIsValid(false);
              isMinimumOneError = true;
              setError({isError:true,message:'Location name required.'})
            }
            if (!data.Address) {
              errorList.push("Address is required");
              error = true;
              setIsValid(false);
              isMinimumOneError = true;
              setError({isError:true,message:'Address is required.'})
            }
            if (!data.PrimaryContact) {
              errorList.push("Primary Contact is required");
              error = true;
              setIsValid(false);
              isMinimumOneError = true;
              setError({isError:true,message:'Primary Contact is required.'})
            }
            if (data?.LocationName?.includes("/")) {
              errorList.push("Remove / from location name");
              error = true;
              setIsValid(false);
              isMinimumOneError = true;
              setError({isError:true,message:'Remove / from location name.'})
            }
            if (data.PhoneNumber) {
              if (isNaN(data.PhoneNumber)) {
                errorList.push("Phone Number is not valid");
                error = true;
                setIsValid(false);
                isMinimumOneError = true;
                setError({isError:true,message:'Phone Number is not valid.'})
              }
              if (!data.CountryCode) {
                errorList.push("country code missing");
                error = true;
                setIsValid(false);
                isMinimumOneError = true;
                setError({isError:true,message:'country code missing.'})
              }
            }
            if (data.CountryCode) {
              if (!data.PhoneNumber) {
                errorList.push("Phone number missing");
                error = true;
                setIsValid(false);
                isMinimumOneError = true;
                setError({isError:true,message:'Phone number is missing.'})
              }
            }
          };
          checkLocationname();
          const currentData = {
            id: uuidv1(),
            name: data?.LocationName ? data?.LocationName.trim() : "",
            parentLocation: data.ParentLocation
              ? data.ParentLocation === "none"
                ? ""
                : data.ParentLocation
              : "",
            direction: data.Direction ? data.Direction : "",
            doorCode: data.DoorCode ? String(data.DoorCode) : "",
            primaryContact: data.PrimaryContact ? data.PrimaryContact : "",
            address: data.Address ? data.Address : "",
            CountryCode: data.CountryCode ? data.CountryCode : "",
            phone: data.PhoneNumber
              ? data.CountryCode
                ? typeof data.CountryCode === "string"
                  ? "+" +
                    data.CountryCode?.replace(/['"]+/g, "") +
                    data.PhoneNumber
                  : "+" + data.CountryCode + data.PhoneNumber
                : data.PhoneNumber
              : "",
            hasError:error,
          };

          allvalidData.push(currentData);
          setAllData((pre) => {
            return [...pre, currentData];
          });
        }

        if (!isMinimumOneError) {
          checkLocationImport(allvalidData);
        }

      } else {
        setExcelIncorrectFormat(true);
        //  alert('It looks like your sheet has some missing or incorrect columns.Don\'t worry—just download the sample format below, make the necessary edits, and try again.')
      }
    };
    setLoading(false);
  };

  const fileSubmit = async (chunkSize) => {
    setSubmit(true);
    // setProgress(0);

    const totalChunks = Math.ceil(allData?.length / chunkSize);
    const responses = [];



    let chunksSent = 0; // Variable to track the number of chunks sent successfully

    // Split data into chunks and send each chunk to the server
    for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize;
      const end = (i + 1) * chunkSize;
      const chunk = allData.slice(start, end);

      try {
        // Send chunk to the server using Axios
        const response = await importConsumerLocationData(params.id, chunk);
        responses.push(response.data);
        // chunksSent++;
        // const progress = ((i++ / totalChunks) * 100).toFixed(0);
        // console.log("progress progress", progress);
        // setProgress(progress);
      } catch (error) {
        // Handle error if request fails
        console.error("Error sending chunk:", error.message);
        // responses.push({ error: error.message });
      }
    }

    if (responses.length > 0) {
      toast.success("Location Import Success");
      setAllData([]);
      navigate("view-location");
    } else {
      toast.error("Location Import failed please try again.");
    }

    setSubmit(false);
    setResponseData(responses.flat(1));
    return responses;
  };

  const onClickDownloadSampleLocationFile = () => {
    const excelFileUrl = ConsumerLocationImportTest; // Update this path
    const anchor = document.createElement("a");
    anchor.href = excelFileUrl;
    anchor.download = "Sample-file.xlsx";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const handleResetClick = () => {
    setAllData([]);
    setExcelIncorrectFormat(false);
    setError({ isError: false, message: "" });
  };

  return (
    <div>
      {submit && <Loader />}

      {!excelValid ||
      allData?.length === 0 ||
      !isValid ||
      responseData.length > 0 ? (
        <>
          {!excelIncorrectFormat && !error.isError ? (
            <>
              <Box sx={{ background: "white", mb: 1, px: 2, py: 3 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Button
                    component="label"
                    role={undefined}
                    variant="outlined"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    // style={{ width: "25%" }}
                    color="info"
                  >
                    Upload Location file
                    <VisuallyHiddenInput
                      type="file"
                      accept=".xls,.xlsx,.csv"
                      onChange={handleFileupload}
                    />
                  </Button>
                  <Button
                    component="label"
                    role={undefined}
                    variant="outlined"
                    tabIndex={-1}
                    startIcon={<CloudDownload />}
                    color="info"
                    onClick={onClickDownloadSampleLocationFile}
                  >
                    Download Sample File
                  </Button>
                </Box>
                <Box></Box>
              </Box>
            </>
          ) : (
            <></>
          )}
        </>
      ) : !submit && isValid ? (
        <></>
      ) : (
        <></>
      )}

      {allData?.length > 0 || excelIncorrectFormat ? (
        <>
          {excelIncorrectFormat ? (
            <></>
          ) : (
            <>
              <DataViewtable
                data={allData}
                setModalOpen={setModalOpen}
                setRowData={setRowData}
                loading={loading}
                errorId={errorId}
              />
            </>
          )}
          {error?.isError ? (
            <Box py={1} >
              <Alert severity="error">{error.message+' - Don\'t worry, click on Reset button and make the necessary edits, and try again.'}</Alert>
            </Box>
          ) : (
            <></>
          )}
          {loading ? (
            <>
              <Box
                sx={{
                  width: "100%",
                  height: "50vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: "white",
                }}
              >
                <Typography variant="h4" gutterBottom>
                  Validating Excel Data
                </Typography>
                <CircularProgress />
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                  Please wait while we process the file...
                </Typography>
              </Box>
            </>
          ) : (
            <>
              {(!submit && isValid) || excelIncorrectFormat || error.isError ? (
                <>
                  <Box>
                    {excelIncorrectFormat ? (
                      <>
                        <Alert sx={{ my: 1 }} severity="error">
                          It looks like your sheet has some missing or incorrect
                          columns.Don't worry—just download the sample format,
                          make the necessary edits, and try again.
                        </Alert>
                      </>
                    ) : (
                      <></>
                    )}
                    {!submit && isValid && !excelIncorrectFormat ? (
                      <>
                        {" "}
                        <Alert sx={{ my: 1 }} severity="success">
                          Your Excel file has successfully passed all basic
                          input validations. You’re now just one step away from
                          completing the process. Click the button below to
                          upload all your locations with ease!
                        </Alert>
                      </>
                    ) : (
                      <></>
                    )}
                  </Box>
                  <Box
                    display={"flex"}
                    sx={{
                      gap: 2,
                      backgroundColor: "white",
                      justifyContent: "center",
                      p: 2,
                      borderRadius: 2,
                    }}
                  >
                    <Button
                      component="label"
                      role={undefined}
                      variant="outlined"
                      disabled={excelIncorrectFormat || error?.isError}
                      tabIndex={-1}
                      startIcon={<CloudUploadSharp />}
                      color="success"
                      onClick={() => fileSubmit(chunkSize)}
                    >
                      Submit
                    </Button>

                    <Button
                      component="label"
                      role={undefined}
                      variant="outlined"
                      tabIndex={-1}
                      startIcon={<RestartAltIcon />}
                      color="error"
                      onClick={handleResetClick}
                    >
                      Reset
                    </Button>
                  </Box>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
