import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import Sample from "views/Sample";
import ConnectionModeration from "views/ConnectionModeration";
import ExportImport from "views/Export-import";
import MessageModeration from "views/MessageModeration";
import ImsFlow from "views/ImsFlow";
import ProductManagement from "views/Product-management";
import ImsProduct from "views/ImsFlow/ImsProduct";
import UnverifiedProducerIndex from "views/Unverifies-producer";
import Request from "views/Export-import/Request";
import EventCreate from "views/Event-management/EventCreate";
import OrganizationCreate from "views/Event-management/OrganizationCreate";
import UpdateEvent from "views/Event-management/UpdateEvent";
import UserLogs from "views/Logs/UserLogs";
import OrganizationLogs from "views/Logs/OrganizationLogs";
import OrgDetails from "views/OrganizationDetails";
import FaqComponent from "views/Faq";
import ProductCategoryUrlList from "views/ProductCategoryUrl";
import ErrorLogs from "views/ErrorLogs";
import ProductImportbyProducer from "views/ProductImport/ProductImportbyProducer";
import SettingIndex from "views/Setting";
import RosterImportcomponent from "views/RosterImport";
import BlockIndex from "views/Block";
import NewswirePostManage from "views/NewswirePost";
import OrganizationUsers from "ui-component/OrganizationUsers/OrganizationUsers";
import NewswireMainPage from "ui-component/Newswire/NewswirePostMainPage";
import NewswirePostMainPage from "ui-component/Newswire/NewswirePostMainPage";
import NewswireRssFeedPage from "ui-component/Newswire/NewswireRssFeedPage";
import PendingUsers from "ui-component/PendingUsers/PendingUsers";
import RosterProductDetails from "views/RosterProductDetail";
import LocationImport from "views/RosterImport/LocationImport";
import ProductImport from "views/RosterImport/ProductImport";
import LocationViewtable from "views/RosterImport/LocationView";
import ConsumerProductViewTable from "views/RosterImport/viewProductTable";
import ProductImportV2 from "views/RosterImport/ProductImportV2";
import NewswirePost from "ui-component/NewswirePost";
import RSSFeedMainPage from "ui-component/RSSFeed";
import OrganizationManageMainPage from "ui-component/OrganizationManage v2/OrganizationManageMainPage";
import OrganizationConnectionPage from "views/organization-connections-page/OrganizationConnectionPage";
import RosterReportHomepage from "views/RosterImport/RosterReportHomepage";
// import OrganizationPlanHistoryTable from "ui-component/OrganizationPlanHistory/OrganizationPlanHistoryTable";

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);

// utilities routing
// const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
// const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
// const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(
  lazy(() => import("views/utilities/MaterialIcons"))
);
// const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import("views/sample-page")));
const UserSetting = Loadable(lazy(() => import("views/user-setting")));
const EventManagement = Loadable(lazy(() => import("views/Event-management")));
const CategoryManagement = Loadable(
  lazy(() => import("views/Category-managemnet"))
);
const FeatureVendorList2 = Loadable(
  lazy(() => import("views/Feature-vendor-list2"))
);

const OrganizationPlanHistoryTable = Loadable(lazy(() => import("ui-component/OrganizationPlanHistory/OrganizationPlanHistoryTable")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/dashboard",
  element: <MainLayout />,
  children: [
    {
      path: "",
      element: <DashboardDefault />,
    },
    {
      path: "",
      children: [
        {
          path: "",
          element: <DashboardDefault />,
        },
      ],
    },
    {
      path: "icons",
      children: [
        {
          path: "material-icons",
          element: <UtilsMaterialIcons />,
        },
      ],
    },
    {
      path: "organization-list",
      element: <SamplePage />,
    },
    {
      path: "organizations",
      element: <OrganizationManageMainPage />,
    },
    {
      path: "organization-setting/:userid",
      element: <UserSetting />,
    },
    {
      path: "feature-vendor-list",
      element: <FeatureVendorList2 />,
    },
    {
      path: "category-management",
      element: <CategoryManagement />,
    },
    {
      path: "organization-import",
      element: <ExportImport />,
    },
    // {
    //   path: "organization-management",
    //   element: <Sample />,
    // },
    {
      path: "message-moderation",
      element: <MessageModeration />,
    },
    // {
    //   path: "user-permission",
    //   element: <Sample />,
    // },
    {
      path: "connection-moderation",
      element: <ConnectionModeration />,
    },
    {
      path: "event/management",
      element: <EventManagement />,
    },
    // {
    //   path: "client/management",
    //   element: <Sample />,
    // },
    // {
    //   path: "vendor/management",
    //   element: <Sample />,
    // },
    {
      path: "product/management",
      element: <ProductManagement />,
    },
    {
      path: "ims-flow-management",
      element: <ImsFlow />,
    },
    {
      path: "ims-product-management",
      element: <ImsProduct />,
    },
    {
      path: "unverified-producer",
      element: <UnverifiedProducerIndex />,
    },
    {
      path: "request",
      element: <Request />,
    },
    {
      path: "event/create",
      element: <EventCreate />,
    },
    {
      path: "event/update",
      element: <UpdateEvent />,
    },
    {
      path: "organization/create",
      element: <OrganizationCreate />,
    },
    {
      path: "logs/user",
      element: <UserLogs />,
    },
    {
      path: "logs/organization",
      element: <OrganizationLogs />,
    },
    {
      path: "organization/details/:id",
      element: <OrgDetails />,
    },
    {
      path: "organization/:orgId/roster/product/:productId",
      element: <RosterProductDetails />,
    },
    {
      path: "faq",
      element: <FaqComponent />,
    },
    {
      path: "product/category-url/:orgid",
      element: <ProductCategoryUrlList />,
    },
    {
      path: "Error/logs",
      element: <ErrorLogs />,
    },
    {
      path: "newswire-post",
      element: <NewswirePost />,
    },
    {
      path: "rss-feed",
      element: <RSSFeedMainPage />,
    },
    {
      path: "setting",
      element: <SettingIndex />,
    },
    {
      path: "block",
      element: <BlockIndex />,
    },
    {
      path: "product/import/:id",
      element: <ProductImportbyProducer />,
    },
    {
      path: "roster/:id",
      element: <RosterImportcomponent />,
      children: [
        {
          path: "",
          element: <LocationImport />,
        },
        {
          path: "import-product",
          element: <ProductImportV2 />,
        },
        {
          path: "view-location",
          element: <LocationViewtable />,
        },
        {
          path: "view-product",
          element: <ConsumerProductViewTable />,
        },
        {
          path: "roster-report",
          element: <RosterReportHomepage />,
        },
      ],
    },
    {
      path: "newswire/manage/post",
      element: <NewswirePostManage />,
    },
    {
      path: "organization/plan/:organizationId",
      element: <OrganizationPlanHistoryTable/>,
    },
    {
      path: "organization/users/:organizationId",
      element: <OrganizationUsers/>,
    },
    {
      path: "organization/connections/:organizationId",
      element: <OrganizationConnectionPage/>,
    },
    {
      path: "organization/pending-users/:organizationId",
      element: <PendingUsers/>,
    },
    {
      path: "organization/newswire-post/:organizationId",
      element: <NewswirePostMainPage/>,
    },
    {
      path: "organization/newswire-rss-feeds/:organizationId",
      element: <NewswireRssFeedPage/>,
    },
  ],
};

export default MainRoutes;
