import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  acceptNewsWirePostByAdmin,
  deleteNewsWirePostByAdmin,
  fetchNewsWirePost,
  getUnverifiedProducer,
} from "api/api";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";

import Button from "@mui/material/Button";
import { CSVLink } from "react-csv";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { postType } from "utils/constant";
import { toast } from "react-toastify";
import Loader from "ui-component/Loader";
const headers = [
  { label: "Event Name", key: "eventName" },
  { label: "Event Category", key: "eventCategory" },
  { label: "Event Description", key: "eventDescription" },
  { label: "Start Date", key: "startDateTime" },
  { label: "End Date", key: "endDateTime" },
  { label: "Timezone", key: "eventTimeZone" },
  { label: "Event Privacy", key: "eventPrivacy" },
  { label: "Event Mode", key: "eventMode" },
];

export default function NewswirePostTable({
  setOpenModal,
  setEventId,
  setEditModal,
  updated,
  setUpdated,
  //
  getIMSproductlookupData,
  setUnverifiedId,
  setVerifyModal,
}) {
  const [columns, setColumns] = useState([]);
  const [loader, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pending, setPending] = React.useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [postTypeData, setPostType] = useState("all");

  const [searcgOption, setSearchOption] = useState([]);
  const [searcValueByuser, setSearchValueByuser] = useState("");

  const SearchHandle = async (e) => {
    if (e !== 0) {
      await getIMSproductlookupData(e)
        .then((res) => {
          setSearchOption(res.data);
        })
        .catch((err) => {});
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const HandleApplyFilter = async () => {
    setLoading(true)
    setPending(true);
    console.log(loader)
    await fetchNewsWirePost(searcValueByuser, page, perPage, "", postTypeData)
      .then((res) => {
        setData(res.data.data);
        setTotalRows(res.data.count);
      })
      .catch((err) => {});
    setPending(false);
    setLoading(false);
  };

  // const acceptPost = async (postId) => {
  //   setPending(true);
  //   await acceptNewsWirePostByAdmin(postId)
  //     .then((res) => {
  //       HandleApplyFilter();
  //       toast.success(res.data.message);
  //     })
  //     .catch((err) => {
  //       toast.error(err);
  //     });
  //   setPending(false);
  // };
  const deletePost = async (postId) => {
    setPending(true);
    await deleteNewsWirePostByAdmin(postId)
      .then((res) => {
        HandleApplyFilter();
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err);
      });
    setPending(false);
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div
        style={{
          display: "flex",
          // columnGap: "30px",
          alignItems: "center",
          width: "100%",
          justifyContent: "flex-end",
        }}
      >
        {/* <TextField
          id="outlined-basic"
          label="Search by organization name"
          variant="outlined"
          style={{
            display: "flex",
            width: "40%",

            justifyContent: "flex-start",
          }}
          // size="small"
          onChange={(e) => {
            setSearchValueByuser(e.target.value);
          }}
        /> */}

        <FormControl
          sx={{ m: 1, minWidth: "20%" }}
          // size="small"
        >
          <InputLabel id="demo-select-small-label">Post Type</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={postTypeData}
            label="Post Type"
            onChange={(e) => setPostType(e.target.value)}
          >
            {postType?.map((type) => (
              <MenuItem value={type.name}>{type.title}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* <FormControl
          sx={{ m: 1, minWidth: "20%" }}
        >
          <InputLabel id="demo-select-small-label">Post Status</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            // value={orgType}
            label="Post Status"
            // onChange={(e) => handleSelectType(e.target.value)}
          >
            <MenuItem value={"all"}>{"All"}</MenuItem>
            <MenuItem value={"new"}>{"New"}</MenuItem>
            <MenuItem value={"accepted"}>{"Accepted"}</MenuItem>
            <MenuItem value={"deleted"}>{"Deleted"}</MenuItem>
          </Select>
        </FormControl> */}

        {/* onChange={(e) => {
              setSearchValueByuser(e.target.outerText);
            }} */}
        {/* style={{
              display: "flex",
              width: "400px",
              justifyContent: "flex-start",
            }} */}

        {/* <Button
            variant="contained"
            onClick={(searcValueByuser) => HandleApplyFilter()}
            style={{ backgroundColor: "rgb(255, 102, 0)", color: "white" }}
          >
            Search
          </Button> */}
      </div>

      //   <div style={{}}>
      //     <div
      //       style={{ display: "flex", columnGap: "30px", alignItems: "center" }}
      //     >
      //       <TextField
      //         id="outlined-basic"
      //         label="Search by organization name"
      //         variant="outlined"
      //         style={{
      //           display: "flex",
      //           width: "400px",
      //           justifyContent: "flex-start",
      //         }}
      //         onChange={(e) => {
      //           setSearchValueByuser(e.target.value);
      //         }}
      //       />
      //       <Button
      //         variant="contained"
      //         onClick={(searcValueByuser) => HandleApplyFilter()}
      //         style={{ backgroundColor: "rgb(255, 102, 0)", color: "white" }}
      //       >
      //         Search
      //       </Button>

      //       <Button>Type</Button>
      //     </div>
      //   </div>
    );
  }, [SearchHandle, searcValueByuser]);

  const handleDateTime = (value) => {
    // const date = new Date(value);
    // const date = moment.tz(value, moment.tz.guess());

    const date = moment(value);
    const formattedDate = date.utc().format("DD-MMM-YY HH:mm");

    return formattedDate;
    // return moment(date).format("DD-MMM-YY HH:mm");
  };

  const ImageShow = (url) => {
    return (
      <div>
        <img
          src={url}
          alt="unverified-producer"
          style={{ width: "50px", height: "50px", objectFit: "contain" }}
        />
      </div>
    );
  };

  useEffect(() => {
    setColumns([
      {
        name: "Title",
        selector: (row) => row.title,
        sortable: true,
      },
      {
        name: "Post Type",
        selector: (row) => row.postType,
        sortable: true,
      },
      {
        name: "Post Visibility",
        selector: (row) => row.visibility,
        sortable: true,
      },

      {
        name: "Created At",
        selector: (row) => handleDateTime(row.createdAt),
        sortable: true,
      },

      {
        name: "View",
        cell: (row) => (
          // <Button
          //   variant="outlined"
          //   onClick={() => {
          //     setOpenModal(true);
          //     setUnverifiedId(row.organizationId);
          //   }}
          // >
          <RemoveRedEyeIcon
            color="primary"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setOpenModal(true);
              setUnverifiedId(row.organizationId);
            }}
          />
          // </Button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
      // {
      //   name: "Post status",
      //   cell: (row) => (
      //     <span
      //       style={{
      //         color:
      //           row.postStatus === "accepted"
      //             ? "green"
      //             : row.postStatus === "deleted"
      //             ? "red"
      //             : "black",
      //         textTransform: "capitalize",
      //       }}
      //     >
      //       {row.postStatus}
      //     </span>
      //   ),
      //   ignoreRowClick: true,
      //   allowOverflow: true,
      //   button: true,
      // },
      // {
      //   name: "Accept post",
      //   cell: (row) =>
      //     row.postStatus === "accepted" || row.postStatus === "deleted" ? (
      //       "---"
      //     ) : (
      //       <Button
      //         variant="contained"
      //         color="success"
      //         style={{ color: "white" }}
      //         onClick={() => acceptPost(row.id)}
      //       >
      //         Accept
      //       </Button>
      //     ),
      //   ignoreRowClick: true,
      //   allowOverflow: true,
      //   button: true,
      // },

      {
        name: "Hide Post",
        cell: (row) =>
          row.hideBySystemAdmin || row.userDelete ? (
            "--"
          ) : (
            <Button
              variant="contained"
              color="error"
              style={{ color: "white" }}
              onClick={() => deletePost(row.id)}
            >
              {/* <DeleteIcon /> */}
              Hide
            </Button>
          ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },

      //   {
      //     name: "Edit",
      //     cell: (row) => (
      //       <Button
      //         variant="contained"
      //         onClick={() => {
      //           setEditOpenModal(true);
      //           setUnverifiedId(row.imsProductId);
      //         }}
      //       >
      //         Edit
      //       </Button>
      //     ),
      //     ignoreRowClick: true,
      //     allowOverflow: true,
      //     button: true,
      //   },
    ]);
  }, []);

  const handlePageChange = (page) => {
    setPage(page);
  };

  useEffect(() => {
    HandleApplyFilter();
  }, [page, perPage, updated, postTypeData]);

  
  return (
    <>
      {loader === true && <Loader />}
      <DataTable
        title={
          <p style={{ fontFamily: "Roboto,sans-serif", fontWeight: "400" }}>
            {"Newswire Post "}
          </p>
        }
        columns={columns}
        data={data}
        progressPending={pending}
        pagination
        // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        fixedHeader
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        highlightOnHover
        // actions={actionsMemo}
      />
    </>
  );
}
