import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import {
  deleteProductKit,
  deleteProductSystemItem,
  fetchRosterKitByProductId,
  fetchRosterSystemComponentsByProductId,
} from "api/api";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { AlertDialog, handleDateTime } from "utils/common";
import { toast } from "react-toastify";

const InfiniteScrollAccordionSystem = ({
  count: initialCount,
  organizationId,
  productId,
}) => {
  const [accordianStatus, setAccordianStatus] = useState(false);

  const handleAccordionChange = (syntheticEvent, accordianStatus) => {
    setAccordianStatus(accordianStatus);
  };

  const [count, setCount] = useState(initialCount);

  return (
    <Accordion
      sx={{
        borderTop: "none",
        "&:before": {
          display: "none", // Removes the default top line or border
        },
      }}
      onChange={handleAccordionChange}
    >
      <AccordionSummary
        sx={{ paddingX: "0px" }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        View components ({count})
      </AccordionSummary>
      {accordianStatus ? (
        <AccordianBody
          organizationId={organizationId}
          productId={productId}
          setCount={setCount}
        />
      ) : (
        <></>
      )}
    </Accordion>
  );
};

const AccordianBody = ({ organizationId, productId, setCount }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [kitOptions, setKitOptions] = useState([
    {
      label: "Edit",
      isDisabled: true,
    },
    {
      label: "Delete",
      isDisabled: false,
    },
  ]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedAccessory, setSelectedAccessory] = React.useState(null);
  const open = Boolean(anchorEl);

  // Create state to track open menus for each button
  const [menuStates, setMenuStates] = useState({
    anchorEl: null,
    openMenuIndex: null, // Track which menu is open
  });

  const handleClick = (event, index) => {
    setMenuStates({
      anchorEl: event.currentTarget, // Set the button that was clicked
      openMenuIndex: index, // Identify which menu is open
    });
  };

  const handleClose = () => {
    setMenuStates({
      anchorEl: null,
      openMenuIndex: null, // Close all menus
    });
  };

  // Fetch data from the API
  const fetchItems = async () => {
    setLoading(true);
    const response = await fetchRosterSystemComponentsByProductId(
      organizationId,
      productId,
      page,
      pageSize
    );

    const aData = response.data?.data;
    const metaData = response.data?.metaData;

    // Append new data to items
    setData((prevItems) => [...prevItems, ...aData]);

    setPage((prevPage) => prevPage + 1); // Increment page for the next call

    // Check if no more data to load
    if (aData.length === 0 || aData.length < 10) {
      setHasMore(false);
      return;
    }
    try {
    } catch (error) {}
    setLoading(false);
  };

  const [alertDialogopen, setAlertDialogopen] = React.useState(false);
  const [alertDialogData, setAlertDialogData] = React.useState({});

  const handleSystemItemOptionClick = (option, selectedComponent) => {
    setAnchorEl(null);
    if (option === "Delete") {
      handleClickAlertDialogOpen(selectedComponent);
    }
    handleClose();
  };

  const handleClickAlertDialogOpen = (data) => {
    setAlertDialogopen(true);
    setAlertDialogData(data);
  };

  const handleCloseAlertDialog = () => {
    setAlertDialogopen(false);
    setAlertDialogData({});
  };

  const onSystemItemDeleteSuccess = (deleteSystemItemId) => {
    setData((pre) => pre.filter((e) => e.id !== deleteSystemItemId));
    setCount((pre) => pre - 1);
  };

  const handleActionsOnAlertDialog = async (data, action) => {
    switch (action) {
      case "Disagree":
        handleCloseAlertDialog();
        break;
      case "Agree":
        try {
          const response = await deleteProductSystemItem(data.id, productId);
          if (response.data.status === "success") {
            toast.success("Success", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            onSystemItemDeleteSuccess(data.id);
            handleCloseAlertDialog();
          }
        } catch (error) {
          const errorMessage = error?.response?.data?.message;
          alert(errorMessage);
        }

        break;

      default:
        alert("Action not defined!");
    }
  };

  const isComponentModule = (type) => {
    return type === "module";
  };

  const isComponentProcessor = (type) => {
    return type === "processor";
  };

  useEffect(() => {
    setIsOpen(true);
    fetchItems();
  }, []);

  return (
    <>
      <AccordionDetails sx={{ paddingX: "0px", paddingY: "0px" }}>
        {isOpen && (
          <InfiniteScroll
            initialScrollY={true}
            dataLength={data.length} // Current length of data
            next={page === 0 ? () => {} : fetchItems} // Function to fetch more data
            hasMore={hasMore} // Boolean to control fetching more data
            loader={<h3>Loading...</h3>}
            // endMessage={
            //   <Typography variant="body2" align="center" color="textSecondary">
            //     You have seen it all!
            //   </Typography>
            // }
          >
            <Box
              sx={{
                padding: 1,
                mt: 1,
              }}
            >  
              <Grid container spacing={2}>
                {data &&
                  data.map((component, index) => (
                    <Grid
                      item
                      lg={3}
                      md={6}
                      sm={12}
                      xs={12}
                      key={component.id || index}
                    >
                      <Card
                        sx={{
                          padding: 0,
                          cursor: "pointer",
                          width: "100%",
                          height: "auto",
                          // marginLeft:1,
                          // marginRight:1,
                          borderRadius: 2,
                          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                          transition: "0.3s",
                          backgroundColor: "#ffffff",
                          "&:hover": {
                            boxShadow: "0 8px 30px rgba(0, 0, 0, 0.15)",
                          },
                        }}
                      >
                        <CardContent sx={{ p: 2 }}>
                          {/* Header with Location Icon */}
                          <Box
                            display="flex"
                            justifyContent={"space-between"}
                            alignItems="center"
                          >
                            <Box display="flex" alignItems="center" mb={2}>
                              <Box>
                                {/* <Chip
                                  label={`Components #${index + 1}`}
                                  size="small"
                                  color="primary"
                                ></Chip> */}
                                <Chip
                                  label={isComponentModule(component?.type) ? `${String(component?.type).toUpperCase()}`:String(component?.type).toUpperCase()}
                                  size="small"
                                  color={'info'}
                                ></Chip>
                                {
                                    isComponentModule(component?.type) ? <> <Chip
                                    label={`${component?.lengthSize}mm x ${component?.widthSize}mm`}
                                    size="small"
                                    color={'default'}
                                  ></Chip></>:<></>
                                }
                              </Box>
                            </Box>
                            <Box> 
                              {" "}
                              <IconButton
                                aria-label="more"
                                id={`basic-button-${index}`}
                                aria-controls={
                                  menuStates.openMenuIndex === index
                                    ? `basic-menu-${index}`
                                    : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={
                                  menuStates.openMenuIndex === index
                                    ? "true"
                                    : undefined
                                }
                                onClick={(event) => handleClick(event, index)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </Box>
                          </Box>
                          <Divider />

                          <Box
                            mt={1}
                            p={1}
                            border={1}
                            borderRadius={0}
                            borderColor="grey.300"
                          >
                            <Box display="flex" flexDirection="column" gap={1}>
                            {/* {
                                isComponentModule(component?.type) ? <><Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  {isComponentModule(component?.type) ? "Module Dimension" : ""}
                                </Typography>
                                <Typography variant="body1" fontWeight="bold">
                                  {isComponentModule(component?.type)
                                    ? `${component?.lengthSize}mm x ${component?.widthSize}mm`
                                    : ``}
                                </Typography>
                              </Box></>:<></>
                            } */}
                              
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  Initially In Use:
                                </Typography>
                                <Typography variant="body1" fontWeight="bold">
                                  {component?.ps1}
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  Initially In Storage/Spare:
                                </Typography>
                                <Typography variant="body1" fontWeight="bold">
                                  {component?.ps3}
                                </Typography>
                              </Box>
                              {
                                isComponentProcessor(component?.type) ? <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  System Detailed:
                                </Typography>
                                <Typography variant="body1" fontWeight="bold">
                                  {component?.systemDetailedCount}
                                </Typography>
                              </Box>:<></>
                              }
                              {
                                isComponentModule(component?.type) ?   <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  System Detailed:
                                </Typography>
                                <Typography variant="body1" fontWeight="bold">
                                  {component?.systemDetailedCount}
                                </Typography>
                              </Box>:<></>
                              }
                            
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  Created At:
                                </Typography>
                                <Typography variant="body1" fontWeight="bold">
                                  {handleDateTime(component?.createdAt)}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                      <Menu
                        id={`basic-menu-${index}`} // Unique ID for each Menu
                        anchorEl={menuStates.anchorEl}
                        open={menuStates.openMenuIndex === index} // Open only if the corresponding button was clicked
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": `basic-button-${index}`,
                        }}
                      >
                        <MenuItem disabled onClick={handleClose}>
                          Edit
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleSystemItemOptionClick("Delete", component);
                          }}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </InfiniteScroll>
        )}
      </AccordionDetails>

      <AlertDialog
        title={`System component Delete Confirmation`}
        content={
          "Please confirm your action."
        }
        onClose={handleCloseAlertDialog}
        open={alertDialogopen}
        actions={[
          {
            label: "No",
            onClick: handleActionsOnAlertDialog,
            action: "Disagree",
          },
          {
            label: "Yes",
            onClick: handleActionsOnAlertDialog,
            autoFocus: true,
            action: "Agree",
          },
        ]}
        data={alertDialogData}
      />
    </>
  );
};

export default InfiniteScrollAccordionSystem;
