import React from "react";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router";
import {
  activateOrganizationByAdmin,
  deactivateOrganizationByAdmin,
  deleteAccount,
  FetchOrgDetailsList,
  rosterProductDetails,
} from "api/api";
import { useState } from "react";
import {
  Alert,
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Loader from "ui-component/Loader";
import {
  formatBytes,
  handleDateTime,
  isWarrantyExpired,
  modalStyleForImageView,
} from "utils/common";
import { organizationActiveStatuses, rosterProductTypes } from "utils/constant";
import { toast } from "react-toastify";
import moment from "moment";
import InfiniteScrollAccordionKit from "./roster-product-details/InfiniteScrollAccordionKit";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfiniteScrollAccordionSystem from "./roster-product-details/InfiniteScrollAccordianSystem";
import InfiniteScrollAccordionGroup from "./roster-product-details/infiniteScrollAccordingGroup";

function RosterProductDetails() {
  const params = useParams();
  const [productData, setProductData] = useState({});
  const [loader, setLoader] = useState(true);

  const [openDialogue, setOpenDialogue] = useState(false);
  const [dialogueData, setDialogueData] = useState({});

  const [actions, setActions] = useState({
    deactivateOrganizationAccount: `deactivateOrganizationAccount`,
    activateOrganizationAccount: `activateOrganizationAccount`,
  });

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        color: "white",
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  const handleOpenDialogue = (orgData, action) => {
    switch (action) {
      case actions.activateOrganizationAccount:
        setDialogueData({
          expectedLabel: `Activate organization ${orgData.name}`,
          action,
          showAlert: false,
          severity: "error",
          alertText: ``,
          organizationId: orgData?.organizationId || null,
          dialogueStatus: dialogueStatuses.activateOrganizationAccount,
        });
        break;

      case actions.deactivateOrganizationAccount:
        setDialogueData({
          expectedLabel: `Deactivate organization ${orgData.name}`,
          action,
          showAlert: false,
          severity: "error",
          alertText: ``,
          organizationId: orgData?.organizationId || null,
          dialogueStatus: dialogueStatuses.deactivateOrganizationAccount,
        });
        break;

      default:
        break;
    }
    setOpenDialogue(true);
  };

  const dialogueStatuses = {
    deactivateOrganizationAccount: `deactivateOrganizationAccount`,
    activateOrganizationAccount: `activateOrganizationAccount`,
  };

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
    setDialogueData({});
  };
  const profileImage =
    "https://www.pngall.com/wp-content/uploads/5/Profile-PNG-Images.png";

  const fetchRosterProductDetails = async () => {
    await rosterProductDetails(params?.orgId, params?.productId)
      .then((res) => {
        setProductData(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log("fetch org details err", err);
      });
  };

  const avatarTypes = {
    organizationProfileImage: `organizationProfileImage`,
  };

  const handleAvatarClick = (url, inputAvatarTypes) => {
    switch (inputAvatarTypes) {
      case avatarTypes.organizationProfileImage:
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    fetchRosterProductDetails();
  }, []);

  return (
    <>
      {loader === true && <Loader />}
      {loader ? (
        <>
          <Box sx={{ backgroundColor: "white", padding: "10px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Stack direction="row" alignItems="center" spacing={2} mb={2}>
                <Skeleton variant="circular" width={60} height={60} />
                <Typography variant="h1">
                  {loader ? <Skeleton width={200} /> : "h1"}
                </Typography>
              </Stack>
              {/* <Stack direction="row" alignItems="center" spacing={2} mb={2}>
                <Skeleton variant="circular" width={60} height={60} />
                <Typography variant="h1">{loader ? <Skeleton width={200} /> : 'h1'}</Typography>
                </Stack> */}
            </Box>
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              width={"100%"}
              height={60}
            />
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              width={"100%"}
              height={60}
            />
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              width={"100%"}
              height={60}
            />
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              width={"100%"}
              height={60}
            />
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              width={"100%"}
              height={60}
            />
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              width={"100%"}
              height={60}
            />
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              width={"100%"}
              height={60}
            />
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ backgroundColor: "white", padding: "10px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Stack direction="row" alignItems="center" spacing={2} mb={2}>
                <Avatar
                  src={productData?.category?.icon}
                  alt={""}
                  sx={{
                    width: 70,
                    height: 70,
                    backgroundColor: "transparent",
                    padding: 1,
                    border: "2px solid",
                  }}
                />
                <Typography variant="h4">{productData.productName}</Typography>
                <Chip
                  color="primary"
                  label={productData.productTypeDisplayName}
                ></Chip>
                {isWarrantyExpired(productData?.warrantyEndDate) ? (
                  <Chip color="warning" label={"Warranty Expired"}></Chip>
                ) : (
                  <></>
                )}
                {!productData?.warrantyEndDate ? (
                  <Chip color="warning" label={"Unknown Warranty Date"}></Chip>
                ) : (
                  <></>
                )}
              </Stack>
              <Stack direction="row" alignItems="center" spacing={2} mb={2}>
                <Typography variant="h4">
                  {productData.organization.name}
                </Typography>
                <Avatar
                  src={
                    productData.organization?.organizationDetails?.profileImage
                  }
                  alt={""}
                  sx={{ width: 60, height: 60, cursor: "pointer" }}
                />
              </Stack>
            </Box>

            <Divider textAlign="left" sx={{ mb: 2 }}>
              Basic detail
            </Divider>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Product Name:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {productData.productName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Category:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {productData.category?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Serial Number:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {productData.serialNumber}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Asset Id/Tag No:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {productData.assetIdTagNumber}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Manufacturer:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {productData?.manufacturerName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Version:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {productData?.version}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Manufacturer Product Id:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {productData.manufacturerProductId}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Vendor Product Id:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {productData.vendorProductId}
                </Typography>
              </Grid>
            </Grid>

            <Divider textAlign="left" sx={{ mb: 2, mt: 2 }}>
              Location
            </Divider>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="subtitle1" color="text.secondary">
                Location:
              </Typography>
              <Typography
                sx={{
                  paddingLeft: "4px",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {productData?.locationName}
              </Typography>
            </Grid>

            <Divider textAlign="left" sx={{ mb: 2, mt: 2 }}>
              Status
            </Divider>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Purchase Price Type:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {productData?.purchasePriceType}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Purchase Date:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {handleDateTime(productData?.purchaseDate)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Purchase Price:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {productData?.purchasePrice}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Warranty Start Date:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {handleDateTime(productData?.warrantyStartDate)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Warranty End Date:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {handleDateTime(productData?.warrantyEndDate)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Expected Replacement Date:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {handleDateTime(productData?.expectedReplacementDate)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  End of Life Date:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {handleDateTime(productData?.endOfLifeDate)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Status:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {productData?.status}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Active:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {productData?.active ? "Yes" : "No"}
                </Typography>
              </Grid>
            </Grid>

            <Divider textAlign="left" sx={{ mb: 2, mt: 2 }}>
              Organization details
            </Divider>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Vendor:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {productData?.vendorName || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Integrator:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {productData?.integratorName || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Consultant:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {productData?.manufacturerName || "N/A"}
                </Typography>
              </Grid>
            </Grid>

            {
              // Kit
              productData?.productType === rosterProductTypes.IMSPT3 ? (
                <>
                  <Divider textAlign="left" sx={{ mb: 2, mt: 2 }}>
                    Kit Accessories
                  </Divider>
                  <InfiniteScrollAccordionKit
                    accessoryCount={productData?.totalKitAccessories}
                    organizationId={params?.orgId}
                    productId={params?.productId}
                  />
                </>
              ) : (
                <></>
              )
            }
            {
              // System
              productData?.productType === rosterProductTypes.IMSPT6 ? (
                <>
                  

                  <Divider textAlign="left" sx={{ mb: 2, mt: 2 }}>
                    System Details
                  </Divider>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <Typography variant="subtitle1" color="text.secondary">
                        Pixel Pitch:
                      </Typography>
                      <Typography
                        sx={{
                          paddingLeft: "4px",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        {productData?.pixelPitch}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <Typography variant="subtitle1" color="text.secondary">
                        Serviced By:
                      </Typography>
                      <Typography
                        sx={{
                          paddingLeft: "4px",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                       {productData?.servicedBy}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <Typography variant="subtitle1" color="text.secondary">
                        Technology:
                      </Typography>
                      <Typography
                        sx={{
                          paddingLeft: "4px", 
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                       {productData?.technology}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider textAlign="left" sx={{ mb: 2, mt: 2 }}>
                    System Components
                  </Divider>
                  <InfiniteScrollAccordionSystem
                    count={productData?.totalSystemComponents}
                    organizationId={params?.orgId}
                    productId={params?.productId}
                  />
                </>
              ) : (
                <></>
              )
            }
            {
              // Group
              productData?.productType === rosterProductTypes.IMSPT5 ? (
                <>
                  <Divider textAlign="left" sx={{ mb: 2, mt: 2 }}>
                    Group Components
                  </Divider>
                  <InfiniteScrollAccordionGroup
                    count={productData?.totalGroupComponents}
                    organizationId={params?.orgId}
                    productId={params?.productId}
                  />
                </>
              ) : (
                <></>
              )
            }

            {
              // Software License
              productData?.productType === rosterProductTypes.IMSPT4 ? (
                <>
                  <Divider textAlign="left" sx={{ mb: 2, mt: 2 }}>
                    Software License
                  </Divider>
                  <Accordion
                    sx={{
                      borderTop: "none",
                      "&:before": {
                        display: "none", // Removes the default top line or border
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>Software License</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                          <Typography
                            variant="subtitle1"
                            color="text.secondary"
                          >
                            Name of License:
                          </Typography>
                          <Typography
                            sx={{
                              paddingLeft: "4px",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                          >
                            {productData?.licenseName}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                          <Typography
                            variant="subtitle1"
                            color="text.secondary"
                          >
                            Number of License:
                          </Typography>
                          <Typography
                            sx={{
                              paddingLeft: "4px",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                          >
                            {productData?.numberOfLicense}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                          <Typography
                            variant="subtitle1"
                            color="text.secondary"
                          >
                            Renew Type:
                          </Typography>
                          <Typography
                            sx={{
                              paddingLeft: "4px",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                          >
                            {productData?.renewingType}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                          <Typography
                            variant="subtitle1"
                            color="text.secondary"
                          >
                            Type of License:
                          </Typography>
                          <Typography
                            sx={{
                              paddingLeft: "4px",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                          >
                            {productData?.typeOfLicense}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                          <Typography
                            variant="subtitle1"
                            color="text.secondary"
                          >
                            Set to Auto-Renew:
                          </Typography>
                          <Typography
                            sx={{
                              paddingLeft: "4px",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                          >
                            {productData?.setToAutoRenew ? "Yes" : "No"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </>
              ) : (
                <></>
              )
            }
            {
              // Part
              productData?.productType === rosterProductTypes.IMSPT2 ? (
                <>
                  <Divider textAlign="left" sx={{ mb: 2, mt: 2 }}>
                    Part Details
                  </Divider>
                  <Accordion
                    sx={{
                      borderTop: "none",
                      "&:before": {
                        display: "none", // Removes the default top line or border
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>Part</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                          <Typography
                            variant="subtitle1"
                            color="text.secondary"
                          >
                            Number of Parts/Quantity:
                          </Typography>
                          <Typography
                            sx={{
                              paddingLeft: "4px",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                          >
                            {productData?.numberOfParts}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </>
              ) : (
                <></>
              )
            }
          </Box>

          {/* <Dialog
        open={openDialogue}
        onClose={handleCloseDialogue}
        sx={{width:'800px',margin:'auto'}}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const confirmationTextByUser = formJson.confirmationText;
            const expectedConfirmationText = dialogueData.expectedLabel
            if(expectedConfirmationText===confirmationTextByUser){

              // On text matches..........

              // deactivateOrganizationAccount
              if(dialogueData.dialogueStatus===dialogueStatuses.deactivateOrganizationAccount){
                // API Call to delete account 
                const deactivateOrganizationAccountAPICall = async () => {
                  setLoader(true);
                  await deactivateOrganizationByAdmin(dialogueData?.organizationId)
                    .then((res) => {
                      if(res.data && res.data.status==='success'){
                        toast.success(`Deactivated successfully!`, {
                          position: "top-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "colored",
                        });
                        // this is just for refreshing the model
                        // fetchOrgDetails();
                      }else{
                        toast.error(`Not able to deactivate account!`, {
                          position: "top-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "colored",
                        });
                      }
                    })
                    .catch((err) => {
                      toast.error(`Not able to deactivate account!`, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                      });
                    });
                  // setLoader(false);
                };

                deactivateOrganizationAccountAPICall().then(()=>{}).catch(()=>{}).finally(()=>{
                  handleCloseDialogue(); 
                })
              }

               // deactivateOrganizationAccount
               if(dialogueData.dialogueStatus===dialogueStatuses.activateOrganizationAccount){
                // API Call to delete account 
                const activateOrganizationAccountAPICall = async () => {
                  setLoader(true);
                  await activateOrganizationByAdmin(dialogueData?.organizationId)
                    .then((res) => {
                      if(res.data && res.data.status==='success'){
                        toast.success(`Activated successfully!`, {
                          position: "top-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "colored",
                        });
                        // this is just for refreshing the model
                        // fetchOrgDetails();
                      }else{
                        toast.error(`Not able to activate account!`, {
                          position: "top-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "colored",
                        });
                      }
                    })
                    .catch((err) => {
                      toast.error(`Not able to activate account!`, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                      });
                    });
                  // setLoader(false);
                };

                activateOrganizationAccountAPICall().then(()=>{}).catch(()=>{}).finally(()=>{
                  handleCloseDialogue(); 
                })
              }
       

            }else{
              toast.error(`Hmm, Wrong text`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          },
        }}
      > 

        {
          loader ? <></>:<>
           {
            dialogueData.showAlert ? <Alert sx={{fontSize:'20px'}} severity={dialogueData.severity} >{dialogueData.alertText}</Alert>:<></>
        } 
        <DialogTitle fontSize={'20px'}> {dialogueData.expectedLabel} </DialogTitle>
        <DialogContent>
          <DialogContentText fontSize={'15px'}>
          To verify, type  <span style={{color:'red'}}>{dialogueData.expectedLabel}</span> below:
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="confirmationText" 
            label=""
            type="text"
            fullWidth
            variant="standard"
          />
        
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogue}>Cancel</Button>
          <Button type="submit">{loader ? 'Validating...':'Submit'}</Button>
        </DialogActions>
          </>
        }

       
          </Dialog> */}
        </>
      )}
    </>
  );
}

export default RosterProductDetails;
