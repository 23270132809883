import { borderRadius } from "@mui/system";
import axios from "axios";
import moment from "moment";
import validator from "validator";
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { rosterProductImportExcelSheetProductType, rosterProductTypes } from "./constant";


export const handleDateTime = (value) => {
    if(!value){
      return 'N/A'
    }
    const date = moment.utc(value); // Parse the input as a UTC date
    const formattedDate = date.local().format("DD-MMM-YY hh:mm Z"); // Convert to local timezone and format
    return formattedDate;
};

export const formatBytes = (bytes) => {
    if (bytes === 0) return '0 B';
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
};

export function AlertDialog({ 
    open, 
    onClose, 
    title, 
    content, 
    actions,
    data
  }) {
    return (
      <Dialog
      sx={{
        '& .MuiDialog-paper': {
          width: '400px !important', // Set width
          height: '200px', // Set height
          maxWidth: 'none', // Prevent max-width overriding the width
        },
      }}
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{ cursor: 'move' }} fontSize={'22px'} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText fontSize={'18px'}>
           {content}
          </DialogContentText>
          </DialogContent>
        <DialogActions>
          {actions?.map((action, index) => (
            <Button key={index} onClick={()=>{action.onClick(data,action.action)}} autoFocus={action.autoFocus}>
              {action.label}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    );
  }

  // Function to check if the warranty date is expired (input is a UTC date)
export const isWarrantyExpired = (warrantyDate) => {
  // Parse the warranty date as UTC and compare it with the current date in UTC
  return moment.utc().isAfter(moment.utc(warrantyDate));
};


export const getDatabaseLabelForRosterProductType=(input)=>{
  switch(input){
    case rosterProductImportExcelSheetProductType.Single_Product: return rosterProductTypes.IMSPT1;
    case rosterProductImportExcelSheetProductType.Part: return rosterProductTypes.IMSPT2;
    case rosterProductImportExcelSheetProductType.Kit: return rosterProductTypes.IMSPT3;
    case rosterProductImportExcelSheetProductType.Software_License: return rosterProductTypes.IMSPT4;
    case rosterProductImportExcelSheetProductType.Group: return rosterProductTypes.IMSPT5;
    case rosterProductImportExcelSheetProductType.System: return rosterProductTypes.IMSPT6;
    default: return null;
  }
}

export const getExcelSheetLabelForRosterProductType=(input)=>{
  switch(input){
    case rosterProductTypes.IMSPT1: return rosterProductImportExcelSheetProductType.Single_Product;
    case rosterProductTypes.IMSPT2: return rosterProductImportExcelSheetProductType.Part;
    case rosterProductTypes.IMSPT3: return rosterProductImportExcelSheetProductType.Kit;
    case rosterProductTypes.IMSPT4: return rosterProductImportExcelSheetProductType.Software_License;
    case rosterProductTypes.IMSPT5: return rosterProductImportExcelSheetProductType.Group;
    case rosterProductTypes.IMSPT6: return rosterProductImportExcelSheetProductType.System;
    default: return null;
  }
}

export const getRosterProdutStatusCode=(displayName)=>{
  const statusCodes = {
      'New': 'PS1',
      'In Use': 'PS2',
      'Out for Service': 'PS3',
      'Storage/Spare': 'PS4',
      'Removed': 'PS5',
      'List for Sale': 'PS6',
      'List for Donation or Recycle': 'PS7'
  };

  return statusCodes[displayName] || 'Invalid Display Name';
}