import { Avatar, Box, Typography } from "@mui/material";
import { get_connection } from "api/api";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { handleDateTime } from "utils/common";

const OrganizationConnectionPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [columns, setColumns] = useState([]);
  const [metaData, setMetaData] = useState({});

  const navigate = useNavigate();

  const params = useParams();

  useEffect(() => {
    setColumns([
      {
        name: "Profile",
        selector: (row) => (
          <Avatar
            src={row.profileImage} // Adjust this key based on your data
            alt="Profile"
            sx={{ width: 40, height: 40 }}
          />
        ),
        maxWidth: "80px",
      },
      {
        name: "Organization Name",
        selector: (row) => row.name,
        sortable: false,
        maxWidth: "200px",
      },
      {
        name: "Role",
        selector: (row) => row.role,
        sortable: true,
        maxWidth: "200px",
      },
      {
        name: "Sub Role",
        selector: (row) => row.subRole,
        sortable: true,
        maxWidth: "200px",
      },
      {
        name: "Organization Created At",
        selector: (row) => handleDateTime(row.createdAt),
        sortable: true,
        maxWidth: "200px",
      },
      {
        name: "Connected Date",
        selector: (row) => handleDateTime(row.connectedDate),
        sortable: true,
        maxWidth: "200px",
      },
    ]);
  }, []);

  useEffect(() => {
    fetchData();
  }, [pageSize, pageNumber]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await get_connection(
        params?.organizationId,
        pageNumber,
        pageSize
      );

      setData(response.data.data);
      setTotalRows(response.data.count);
      setMetaData(response.data.metaData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const onChangeRowsPerPage = (e) => {
    setPageSize(e);
  };

  const handleOnChangePage = (e) => {
    setPageNumber(e);
  };

  const handleRowClick = (row)=>{
    navigate(`/dashboard/organization/details/${row.id}`)
  }

  return (
    <>
      <Box>
        <DataTable
          customStyles={{
            subHeader: {
              style: {},
            },
            header: {
              style: {},
            },
          }}
          title={
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h2">
                Connections of {metaData?.organizationName}
              </Typography>
              <Avatar
                src={metaData?.organizationProfileImage}
                alt="image not found"
              />
            </Box>
          }
          columns={columns}
          data={data}
          progressPending={loading} // Show loader while loading
          pagination // Enable pagination
          highlightOnHover
          pointerOnHover
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={onChangeRowsPerPage}
          paginationServer
          onChangePage={handleOnChangePage}
          persistTableHead
          fixedHeader
          onRowClicked={handleRowClick}
        />
      </Box>
    </>
  );
};

export default OrganizationConnectionPage;
