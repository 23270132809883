import React from "react";
import ProductDropzone from "./dropzone";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useState } from "react";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import DataTable from "./DataTable";
import DownloadIcon from "@mui/icons-material/Download";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SampleData from "./Product Listings Import Tool.xlsx";
import ProductViewModal from "./ProductViewModal";
import { Box } from "@mui/system";
import { Alert, LinearProgress, Tab, Tabs, Typography } from "@mui/material";
import ViewProductTable from "./ViewProduct";
import { v1 as uuidv1 } from "uuid";
import {
  fetchProductAllDataForSkipDuplicate,
  getProductCategoryForProducer,
  importProducerProduct,
} from "api/api";
import { useParams } from "react-router";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";
import ImageUploadModal from "./ImagFolderUploadModal";
import Loader from "ui-component/Loader";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "90%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}% uploaded`}</Typography>
      </Box>
    </Box>
  );
}

export default function ProductImportbyProducer() {
  const [totalNo, setTotalNoOfData] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [openImageupload, setOpenImageUpload] = React.useState(false);
  const [submit, setSubmit] = React.useState(false);
  const [isValid, setIsValid] = React.useState(true);

  const [categoryAllData, setCategory] = React.useState(null);
  const [errorCount, setErrorCount] = React.useState(0);
  const [errorHeaderList, setErrorheaderlist] = React.useState([]);
  const [singleProduct, setSingleProduct] = React.useState([]);

  const [uploadResponse, setResponse] = React.useState([]);
  const [duplicateData, setDuplicateData] = React.useState([]);
  const [rawDataCount, setRawDataCount] = React.useState(0);
  const [allProductDataForSkipDuplicate, setAllproductDataForSkipDuplicate] =
    React.useState([]);

  const params = useParams();

  const [value, setValue] = React.useState(0);
  const [chunkSize, setChunkSize] = React.useState(10);

  const [data, setData] = useState([]);
  const [allInvalidData, setAllInvalidData] = useState([]);

  const [imageuploadResponse, setImageuploadResponse] = useState([]);

  const [files, setFiles] = useState([]);

  // set final data
  const [finalvalidData, setFinalData] = useState([]);

  const [progress, setProgress] = React.useState(0);

  const [clearData, setClearData] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  // dropzone

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the acceptedFiles
    console.log(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noKeyboard: true, // Disable keyboard interaction
    noClick: true, // Disable clicking to open file dialog
  });

  const getProductAllDataForSkipDuplicate = async () => {
    try {
      const response = await fetchProductAllDataForSkipDuplicate(params?.id);
      console.log("response data", response.data);
      setAllproductDataForSkipDuplicate(response.data.data);
      return response.data.data;
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleFileUploadNew = async (e) => {
    setLoading(true);
    setIsValid(true);
    setErrorCount(0);
    setRawDataCount(0);
    setResponse([]);
    setErrorheaderlist([]);
    setData([]);
    setDuplicateData([]);

    const skipData = await getProductAllDataForSkipDuplicate();

    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const parsedData = XLSX.utils.sheet_to_json(sheet);
      const headers = [];

      const addDuplicateData = [];

      console.log("parsedData", parsedData);
      console.log("parsedData?.length", parsedData?.length);

      setRawDataCount(parsedData?.length);

      const SkipDuplicateData = parsedData?.filter((rawData) => {
        if (
          !skipData.some(
            (skipData) =>
              skipData.model === rawData["Version/Code"]?.toString() &&
              skipData.name === rawData["Name/Model"]?.toString() &&
              skipData.category === rawData.category
          )
        ) {
          return true;
        } else {
          addDuplicateData.push(rawData);
        }

        return false;
      });

      setDuplicateData(addDuplicateData);

      for (let key in sheet) {
        if (key[0] === "!") continue;
        // parse out the column, row, and value
        const row = parseInt(key.substring(1));
        const value = sheet[key].v;

        // Store the header values
        if (row === 1) {
          headers.push(value);
        }
      }

      let allData = [];

      const defaultHeaders = [
        "Name/Model",
        "Version/Code",
        "category",
        "Consults",
        "Installs",
        "Integrates",
        "Makes",
        "Repairs",
        "Sells",
        "image_one",
        "image_two",
        "image_three",
        "image_four",
        "image_five",
        "specs_link",
        "description",
        "details_link",
        "summary",
      ];

      const missingHeaders = defaultHeaders.filter(
        (header) => !headers.includes(header)
      );

      if (missingHeaders.length === 0) {
        for (const data of parsedData) {
          let errorStatus = false;
          let errorList = [];
          let matchedUserData = [];

          // const dataCheck = categoryAllData.forEach((category) => {
          //   if (data.category === category) {
          //     matchedUserData.push(data);
          //   }
          // });

          if (data) {
            if (
              data["Name/Model"]?.length > 2 &&
              data["Name/Model"]?.length <= 50 &&
              data["Version/Code"]?.length <= 50 &&
              data?.description?.length <= 2500 &&
              data?.summary?.length <= 100
            ) {
            } else {
              if (
                data?.description === undefined ||
                data?.summary === undefined
              ) {
              }
              if (
                data["Name/Model"]?.length < 2 ||
                data["Name/Model"]?.length > 50 ||
                data["Version/Code"]?.length > 50
              ) {
                setIsValid(false);
                errorStatus = true;
              }
              if (
                !data["Name/Model"] ||
                !data["Name/Model"] ||
                !data["Version/Code"]
              ) {
                setIsValid(false);
                errorStatus = true;
              }
            }
            if (data.category) {
              if (!categoryAllData.includes(data.category)) {
                errorList.push("Category not matched!");
                setIsValid(false);
                errorStatus = true;
              }
            }

            const checktags = [
              data.Consults === "Yes" && "Consults",
              data.Installs === "Yes" && "Installs",
              data.Integrates === "Yes" && "Integrates",
              data.Makes === "Yes" && "Makes",
              data.Repairs === "Yes" && "Repairs",
              data.Sells === "Yes" && "Sells",
              data.Rents === "Yes" && "Rents",
            ].filter((tag) => tag !== false);

            if (!checktags.length > 0) {
              setIsValid(false);

              errorStatus = true;
            }

            allData.push({
              productUUID: uuidv1(),
              category: data.category,
              description: data?.description
                ? data.description?.toString()
                : "",
              webLink: data?.details_link ? data?.details_link?.toString() : "",
              images: [
                data.image_one,
                data.image_two,
                data.image_three,
                data.image_four,
                data.image_five,
              ].filter((image) => image !== undefined),
              modelNumber: data["Version/Code"]
                ? data["Version/Code"]?.toString()
                : "",
              name: data["Name/Model"] ? data["Name/Model"]?.toString() : "",
              specsLink: data.specs_link ? data.specs_link?.toString() : "",
              summary: data.summary ? data.summary?.toString() : "",
              tags: [
                data.Consults === "Yes" && "Consults",
                data.Installs === "Yes" && "Installs",
                data.Integrates === "Yes" && "Integrates",
                data.Makes === "Yes" && "Makes",
                data.Repairs === "Yes" && "Repairs",
                data.Sells === "Yes" && "Sells",
                data.Rents === "Yes" && "Rents",
              ].filter((tag) => tag !== false),

              error: errorStatus,
              errorList: errorList,
            });
          }
        }

        console.log("all data", allData);

        const getErrorCount = allData?.filter((data) => data.error === true);

        setErrorCount(getErrorCount.length);

        setData(allData);
      } else {
        setErrorheaderlist(missingHeaders);
      }
    };
    setLoading(false);
  };

  const getProductCategory = async () => {
    try {
      const response = await getProductCategoryForProducer();

      let categoryData = [];
      for (const category of response.data) {
        categoryData.push(category.name);
      }
      setCategory(categoryData);
    } catch (error) {}
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setData(file);

    Papa.parse(file, {
      complete: (result) => {
        // 'result.data' contains the parsed data as an array of objects
        const parsedData = result.data;
        console.log("parse data", parsedData);
        if (parsedData[0].length > 12) {
          alert("Format mismatched, please check and retry!");
          return;
        }

        setTotalNoOfData(parsedData.length);
      },
    });
  };

  const downloadSampleData = () => {
    // Replace 'your-excel-file.xlsx' with the actual path or URL of your Excel file
    const excelFileUrl = SampleData;

    // Create an anchor element
    const anchor = document.createElement("a");

    // Set the href and download attributes to trigger the download
    anchor.href = excelFileUrl;
    anchor.download = "Sample-file.xlsx"; // Set the desired file name

    // Append the anchor to the body and click it programmatically
    document.body.appendChild(anchor);
    anchor.click();

    // Remove the anchor from the DOM
    document.body.removeChild(anchor);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fileSubmit = async (chunkSize) => {
    setSubmit(true);
    setProgress(0);

    const totalChunks = Math.ceil(data?.length / chunkSize);
    const responses = [];

    let chunksSent = 0; // Variable to track the number of chunks sent successfully

    // Split data into chunks and send each chunk to the server
    for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize;
      const end = (i + 1) * chunkSize;
      const chunk = data.slice(start, end);

      try {
        // Send chunk to the server using Axios
        const response = await importProducerProduct(params.id, chunk);
        responses.push(response.data);
      } catch (error) {
        // Handle error if request fails
        console.error("Error sending chunk:", error.message);
        // responses.push({ error: error.message });
      }
    }

    if (responses.length > 0) {
      setImageuploadResponse([]);
      toast.success("Product Import Success");
    } else {
      toast.error("Product Import failed please try again.");
    }

    setSubmit(false);
    setResponse(responses.flat(1));
    return responses;
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
  };

  const addImageUrl = () => {
    // const checkImage=data?.map((rowData)=>{
    //   if(rowData.image_one.inc)
    // })
  };

  // Function to replace image URLs based on filename match
  const replaceImageURLs = () => {
    setOpenImageUpload(false);
    // toast.success("Image folder uploaded");
    let finalData = [];

    data?.forEach((product) => {
      let updatedProduct = { ...product }; // Copy the product object
      updatedProduct.images.forEach((image, index) => {
        const matchedImage = imageuploadResponse.find(
          (img) => img.fileName === image
        );
        if (matchedImage) {
          updatedProduct.images[index] = matchedImage.url;
        } else {
          // If no match found, keep the original image filename
          updatedProduct.images[index] = image;
        }
      });
      finalData.push(updatedProduct); // Push the updated product to finalData
    });
    setFinalData(finalData);
    if (finalData) {
      fileSubmit(10);
    }
    // return finalData;
    // Return the finalData array
  };

  useEffect(() => {
    if (imageuploadResponse.length > 0) {
      setClearData(!clearData);
      replaceImageURLs();
    }
  }, [imageuploadResponse]);

  useEffect(() => {
    getProductCategory();
  }, []);

  return (
    <div>
      {submit && <Loader />}
      {/* <ToastContainer /> */}
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <Tabs value={value} onChange={handleChange} left>
          <Tab label="Import Product" />
          <Tab label="View Product" />
        </Tabs>
      </Box>

      {value === 0 ? (
        <>
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              padding: "30px",
              rowGap: "10px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                rowGap: "10px",
                columnGap: "10px",
              }}
            >
              {data?.length === 0 || !isValid || uploadResponse.length > 0 ? (
                <Button
                  component="label"
                  role={undefined}
                  variant="outlined"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                  style={{ width: "20%" }}
                  color="info"
                >
                  Upload Product file
                  <VisuallyHiddenInput
                    type="file"
                    accept=".xls,.xlsx,.csv"
                    onChange={handleFileUploadNew}
                  />
                </Button>
              ) : !submit && isValid ? (
                <>
                  <>
                    <Button
                      component="label"
                      role={undefined}
                      variant="outlined"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                      style={{ width: "35%" }}
                      color="info"
                      onClick={() => setOpenImageUpload(true)}
                    >
                      {imageuploadResponse?.length < 1
                        ? "Upload Image folder"
                        : "Re-upload Image folder"}
                    </Button>
                  </>
                  {imageuploadResponse?.length < 1 && (
                    <Button
                      component="label1"
                      role={undefined}
                      variant="outlined"
                      tabIndex={-1}
                      startIcon={<RestartAltIcon />}
                      style={{ width: "20%" }}
                      color="error"
                      onClick={() => {
                        setRawDataCount(0);
                        setData([]);
                        setErrorCount(0);
                        setDuplicateData([]);
                      }}
                    >
                      Reset
                    </Button>
                  )}
                  <Button
                    component="label"
                    role={undefined}
                    variant="outlined"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    style={{ width: "20%" }}
                    color="success"
                    onClick={() => fileSubmit(chunkSize)}
                  >
                    Submit
                  </Button>
                </>
              ) : (
                ""
              )}
            </div>
            {data?.length > 0 && !isValid && (
              <div style={{ marginTop: "10px" }}>
                <Alert severity="error">
                  This Excel sheet has some error please resolve and submit
                  again.
                </Alert>
              </div>
            )}
            {errorHeaderList.length > 0 && (
              <div style={{ marginTop: "10px" }}>
                <Alert severity="error">
                  Headers missing{" "}
                  {errorHeaderList?.map((error) => (
                    <strong>{error} ,</strong>
                  ))}
                  please correct and submit again.
                </Alert>
              </div>
            )}
            {/* {submit && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <LinearProgressWithLabel
                    value={progress}
                    variant="buffer"
                    valueBuffer={10}
                  />
                </Box>
              </div>
            )} */}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <h4>Total : {rawDataCount}</h4>
            <div style={{ display: "flex", width: "40%", columnGap: "10px" }}>
              <h4>Valid : {data?.length - errorCount}</h4>
              <h4>Invalid : {rawDataCount - data?.length}</h4>
              <h4>Error : {errorCount}</h4>
              {duplicateData?.length > 0 && (
                <>
                  <h4>Already Exists : {duplicateData?.length}</h4>
                  {/* <h4 style={{ color: "#3fec3f" }}>
                    New : {rawDataCount - duplicateData?.length}
                  </h4> */}
                </>
              )}
            </div>

            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                component="label3"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                startIcon={<DownloadIcon />}
                style={{ width: "60%" }}
                onClick={downloadSampleData}
              >
                Download sample file
              </Button>
            </div>
          </div>

          <DataTable
            data={data}
            setOpen={setOpen}
            setIsValid={setIsValid}
            isValid={isValid}
            categoryAllData={categoryAllData}
            setErrorCount={setErrorCount}
            errorCount={errorCount}
            setSingleProduct={setSingleProduct}
            uploadResponse={uploadResponse}
            duplicateData={duplicateData}
            loading={loading}
          />
        </>
      ) : value === 1 ? (
        <div style={{ marginTop: "20px" }}>
          <ViewProductTable data={data} setOpen={setOpen} />
        </div>
      ) : (
        ""
      )}
      <ProductViewModal
        open={open}
        setOpen={setOpen}
        singleProductData={singleProduct}
      />
      <ImageUploadModal
        openImageupload={openImageupload}
        setOpenImageUpload={setOpenImageUpload}
        imageuploadResponse={imageuploadResponse}
        setImageuploadResponse={setImageuploadResponse}
        clearData={clearData}
        setSubmit={setSubmit}
        submit={submit}
      />
    </div>
  );
}
