import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  colors,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  Link,
  Menu,
  MenuItem,
  Pagination,
  Select,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import { fetchConsumerLocationData, fetchRosterChildLocationListForRosterProductImport } from "api/api";
import { useParams } from "react-router";
import { useState } from "react";
import Loader from "ui-component/Loader";
import DataTable from "react-data-table-component";
import { handleDateTime } from "utils/common";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import DirectionsIcon from "@mui/icons-material/Directions";
import HomeIcon from "@mui/icons-material/Home";
import LockIcon from "@mui/icons-material/Lock";
import SearchIcon from "@mui/icons-material/Search";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import * as XLSX from "xlsx";

export default function LocationViewtable({ setModalOpen, setRowData }) {
  const params = useParams();

  const [page, setPage] = useState(0);
  const [parentLocationId, setParentLocationId] = useState(0);

  const [data, setData] = useState([]);
  const [locationBreadcrumbs, setLocationBreadcrumbs] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  // Function to fetch data from API
  const fetchItems = async () => {
    try {
      const response = await fetchConsumerLocationData(
        params.id,
        page,
        6,
        parentLocationId
      );

      const aData = response.data?.consumerProduct;

      // Append new data to items
      setData((prevItems) => [...prevItems, ...aData]);

      setPage((prevPage) => prevPage + 1); // Increment page for the next call

      // Check if no more data to load
      if (aData.length === 0 || aData.length < 6) {
        setHasMore(false);
        return;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setHasMore(false);
    }
  };

  const HandleApplyFilter = async () => {};

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <>
        <div
          style={{
            display: "flex",
            // columnGap: "30px",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
        ></div>
      </>
    );
  }, []);

  // If user clicks on direct location card
  const handleLocationCardClick = (data) => {
    const { id, locationName } = data;

    setLocationBreadcrumbs((prev) => [
      ...prev,
      { name: locationName.locationName, id: id },
    ]);

    setPage(0);
    setParentLocationId(() => id);
  };

  /* If user clicks on breadcrumbs */
  const handleNewParentLocation = (id) => {
    if (parentLocationId == id) {
      return;
    } else if (id == 0) {
      setLocationBreadcrumbs([]);
    } else {
      const newBred = [];

      for (let i = 0; i < locationBreadcrumbs.length; i++) {
        newBred.push(locationBreadcrumbs[i]);
        if (locationBreadcrumbs[i].id === id) {
          break;
        }
      }

      setLocationBreadcrumbs(newBred);
    }

    setPage(0);
    setParentLocationId(() => id);
  };


  useEffect(() => {
    setData([]);
    setPage(0);
    setHasMore(true);
    fetchItems();
  }, [parentLocationId]);

  const LocationCardSkeleton = () => {
    return (
      <Box
        sx={{
          padding: 0,
          mt: 2,
        }}
      >
        <Grid container spacing={2}>
          {[1, 2, 3, 4].map((location, index) => (
            <Grid item xs={12} sm={12} md={6} key={location.id || index}>
              <Card
                sx={{
                  padding: 0,
                  width: "100%",
                  height: "350px",
                  borderRadius: 2,
                  backgroundColor: "#ffffff",
                  transition: "0.3s",
                  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                }}
              >
                <CardContent sx={{ p: 2 }}>
                  {/* Header with Location Icon */}
                  <Box
                    display="flex"
                    justifyContent={"space-between"}
                    alignItems="center"
                  >
                    <Box display="flex" alignItems="center" mb={2}>
                      <LocationOnIcon
                        sx={{ color: "#1976d2", marginRight: 1 }}
                      />
                      <Skeleton variant="text" width={150} height={30} />
                    </Box>
                    <Skeleton variant="text" width={30} height={30} />
                  </Box>

                  <Divider />

                  {/* Address */}
                  <Stack direction="row" spacing={1} alignItems="center" mt={2}>
                    <HomeIcon sx={{ color: "#ff9800" }} />
                    <Skeleton variant="text" width="60%" height={20} />
                  </Stack>

                  {/* Direction */}
                  <Stack direction="row" spacing={1} alignItems="center" mt={1}>
                    <DirectionsIcon sx={{ color: "#4caf50" }} />
                    <Skeleton variant="text" width="60%" height={20} />
                  </Stack>

                  {/* Door Code */}
                  <Stack direction="row" spacing={1} alignItems="center" mt={1}>
                    <LockIcon sx={{ color: "#f44336" }} />
                    <Skeleton variant="text" width="60%" height={20} />
                  </Stack>

                  {/* Parent Location */}
                  <Stack direction="row" spacing={1} alignItems="center" mt={1}>
                    <LocationOnIcon sx={{ color: "#9c27b0" }} />
                    <Skeleton variant="text" width="60%" height={20} />
                  </Stack>

                  {/* Phone */}
                  <Stack direction="row" spacing={1} alignItems="center" mt={1}>
                    <ContactPhoneIcon sx={{ color: "#3f51b5" }} />
                    <Skeleton variant="text" width="60%" height={20} />
                  </Stack>

                  {/* prouduct counter */}
                  <Stack direction="row" spacing={1} alignItems="center" mt={1}>
                    <ContactPhoneIcon sx={{ color: "#607d8b" }} />
                    <Skeleton variant="text" width="60%" height={20} />
                  </Stack>

                  {/* prouduct counter */}
                  <Stack direction="row" spacing={1} alignItems="center" mt={1}>
                    <Inventory2Icon sx={{ color: "#607d8b" }} />
                    <Skeleton variant="text" width="60%" height={20} />
                  </Stack>

                  {/* Sub Location */}
                  <Stack direction="row" spacing={1} alignItems="center" mt={1}>
                    <LocationOnIcon sx={{ color: "#607d8b" }} />
                    <Skeleton variant="text" width="60%" height={20} />
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  const LocationCard = ({ data, index }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleDownloadLocationSheetClick = async(locationData)=>{
      handleClose()
      const locationId = locationData?.id;
      const subLocationCount = locationData?.subLocationCount;


      if(Number(subLocationCount)===0){
        alert('No sublocations!')
        return;
      } 

      // API CALL 
      const locationResponse =
      await fetchRosterChildLocationListForRosterProductImport(params?.id,locationId);
    // setLeafLocationList(locationResponse?.data);  

          // Create a worksheet from the leafLocations data
    const ws = XLSX.utils.json_to_sheet(locationResponse.data);

    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Leaf Locations");

    // Generate a binary string representing the workbook
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Create a Blob from the binary string and trigger download
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Leaf_Locations.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    }

    return (
      <>
        <Card
          sx={{
            padding: 0,
            // cursor: "pointer",
            width: "100%",
            height: "350px",
            borderRadius: 2,
            // boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
            transition: "0.3s",
            backgroundColor: "#ffffff",
            "&:hover": {
              boxShadow: "0 8px 30px rgba(0, 0, 0, 0.15)",
            },
          }}
        >
          <CardContent sx={{ p: 2 }}>
            {/* Header with Location Icon */}
            <Box
              display="flex"
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Box display="flex" alignItems="center" mb={2}>
                <LocationOnIcon sx={{ color: "#1976d2", marginRight: 1 }} />
                <Typography
                   sx={{cursor:'pointer',borderBottom:1,":hover":{color:'blue'}}}
                  onClick={() => {
                    handleLocationCardClick(data);
                  }}
                  variant="h5"
                  
                  fontWeight="bold"
                >
                  #{index + 1} {data.locationName?.locationName || "N/A"}
                </Typography>
                <Box ml={2}>
                  {data?.subLocationCount == 0 ? (
                    <Chip
                      label="Child Location"
                      size="small"
                      color="success"
                    ></Chip>
                  ) : (
                    <Chip
                      label="Source Location"
                      size="small"
                      color="primary"
                    ></Chip>
                  )}
                </Box>
              </Box>

              <IconButton
                aria-label="more"
                aria-controls={anchorEl ? `basic-menu-${index}` : undefined}
                aria-haspopup="true"
                aria-expanded={Boolean(anchorEl)}
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id={`basic-menu-${index}`}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                MenuListProps={{
                  "aria-labelledby": `basic-button-${index}`,
                }}
              >
                <MenuItem onClick={()=>{handleDownloadLocationSheetClick(data)}}>Download Location Sheet</MenuItem>
              </Menu>
            </Box>

            <Divider />

            {/* Address */}
            <Stack direction="row" spacing={1} alignItems="center" mt={2}>
              <HomeIcon sx={{ color: "#ff9800" }} />
              <Typography variant="body1" color="textSecondary">
                Address: <strong>{data.address || "N/A"}</strong>
              </Typography>
            </Stack>

            {/* Direction */}
            <Stack direction="row" spacing={1} alignItems="center" mt={1}>
              <DirectionsIcon sx={{ color: "#4caf50" }} />
              <Typography variant="body1" color="textSecondary">
                Direction: <strong>{data.direction || "N/A"}</strong>
              </Typography>
            </Stack>

            {/* Door Code */}
            <Stack direction="row" spacing={1} alignItems="center" mt={1}>
              <LockIcon sx={{ color: "#f44336" }} />
              <Typography variant="body1" color="textSecondary">
                Door Code: <strong>{data.doorCode || "N/A"}</strong>
              </Typography>
            </Stack>

            {/* Parent Location */}
            <Stack direction="row" spacing={1} alignItems="center" mt={1}>
              <LocationOnIcon sx={{ color: "#9c27b0" }} />
              <Typography variant="body1" color="textSecondary">
                Parent Location: <strong>{data.parentLocation || "N/A"}</strong>
              </Typography>
            </Stack>

            {/* Phone */}
            <Stack direction="row" spacing={1} alignItems="center" mt={1}>
              <ContactPhoneIcon sx={{ color: "#3f51b5" }} />
              <Typography variant="body1" color="textSecondary">
                Phone: <strong>{data.phone || "N/A"}</strong>
              </Typography>
            </Stack>

            {/* Primary Contact */}
            <Stack direction="row" spacing={1} alignItems="center" mt={1}>
              <ContactPhoneIcon sx={{ color: "#607d8b" }} />
              <Typography variant="body1" color="textSecondary">
                Primary Contact: <strong>{data.primaryContact || "N/A"}</strong>
              </Typography>
            </Stack>

            <Stack direction="row" spacing={1} alignItems="center" mt={1}>
              <Inventory2Icon sx={{ color: "#607d8b" }} />
              <Typography variant="body1" color="textSecondary">
                Product Count: <strong>{data?.productCounter || "N/A"}</strong>
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center" mt={1}>
              <LocationOnIcon sx={{ color: "#607d8b" }} />
              <Typography variant="body1" color="textSecondary">
                Sub Location: <strong>{data.subLocationCount || "N/A"}</strong>
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      </>
    );
  };

  return (
    <>
      <Box
        width={"100%"}
        sx={{ backgroundColor: "#ffffff", p: "2px", borderRadius: 2 }}
        height={"50px"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        flexWrap={"wrap"}
      >
        <Box>
          <Breadcrumbs aria-label="breadcrumb">
            <HomeIcon
              onClick={() => {
                handleNewParentLocation(0);
              }}
              sx={{ mr: 0.5, cursor: "pointer", ml: 1, color: "#1976d2" }}
              fontSize="medium"
            />

            {locationBreadcrumbs &&
              locationBreadcrumbs?.map((e) => {
                return (
                  <Button
                    onClick={() => {
                      handleNewParentLocation(e.id);
                    }}
                  >
                    {e.name}
                  </Button>
                );
              })}
          </Breadcrumbs>
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton
            onClick={() => {
              alert("pending work!");
            }}
            color="primary"
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
        </Box>
      </Box>

      <InfiniteScroll
        dataLength={data.length} // Current length of data
        next={page === 0 ? () => {} : fetchItems} // Function to fetch more data
        hasMore={hasMore} // Boolean to control fetching more data
        loader={<LocationCardSkeleton />}
        // endMessage={
        //   <Typography variant="body2" align="center" color="textSecondary">
        //     You have seen it all!
        //   </Typography>
        // }
      >
        <Box
          sx={{
            padding: 0,
            mt: 1,
          }}
        >
          <Grid container spacing={2}>
            {data &&
              data.map((location, index) => (
                <Grid item xs={12} sm={12} md={6} key={location.id || index}>
                  <LocationCard data={location} index={index} />
                </Grid>
              ))}
          </Grid>
        </Box>
      </InfiniteScroll>
    </>
  );
}
