import React from "react";
import {
  Box,
  TextField,
  Grid,
  Button,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  Select,
  Chip,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
  Menu,
  Autocomplete,
  FormHelperText,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateField } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import DataTable from "react-data-table-component";
import { getAllCategory, rosterReportPreview ,rosterLocationData} from "api/api";
import { useParams } from "react-router";
import { useState } from "react";
import { useEffect } from "react";
import { handleDateTime } from "utils/common";
import { BASE_URL } from "utils/constant";
import axios from "axios";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Fade from "@mui/material/Fade";

import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const RosterReportHomepage = () => {
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [previewMode, setPreviewMode] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [columns, setColumns] = useState([]);
  const [warrantyEndDate, setWarrantyEndDate] = useState(null);
  const [endOfLifeDate, setEndOfLifeDate] = useState(null);
  const [replacementDate, setReplacementDate] = useState(null);
  const [categories, setCategories] = React.useState([]);
  const [selctedCategory, setSelctedCategory] = React.useState([]);
  const [loading,setLoading] = useState(false);

  const rosterFieldNameList = {
    location: `location`,
    productName: `productName`,
    productType: `productType`,
    category: `category`,
    serialNumber: `serialNumber`,
    modelNumber: `modelNumber`,
    assetId: `assetId`,
    manufacturer: `manufacturer`,
    purchasePriceType: `purchasePriceType`,
    purchaseDate: `purchaseDate`,
    purchasePrice: `purchasePrice`,
    warrantyStartDate: `warrantyStartDate`,
    warrantyEndDate: `warrantyEndDate`,
    replacementDate: `replacementDate`,
    endOfLifeDate: `endOfLifeDate`,
    vendor: `vendor`,
    integrator: `integrator`,
    consultant: `consultant`,
  };

  const [reportFields, setReportFields] = useState([
    {
      label: `Location`,
      name: rosterFieldNameList.location,
      required: false,
      checked: true,
    },
    {
      label: `Product Name`,
      name: rosterFieldNameList.productName,
      required: false,
      checked: true,
    },
    {
      label: `Product Type`,
      name: rosterFieldNameList.productType,
      required: false,
      checked: true,
    },
    {
      label: `Category`,
      name: rosterFieldNameList.category,
      required: false,
      checked: true,
    },
    {
      label: `Serial Number`,
      name: rosterFieldNameList.serialNumber,
      required: false,
      checked: false,
    },
    {
      label: `Model Number`,
      name: rosterFieldNameList.modelNumber,
      required: false,
      checked: false,
    },
    {
      label: `Asset ID/Tag No`,
      name: rosterFieldNameList.assetId,
      required: false,
      checked: false,
    },
    {
      label: `Manufacturer`,
      name: rosterFieldNameList.manufacturer,
      required: false,
      checked: false,
    },
    {
      label: `Purchase Price Type`,
      name: rosterFieldNameList.purchasePriceType,
      required: false,
      checked: false,
    },
    {
      label: `Purchase Date`,
      name: rosterFieldNameList.purchaseDate,
      required: false,
      checked: false,
    },
    {
      label: `Purchase Price`,
      name: rosterFieldNameList.purchasePrice,
      required: false,
      checked: false,
    },
    {
      label: `Warranty Start Date`,
      name: rosterFieldNameList.warrantyStartDate,
      required: false,
      checked: false,
    },
    {
      label: `Warranty End Date`,
      name: rosterFieldNameList.warrantyEndDate,
      required: false,
      checked: false,
    },
    {
      label: `Expected Replacement Date`,
      name: rosterFieldNameList.replacementDate,
      required: false,
      checked: false,
    },
    {
      label: `End of Life Date`,
      name: rosterFieldNameList.endOfLifeDate,
      required: false,
      checked: false,
    },
    {
      label: `Vendor`,
      name: rosterFieldNameList.vendor,
      required: false,
      checked: false,
    },
    {
      label: `Integrator`,
      name: rosterFieldNameList.integrator,
      required: false,
      checked: false,
    },
    {
      label: `Consultant`,
      name: rosterFieldNameList.consultant,
      required: false,
      checked: false,
    },
  ]);

  const params = useParams();

  const handleCategoryChange = (event) => {
    setPreviewMode(false);
    const {
      target: { value },
    } = event;
    setSelctedCategory(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleLocationChange = (event) => {
    setPreviewMode(false);
    const {
      target: { value },
    } = event;
    setSelctedCategory(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPageSize(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const fetchData = async () => {
    setLoading(true)
    const organizationId = params?.id;
    const WED = warrantyEndDate ? new Date(warrantyEndDate).toISOString() : "";
    const EOLD = endOfLifeDate ? new Date(endOfLifeDate).toISOString() : "";
    const RD = replacementDate ? new Date(replacementDate).toISOString() : "";
    const selectedCategories = selctedCategory.join(",");
    const selectedLocationIdList = selectedLocations.map((e)=>e.id).join(',');
    
    const resposne = await rosterReportPreview(
      organizationId,
      page,
      pageSize,
      WED,
      EOLD,
      RD,
      selectedCategories,
      selectedLocationIdList,
    );
    setData(resposne.data.data);
    setTotalRows(resposne.data.totalCount);
    setLoading(false)
  };

  const viewFilteredProductsButtonOnClick = () => {
    try {
      setPreviewMode((pre) => !pre);
    } catch (error) {
      alert("Error");
    }
  };

  const downloadExcelReportButtonOnClick = async () => {
    try {
      const organizationId = params?.id;
      const WED = warrantyEndDate
        ? new Date(warrantyEndDate).toISOString()
        : "";
      const EOLD = endOfLifeDate ? new Date(endOfLifeDate).toISOString() : "";
      const RD = replacementDate ? new Date(replacementDate).toISOString() : "";
      const fields = reportFields
        .filter((e) => e.checked)
        .map((e) => e.name)
        .join(","); // selected fields

      const selectedLocationIdListArray = selectedLocations.map((e)=>e.id).join(',');

      const selectedCategories = selctedCategory.join(",");
      const response = await axios.get(
        `${BASE_URL}system-admin/downloadExcelRosterReport?organizationId=${organizationId}&warrantyEndDate=${WED}&endOfLifeDate=${EOLD}&replacementDate=${RD}&fields=${fields}&selectedCategories=${selectedCategories}&selectedLocationIdList=${selectedLocationIdListArray}`,
        {
          responseType: "blob", // Important for binary data
        }
      );  

      // Create a URL for the blob data
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      // Set the download attribute with a filename
      link.setAttribute("download", "report.xlsx");

      // Append to DOM, click to download, and remove from DOM
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {}
  };

  const downloadPDFReportButtonOnClick = async () => {
    try {
      const organizationId = params?.id;
      const WED = warrantyEndDate
        ? new Date(warrantyEndDate).toISOString()
        : "";
      const EOLD = endOfLifeDate ? new Date(endOfLifeDate).toISOString() : "";
      const RD = replacementDate ? new Date(replacementDate).toISOString() : "";
      const fields = reportFields
        .filter((e) => e.checked)
        .map((e) => e.name)
        .join(","); // selected fields

      const selectedCategories = selctedCategory.join(",");
      const selectedLocationIdListArray = selectedLocations.map((e)=>e.id).join(',');
      const response = await axios.get(
        `${BASE_URL}system-admin/downloadPDFRosterReport?organizationId=${organizationId}&warrantyEndDate=${WED}&endOfLifeDate=${EOLD}&replacementDate=${RD}&fields=${fields}&selectedCategories=${selectedCategories}&selectedLocationIdList=${selectedLocationIdListArray}`,
        {
          responseType: "blob", // Important for binary data
        }
      );

      // Create a blob URL for the PDF and trigger the download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "report.pdf"); // File name to save as
      document.body.appendChild(link);
      link.click();

      // Cleanup
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {}
  };

  const resetFilterButtonOnClick = () => {
    setWarrantyEndDate(null);
    setEndOfLifeDate(null);
    setReplacementDate(null);
    setPreviewMode(false);
    setSelctedCategory([]);
    setSelectedLocations([])
  };

  const handleWarrantyChange = (newValue) => {
    setPreviewMode(false);
    setWarrantyEndDate(dayjs(newValue));
  };

  const handleEndOfLifeDateChange = (newValue) => {
    setPreviewMode(false);
    setEndOfLifeDate(dayjs(newValue));
  };

  const handleReplacementDateChange = (newValue) => {
    setPreviewMode(false);
    setReplacementDate(dayjs(newValue));
  };

  const handleRosterReportFieldCheckboxChange = (e) => {
    setReportFields((prev) => {
      return prev.map((field) => {
        if (field.name === e.target.name) {
          return { ...field, checked: !field.checked }; // Create a new object with updated 'checked' property
        }
        return field; // Return the field unchanged
      });
    });
  };

  const fetchInitialResourcesForRosterReportPage = async () => {
    try {
      const response = await getAllCategory();
      const categoryData = response?.data;
      setCategories(categoryData);
    } catch (error) {}
  };

  useEffect(() => {
    if (previewMode) {
      fetchData();
    }
  }, [previewMode, page, pageSize]);

  useEffect(() => {
    setColumns([
      {
        name: "#",
        selector: (row, index) => index + 1,
        width: "50px",
      },
      {
        name: "Location Name",
        cell: (row) =>
          `${row.parentlocation}${row.parentlocation ? "/" : ""}${
            row.locationName
          }`, // Use the custom cell component'
        width: "400px",
        omit: !reportFields.find((e) => e.name === rosterFieldNameList.location)
          .checked,
      },
      {
        name: "Product Name",
        selector: (row) => `${row.productName}`,
        omit: !reportFields.find(
          (e) => e.name === rosterFieldNameList.productName
        ).checked,
        width: "200px",
      },
      {
        name: "Product Type",
        omit: !reportFields.find(
          (e) => e.name === rosterFieldNameList.productType
        ).checked,
        selector: (row) => (
          <>
            <Chip
              label={row.productType || "N/A"} // Display the product type or 'N/A' if undefined
              color="info" // You can adjust the color as needed
              variant="outlined" // Optional: use 'filled' for a solid chip
              size="small" // Adjust the size (small, medium)
            />
          </>
        ),
        width: "200px",
      },

      {
        name: "Category",
        selector: (row) => row.category,
        omit: !reportFields.find((e) => e.name === "category").checked,
        width: "200px",
      },
      {
        name: "Serial Number",
        selector: (row) => row?.serialNumber,
        omit: !reportFields.find(
          (e) => e.name === rosterFieldNameList.serialNumber
        ).checked,
        width: "200px",
      },
      {
        name: "Model Number",
        selector: (row) => row?.modelNumber,
        omit: !reportFields.find(
          (e) => e.name === rosterFieldNameList.modelNumber
        ).checked,
        width: "200px",
      },
      {
        name: "AssetId/Tag No",
        selector: (row) => row?.assetIdTagNumber,
        omit: !reportFields.find((e) => e.name === rosterFieldNameList.assetId)
          .checked,
        width: "200px",
      },
      {
        name: "Manufacturer",
        selector: (row) => row?.displayManufacturer,
        omit: !reportFields.find(
          (e) => e.name === rosterFieldNameList.manufacturer
        ).checked,
        width: "200px",
      },
      {
        name: "Purchase Price Type",
        selector: (row) => row?.purchasePriceType,
        omit: !reportFields.find(
          (e) => e.name === rosterFieldNameList.purchasePriceType
        ).checked,
        width: "200px",
      },
      {
        name: "Purchase Date",
        selector: (row) => handleDateTime(row?.purchaseDate),
        omit: !reportFields.find(
          (e) => e.name === rosterFieldNameList.purchaseDate
        ).checked,
        width: "200px",
      },
      {
        name: "Purchase Price",
        selector: (row) => row?.purchasePrice,
        omit: !reportFields.find(
          (e) => e.name === rosterFieldNameList.purchasePrice
        ).checked,
        width: "200px",
      },
      {
        name: "Warranty Start Date",
        selector: (row) => handleDateTime(row?.warrantyStartDate),
        omit: !reportFields.find(
          (e) => e.name === rosterFieldNameList.warrantyStartDate
        ).checked,
        width: "200px",
      },
      {
        name: "Warranty End Date",
        selector: (row) => handleDateTime(row?.warrantyEndDate),
        omit: !reportFields.find(
          (e) => e.name === rosterFieldNameList.warrantyEndDate
        ).checked,
        width: "200px",
      },
      {
        name: "Expected Replacement Date",
        selector: (row) => handleDateTime(row?.expectedReplacementDate),
        omit: !reportFields.find(
          (e) => e.name === rosterFieldNameList.replacementDate
        ).checked,
        width: "200px",
      },
      {
        name: "End of Life Date",
        selector: (row) => handleDateTime(row?.endOfLifeDate),
        omit: !reportFields.find(
          (e) => e.name === rosterFieldNameList.endOfLifeDate
        ).checked,
        width: "200px",
      },
      {
        name: "Vendor",
        selector: (row) => row?.displayVendor,
        omit: !reportFields.find((e) => e.name === rosterFieldNameList.vendor)
          .checked,
        width: "200px",
      },
      {
        name: "Integrator",
        selector: (row) => row?.displayIntegrator,
        omit: !reportFields.find(
          (e) => e.name === rosterFieldNameList.integrator
        ).checked,
        width: "200px",
      },
      {
        name: "Consultant",
        selector: (row) => row?.displayConsultant,
        omit: !reportFields.find(
          (e) => e.name === rosterFieldNameList.consultant
        ).checked,
        width: "200px",
      },
    ]);
  }, [reportFields]);

  useEffect(() => {
    fetchInitialResourcesForRosterReportPage();
  }, []);

  const handleLocationMultiSelectInputChange = (newLocationList)=>{
    setSelectedLocations(newLocationList); 
    setPreviewMode(false)
  }

  const fetchLocations = async (searchText) => {
    // if (searchText.length === 0) {
    //   setLocations([]); 
    //   return;
    // }
    try {
      const organizationId = params?.id;
      const response = await rosterLocationData(organizationId,searchText.trim())
      const locationData = response.data;
      const filteredLocation = []
      for(const loc of locationData){
        if((locations.map((e)=>e.id).includes(loc.id) || selectedLocations.map((e)=>e.id).includes(loc.id))){

        }else{
          filteredLocation.push(loc);
        }
      }
      setLocations(filteredLocation); 
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  return (
    <>
      <Grid container>
        <Grid item sm={12}>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              flexWrap: "wrap",
              //   flexDirection:'column',
              mb: 2,
              background: "white",
              p: 2,
            }}
          >
            {/* Filter 1: Organization Role */}
            <FormControl fullWidth variant="outlined">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                <Box sx={{width:350}}>
                  <Autocomplete
                      multiple
                      options={locations}
                      getOptionLabel={(option) => option.name}
                      value={selectedLocations}
                      
                      onInputChange={(event, value) => {
                        if (value.length > 0) fetchLocations(value); // Fetch only if input exists
                      }}
                      onChange={(event, newValue) => {handleLocationMultiSelectInputChange(newValue)}}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0px", // Make the border square
                        },
                      }}
                      renderInput={(params) => (
                        <TextField placeholder="search for location" {...params} label="Search By Locations" size="small" variant="outlined" />
                      )}
                      
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip label={option.name} {...getTagProps({ index })} />
                        ))
                      }
                    />
                    <Typography
                      variant="caption"
                      sx={{ size: "small", display: "block", color: "text.secondary", ml: 2 ,pt:0.5}}
                    >
                      Search by Locations
                    </Typography>
                  </Box>
                  <div>
                  <FormControl sx={{ width: 250 }}>
                      <InputLabel
                        size="small"
                        id="demo-multiple-checkbox-label"
                      >
                        Select Category
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={selctedCategory}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: "0px", // Make the border square
                          },
                        }}
                        size="small"
                        onChange={handleCategoryChange}
                        input={<OutlinedInput label="Select Category" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {categories &&
                          categories?.map(({ name, id: categoryId }) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                checked={selctedCategory.includes(name)}
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText>Select Multiple Category</FormHelperText>
                    </FormControl>
                  </div>
                  <DatePicker
                    slotProps={{
                      textField: {
                        size: "small",
                        helperText: `Warranty End Date`,
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "0px", // Make the border square
                      },
                    }}
                    value={warrantyEndDate}
                    onChange={(newValue) => {
                      handleWarrantyChange(newValue);
                    }}
                    label="Warranty End Date"
                  />
                  <DatePicker
                    slotProps={{
                      textField: {
                        size: "small",
                        helperText: `End of Life Date`,
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "0px", // Make the border square
                      },
                    }}
                    value={endOfLifeDate}
                    onChange={(newValue) => {
                      handleEndOfLifeDateChange(newValue);
                    }}
                    label="EOL Date"
                  />
                  <DatePicker
                    slotProps={{
                      textField: {
                        size: "small",
                        helperText: `Recommended Replacement Date`,
                      },
                    }}
                    value={replacementDate}
                    onChange={(newValue) => {
                      handleReplacementDateChange(newValue);
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "0px", // Make the border square
                      },
                    }}
                    label="Replacement Date"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </FormControl>

            <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
              <Button
                onClick={viewFilteredProductsButtonOnClick}
                variant="contained"
              >
                {previewMode ? "Close Preview" : `Preview Products`}
              </Button>
              {/* <Button variant="contained">Download PDF Report</Button> */}
              <Button onClick={resetFilterButtonOnClick} variant="contained">
                Reset Filter
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              mb: 2,
              background: "white",
              p: 2,
            }}
          >
            <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
              {reportFields?.map(({ label, name, required, checked }) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={name}
                        inputProps={{ "aria-label": "controlled" }}
                        checked={checked}
                        onChange={(e) => {
                          handleRosterReportFieldCheckboxChange(e);
                        }}
                      />
                    }
                    label={label}
                    key={name}
                    required={required}
                  />
                );
              })}
            </FormGroup>
          </Box>
        </Grid>

        {previewMode && (
          <Grid item sm={12}>
            <Box>
              <DataTable
                customStyles={{
                  subHeader: {
                    style: {},
                  },
                  header: {
                    style: {
                      borderTopLeftRadius: "10px", // Top left corner
                      borderTopRightRadius: "10px", // Top right corner
                    },
                  },
                }}
                progressPending={loading}
                title={
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <p>{"Roster Products"}</p>{" "}
                      <Box>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={open ? "long-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={handleClick}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="fade-menu"
                          MenuListProps={{
                            "aria-labelledby": "fade-button",
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          TransitionComponent={Fade}
                        >
                          <MenuItem
                            onClick={() => {
                              downloadExcelReportButtonOnClick();
                            }}
                          >
                            Download Excel Report
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              downloadPDFReportButtonOnClick();
                            }}
                          >
                            Download PDF Report
                          </MenuItem>
                        </Menu>
                      </Box>
                    </Box>
                  </>
                }
                columns={columns}
                data={data}
                pagination
                persistTableHead
                fixedHeader
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                highlightOnHover
                responsive
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default RosterReportHomepage;
