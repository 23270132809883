import {
    Alert,
    Autocomplete,
    Fab,
    FormControl,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
  } from "@mui/material";
  import {
      createUpdateRSSFeedByAdmin,
    DeleteErrorLogs,
    deleteRSSFeedByAdmin,
    fetchPostTypeListForNewswire,
    getErrorList,
    getNewswirePostsWithFilters,
    getProductList,
    getRSSFeedWithFilters,
    getUnverifiedProducer,
  } from "api/api";

  import moment from "moment";
  import React from "react";
  import { useState } from "react";
  import { useEffect } from "react";
  import DataTable from "react-data-table-component";
  import AddIcon from "@mui/icons-material/Add";
  
  import Button from "@mui/material/Button";
  import { CSVLink } from "react-csv";
  import { Link } from "react-router-dom";
  import { toast } from "react-toastify";
  import Box from "@mui/material/Box";
  import {
    Chip,
    Divider,
    Grid,
    Modal,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
  } from "@mui/material";
  import Loader from "ui-component/Loader";
  import { useNavigate, useParams } from "react-router";
  import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
  import {
    fetchOrganizationNewswirePosts,
    fetchOrganizationPlans,
  } from "api/api";
  import Avatar from "@mui/material/Avatar";
  import Tooltip from "@mui/material/Tooltip";
import { AlertDialog, handleDateTime } from "utils/common";
  
  const headers = [
    { label: "Event Name", key: "eventName" },
    { label: "Event Category", key: "eventCategory" },
    { label: "Event Description", key: "eventDescription" },
    { label: "Start Date", key: "startDateTime" },
    { label: "End Date", key: "endDateTime" },
    { label: "Timezone", key: "eventTimeZone" },
    { label: "Event Privacy", key: "eventPrivacy" },
    { label: "Event Mode", key: "eventMode" },
  ];
  
  export default function RSSFeedMainPage({  }) {
    const [columns, setColumns] = useState([]);
  
    const [data, setData] = useState([]);
  
    const [pending, setPending] = React.useState(false);

    const [loader, setLoading] = useState(false)
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
  
    const [open, setOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [postTypeList, setPostTypeList] = useState([]);
  
    const [filterRssFeedType,setFilterRssFeedType] = useState('all');
    const [filterSearchText,setFilterSearchText] = useState('');

    const [metaData, setMetaData] = useState({});
    const [rssModalData, setRssModalData] = useState({});
    const [feedForm, setFeedForm] = useState({ name: "", url: "", id: null });
  
    const theme = useTheme();
  
    // Media query to check screen size, e.g., smaller screens (600px and below)
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));

    const rssFeedType = {
        all:'all',
        withoutOrganization:'withoutOrganization'
    }
  
    const handleOpen = (row) => {
      setSelectedRow(row);
      setOpen(true);
    };
  
    const handleClose = () => {
        setFeedForm({ name: "", url: "" });
        setOpen(false);
        fetchRSSFeeds();
    };

    const [alertDialogopen, setAlertDialogopen] = React.useState(false);
    const [alertDialogData, setAlertDialogData] = React.useState({});
  
    const handleClickAlertDialogOpen = (data) => {
      setAlertDialogopen(true);
      setAlertDialogData(data);
    };
  
    const handleCloseAlertDialog = ()=>{
      setAlertDialogopen(false);
      setAlertDialogData({})
    }

    const handleActionsOnAlertDialog = async (data,action) => {

        switch(action){
    
          case 'Disagree': 
          
          handleCloseAlertDialog();
          break;
          case 'Agree': 
            
          try {
            const response = await deleteRSSFeedByAdmin(data?.id);
            if(response.data.status==='success'){
              toast.success("Success", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              fetchRSSFeeds();
              handleCloseAlertDialog()
            }
          } catch (error) {
            const errorMessage = error?.response?.data?.message;
            alert(errorMessage)
          }
            
          break;
          
          default:
            alert('Action not defined!')
          }
      };

    const handleRSSFeedAddNewButtonClick = () => {
        handleOpen();
        setRssModalData({
          title: `Add a new global RSS feed`,
          alertContent:`Note: This RSS feed is not tied to any specific organization. It is intended for global or VTC-level.`,
          showAlert:true,
        });
      };
  
    const handleModalSubmitButton = async () => {
        if (!(feedForm.name.length > 0 || feedForm.url.length > 0)) {
          alert("Enter data!");
        }
    
        const data = {
          id: feedForm.id || null,
          name: feedForm.name,
          url: feedForm.url,
          organizationId: metaData.organizationId,
        };
        

        try {
          const response = await createUpdateRSSFeedByAdmin({ ...data });
          if (response.data?.status === "success") {
            handleClose();
            toast.success("Success", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        } catch (error) {
          console.log({ error });
          const errorMessage = error.response?.data?.message || "Error!";
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      };
  
    const handlePerRowsChange = async (newPerPage, page) => {
      setPerPage(newPerPage);
    };
  
    const fetchRSSFeeds = async () => {
      setPending(true);
      setLoading(true)
      await getRSSFeedWithFilters(page-1,perPage,filterRssFeedType,filterSearchText)
        .then((res) => {
          setData(res.data.data);
          setTotalRows(res.data.totalCount);
        })
        .catch((err) => {});
      setLoading(false)
      setPending(false);
    };

    const handleRSSFeedEditClick = (data) => {
        handleOpen();
        setRssModalData({
          title: `Update rss feed`,
          alertContent:``,
          showAlert:false,
        });
        setFeedForm({ name: data.feedName, url: data.url, id: data.id });
      };
    
  
  
    const subHeaderComponentMemo2 = React.useMemo(() => {
      
      return (
        <>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              alignItems: "center",
              backgroundColor: "background.paper",
              padding: 1,
              gap: 1,
              // boxShadow: 1,
              
              // borderRadius: 2,
              // backgroundColor:'pink',
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <TextField
              id="search-posts"
              label="Search feed by organization name or url"
              variant="outlined"
              value={filterSearchText}
              onChange={(e)=>{setFilterSearchText(e.target.value)}}
              size="medium"
              // fullWidth
              sx={{ "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "0px", // Make the border square
              }, minWidth: 300 }}
            />
            <FormControl variant="outlined" size="medium" sx={{"& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "0px", // Make the border square
              }, minWidth: 300 }}>
              <InputLabel id="post-visibility-label">RSS Feed Type</InputLabel>
              <Select
                labelId="post-visibility-label"
                id="post-visibility"
                  value={filterRssFeedType}
                  onChange={(e)=>{
                      setFilterRssFeedType(e.target.value)
                  }}
                label="Post Visibility"
              >
                  <MenuItem selected value={rssFeedType.all}>All</MenuItem>
                  <MenuItem selected value={rssFeedType.withoutOrganization}>No Organization</MenuItem>
                
              </Select>
            </FormControl>
            {/* <Button variant="contained" color="primary" onClick={() => {}}>
              Search
            </Button> */}
          </Box>
        </>
      );
    }, [postTypeList,filterRssFeedType,filterSearchText]);
  
  // Modal Styles
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen
      ? "90%"
      : isMediumScreen
      ? "70%"
      : isLargeScreen
      ? "60%"
      : "40%",
    maxHeight: "70vh", // Prevent overflow on small screens
    minHeight: "70vh",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    overflowY: "auto", // Add scrolling for overflow content
    p: 4,
  };
  
  
 
  
    const fetchResources = async()=>{
      try {
          const postTypeResponse = await fetchPostTypeListForNewswire();
          setPostTypeList(postTypeResponse.data)
          
      } catch (error) {
          alert('getting error while fetching the resources!')
      }
    }
  
    useEffect(() => {
      setColumns([
        {
          name: "Feed Name",
          selector: (row) => row.feedName,
          sortable: false,
          maxWidth: "200px",
        },
        {
          name: "Organization",
          selector: (row) => row.organizationName,
          sortable: true,
          maxWidth: "600px",
        },
        {
          name: "Url",
          selector: (row) => row.url,
          sortable: true,
        },
        {
          name: "Created At",
          selector: (row) => handleDateTime(row.createdAt),
          sortable: true,
          maxWidth: "600px",
        },
        {
            name: "Edit RSS Feed",
            selector: (row) => (
              <>
                <Button
                  onClick={() => {
                    handleRSSFeedEditClick(row);
                  }}
                  variant="outlined"
                >
                  Edit
                </Button>
              </>
            ),
            sortable: true,
            maxWidth: "600px",
          },
          {
            name: "Delete RSS Feed",
            selector: (row) => (
              <>
                <Button
                  onClick={() => {
                    handleClickAlertDialogOpen(row);
                  }}
                  variant="outlined"
                  color="error"
                >
                  Delete
                </Button>
              </>
            ),
            sortable: true,
            maxWidth: "600px",
          },
      ]);
      fetchResources()
    }, []);
  
  
  
    const handlePageChange = (page) => {
      setPage(page);
    };
  
    useEffect(() => {
        fetchRSSFeeds();
    }, [page, perPage,filterRssFeedType,filterSearchText]);
  
    return (
      <>
      {loader === true && <Loader />}
        <Box mt={1} overflow={"hidden"}> 
            <DataTable
            customStyles={{
              subHeader: {
                style: {},
              },
              header: {
                style: {
                  borderTopLeftRadius: "0px", // Top left corner
                  borderTopRightRadius: "0px", // Top right corner
                },
              },
            }}
            title={<p>{"RSS Feed"}</p>}
            columns={columns}
            data={data}
            pagination
            subHeader={true}
            subHeaderComponent={subHeaderComponentMemo2}
            persistTableHead
            fixedHeader
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover
          />
        </Box>
  
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          {rssModalData ? (
            <>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box>
                  <p style={{ fontSize: "22px" }}>{rssModalData.title}</p>
                </Box>
                {/* <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  gap={1}
                  alignItems={"center"}
                >
                  <p style={{ fontSize: "22px" }}>
                    {metaData.organizationName}
                  </p>
                  <Avatar src={metaData.orgProfileImage}></Avatar>
                </Box> */}
              </Box>

              <Box
                component="form"
                sx={{ "& > :not(style)": { m: 1, width: "100%" } }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Feed name"
                  variant="outlined"
                  required
                  value={feedForm.name}
                  onChange={(e) => {
                    setFeedForm((pre) => {
                      return { ...pre, name: e.target.value };
                    });
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Feed url"
                  variant="outlined"
                  value={feedForm.url}
                  required
                  onChange={(e) => {
                    setFeedForm((pre) => {
                      return { ...pre, url: e.target.value };
                    });
                  }}
                />
              </Box>
              <Box display={"flex"} justifyContent={"center"} mt={2}>
                <Button
                  disabled={
                    feedForm.name.length > 0 && feedForm.url.length > 0
                      ? false
                      : true
                  }
                  type="submit"
                  onClick={() => {
                    handleModalSubmitButton();
                  }}
                  variant="outlined"
                >
                  Submit
                </Button>
              </Box>
            </>
          ) : (
            <></>
          )}
          {
            rssModalData.showAlert ? <><Alert sx={{mt:2}}>{rssModalData.alertContent}</Alert></>:<></>
          }
          
        </Box>
      </Modal>

      <AlertDialog
        title={"Feed Delete Confirmation"}
        content={"Do you really want to delete this RSS feed? Please confirm your action."}
        onClose={handleCloseAlertDialog}
        open={alertDialogopen}
        actions={[
          { label: "No", onClick: handleActionsOnAlertDialog, action:'Disagree' },
          {
            label: "Yes",
            onClick: handleActionsOnAlertDialog,
            autoFocus: true,
            action:'Agree'
          },
        ]}
        data={alertDialogData}
      />

      <Fab
        onClick={() => {
          handleRSSFeedAddNewButtonClick();
        }}
        sx={{
          position: "fixed", // fixed
          bottom: "9%",
          right: "4%",
          // bottom: "3%",
          // left:"25%"
          
        }}
        color="primary"
        aria-label="add"
      >
        <AddIcon />
      </Fab>
      </>
    );
  }
  