import { TextField } from "@mui/material";
import {
  DeleteErrorLogs,
  getErrorList,
  getProductList,
  getUnverifiedProducer,
} from "api/api";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";

import Button from "@mui/material/Button";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const headers = [
  { label: "Event Name", key: "eventName" },
  { label: "Event Category", key: "eventCategory" },
  { label: "Event Description", key: "eventDescription" },
  { label: "Start Date", key: "startDateTime" },
  { label: "End Date", key: "endDateTime" },
  { label: "Timezone", key: "eventTimeZone" },
  { label: "Event Privacy", key: "eventPrivacy" },
  { label: "Event Mode", key: "eventMode" },
];

export default function ErrorLogsTable({
  //
  getIMSproductlookupData,
}) {
  const [columns, setColumns] = useState([]);

  const [data, setData] = useState([]);

  const [pending, setPending] = React.useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const [searcgOption, setSearchOption] = useState([]);
  const [searcValueByuser, setSearchValueByuser] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const SearchHandle = async (e) => {
    if (e !== 0) {
      await getIMSproductlookupData(e)
        .then((res) => {
          setSearchOption(res.data);
        })
        .catch((err) => {});
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const HandleApplyFilter = async () => {
    setPending(true);

    await getErrorList(searcValueByuser, page, perPage)
      .then((res) => {
        setData(res.data.data);
        setTotalRows(res.data.counter);
      })
      .catch((err) => {});
    setPending(false);
  };
  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state?.selectedRows.map((r) => r.id));
  }, []);

  const contextActions = React.useMemo(() => {
    const handleDelete = async () => {
      await DeleteErrorLogs(selectedRows)
        .then((res) => {
          toast.success(res.data.message);
          setToggleCleared(!toggleCleared);
          HandleApplyFilter();
        })
        .catch((err) => {});
    };

    return (
      <Button
        key="delete"
        onClick={handleDelete}
        style={{ backgroundColor: "red", color: "white" }}
        icon
      >
        Delete
      </Button>
    );
  }, [data, selectedRows, toggleCleared]);

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div>
        <div
          style={{ display: "flex", columnGap: "30px", alignItems: "center" }}
        >
          <TextField
            id="outlined-basic"
            label="Search by Url"
            variant="outlined"
            style={{
              display: "flex",
              width: "400px",
              justifyContent: "flex-start",
            }}
            sx={{"& .MuiOutlinedInput-notchedOutline": {
              borderRadius: "0px",
            }}}
            onChange={(e) => {
              setSearchValueByuser(e.target.value);
            }}
          />

          <Button
            variant="contained"
            onClick={(searcValueByuser) => HandleApplyFilter()}
            style={{ backgroundColor: "rgb(255, 102, 0)", color: "white" }}
          >
            Search
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setPage(1);
              setTotalRows(0);
              setPerPage(10);

              HandleApplyFilter();
            }}
          >
            Refresh
          </Button>
        </div>
      </div>
    );
  }, [SearchHandle, searcValueByuser]);

  const handleDateTime = (value) => {
    // const date = new Date(value);
    // const date = moment.tz(value, moment.tz.guess());

    const date = moment(value);
    const formattedDate = date.utc().format("DD-MMM-YY HH:mm");

    return formattedDate;
    // return moment(date).format("DD-MMM-YY HH:mm");
  };

  const ExpandedComponent = ({ data }) => (
    <div
      style={{
        width: "100%",
        padding: "20px 40px",
        display: "flex",
        flexDirection: "column",
        rowGap: "10px",
      }}
    >
      <span>
        <strong>user Id : </strong>
        <span>{data?.extra.split(",")[0].split(":")[1]}</span>
      </span>
      <span>
        <strong>Platform : </strong>
        <span>{data?.extra.split(",")[1].split(":")[1]}</span>
      </span>
      <span>
        <strong>Url: </strong>
        <span>{data?.url}</span>
      </span>
      <span>
        <strong>Query Parameters: </strong>
        <span>{data?.queryParameters}</span>
      </span>
      <span>
        <strong>Request Data: </strong>
        <span>{data?.requestData}</span>
      </span>

      <span>
        <strong>Response Data: </strong>
        <span>{data?.responseData}</span>
      </span>
      <span>
        <strong>error: </strong>
        <span>{data?.error}</span>
      </span>
    </div>
  );

  useEffect(() => {
    setColumns([
      {
        name: "Time",
        selector: (row) => handleDateTime(row.createdAt),
        sortable: false,
        maxWidth: "200px",
      },
      {
        name: "URL",
        selector: (row) => row.url,
        sortable: true,
        maxWidth: "600px",
      },
      {
        name: "Time Ago",
        selector: (row) => moment(row.createdAt).fromNow(),
        sortable: true,
      },
    ]);
  }, []);

  const handlePageChange = (page) => {
    setPage(page);
  };

  useEffect(() => {
    HandleApplyFilter();
  }, [page, perPage]);

  return (
    <>
      <DataTable
        title={<p>{"Error Logs"}</p>}
        columns={columns}
        data={data}
        progressPending={pending}
        pagination
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        fixedHeader
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        highlightOnHover
        selectableRows
        contextActions={contextActions}
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
        // row exapndable
        expandableRows={true}
        expandableRowsComponent={ExpandedComponent}
        expandOnRowClicked={false}
        expandOnRowDoubleClicked={false}
        expandableRowsHideExpander={false}
      />
    </>
  );
}
