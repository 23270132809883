import {
  CloudDownload,
  CloudUpload,
  CloudUploadSharp,
  RestartAlt,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  styled,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import rosterExcelFile from "./Roster_Demo_Product_Sheet.xlsx";
import * as XLSX from "xlsx";
import { useState } from "react";
import DataTable from "react-data-table-component";
import {
  getDatabaseLabelForRosterProductType,
  getExcelSheetLabelForRosterProductType,
  getRosterProdutStatusCode,
  handleDateTime,
} from "utils/common";
import { useEffect } from "react";
import {
  rosterImportDefaultExcelColumn,
  rosterImportDefaultExcelDateColumn,
  rosterImportDefaultExcelSelectableColumn,
  rosterProductImportDefaultExcelColumn,
  rosterProductImportDefaultExcelDateColumn,
  rosterProductImportDefaultExcelSelectableColumn,
  rosterProductImportExcelSheetMandatoryFields,
  rosterProductImportExcelSheetMandatoryFieldsByProductType,
  rosterProductImportExcelSheetMandatoryFieldsForAllProductType,
  rosterProductImportExcelSheetProductType,
  rosterProductImportExcelSheetPurchasePriceType,
  rosterProductImportExcelSheetStatus,
} from "utils/constant";
import {
  fetchProductAndAssetIdListForOrganization,
  fetchProductTypeAndItsSupportedCategoryForRosterProductImport,
  fetchRosterChildLocationListForRosterProductImport,
  getCategoryListForRosterProductImport,
  saveImportedRosterProduct,
} from "api/api";
import { useParams } from "react-router";
import { v1 as uuidv1 } from "uuid";

export default function ProductImportV2() {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [showDataTable, setShowDataTable] = useState(false);
  const [showUploadHeader, setShowUploadHeader] = useState(true);
  const [fileExtractingLoader, setFileExtractingLoader] = useState(false);
  const [resetButtonContainer, setResetButtonContainer] = useState(false);
  const [showExcelGuidelines, setShowExcelGuidelines] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [leafLocationList, setLeafLocationList] = useState([]);
  const [
    productTypeWithSupportedCategory,
    setProductTypeWithSupportedCategory,
  ] = useState([]);
  const [isExcelValid, setIsExcelValid] = useState(false);
  const [productWithAssetIdList, setProductWithAssetIdList] = useState([]);
  const [productExistMode, setProductExistMode] = useState(false);

  const params = useParams();

  function columnEquality(arr1, arr2) {
    // Compare each element in the arrays
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return {
          status: false,
          unmatchedColumnIndex: i,
        };
      }
    }

    return {
      status: true,
    };
  }

  const getLocationById = (locationId) => {
    if (!locationId) {
      return null;
    }

    const loc = leafLocationList.find((e) => e["id"] === locationId);

    if (!loc) {
      return null;
    }

    return loc.name;
  };

  const handleFileupload = (e) => {
    setShowExcelGuidelines(false);
    setFileExtractingLoader(true);
    setProductExistMode(false);
    const reader = new FileReader();
    reader.readAsArrayBuffer(e.target.files[0]);

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      let parsedData = XLSX.utils.sheet_to_json(sheet);

      //   Filtering the excel based on Product Name column Start
      parsedData = parsedData.filter(
        (e) =>
          e[
            rosterProductImportExcelSheetMandatoryFieldsForAllProductType
              .Product_Name
          ]
      );
      //   Filtering the excel based on Product Name column End

      const cellError = [];

      // Removing 'Select' value start
      // If there is 'Select' then converting it into empty string ''.
      const selectableColumnNames = Object.values(
        rosterProductImportDefaultExcelSelectableColumn
      );

      for (const column of selectableColumnNames) {
        parsedData = parsedData.map((e) => {
          e[column] = e[column] === "Select" ? "" : e[column];
          return e;
        });
      }

      // Removing 'Select' value end

      const mandatoryFields = Object.values(
        rosterProductImportExcelSheetMandatoryFieldsForAllProductType
      );

      //   Column validation Start
      const defaultColumns = Object.values(
        rosterProductImportDefaultExcelColumn
      );

      //   Mandatory field validation Start
      for (const mandatoryField of mandatoryFields) {
        let i = 0;

        for (const currentRow of parsedData) {
          if (
            Object.keys(currentRow).includes(mandatoryField) &&
            currentRow[mandatoryField]
          ) {
          } else {
            const cellErrorObj = { id: i, columnName: mandatoryField };
            cellError.push(cellErrorObj);

            const content = `Row No: ${
              i + 1
            }: Please specify a value for the '${mandatoryField}' column for product ${
              currentRow[
                rosterProductImportExcelSheetMandatoryFieldsForAllProductType
                  .Product_Name
              ]
            }`;
            alerts.push({ severity: "error", content: content });
            setResetButtonContainer(true);
          }
          i++;
        }
      }
      //   Mandatory field validation End

      //   Product Type Column validation Start
      for (let i = 0; i < parsedData.length; i++) {
        if (
          Object.values(rosterProductImportExcelSheetProductType).includes(
            parsedData[i][
              rosterProductImportExcelSheetMandatoryFieldsForAllProductType
                .Product_Type
            ]
          )
        ) {
        } else {
          const cellErrorObj = {
            id: i,
            columnName:
              rosterProductImportExcelSheetMandatoryFieldsForAllProductType.Product_Type,
          };
          cellError.push(cellErrorObj);

          const content = `Row No: ${i + 1}: Invalid Product Type`;
          alerts.push({ severity: "error", content: content });
          setResetButtonContainer(true);
        }
      }
      //   Product Type Column validation End

      // Product Category column validation start
      const categoryListWithTitle = categoryList.map((e) => e?.name);
      for (let i = 0; i < parsedData.length; i++) {
        if (
          categoryListWithTitle.includes(
            parsedData[i][
              rosterProductImportExcelSheetMandatoryFieldsForAllProductType
                .Product_Category
            ]
          )
        ) {
          parsedData[i] = {
            ...parsedData[i],
            categoryId: categoryList.find(
              (e) =>
                e.name ===
                parsedData[i][
                  rosterProductImportExcelSheetMandatoryFieldsForAllProductType
                    .Product_Category
                ]
            ).id,
          };
        } else {
          const cellErrorObj = {
            id: i,
            columnName:
              rosterProductImportExcelSheetMandatoryFieldsForAllProductType.Product_Category,
          };
          cellError.push(cellErrorObj);

          const content = `Row No: ${i + 1}: Invalid Product Category`;
          alerts.push({ severity: "error", content: content });
          setResetButtonContainer(true);
        }
      }
      // Product Category column validation end

      // Location column validation start
      const leaflocationListTemp = leafLocationList.map((e) => e?.id);
      for (let i = 0; i < parsedData.length; i++) {
        if (
          leaflocationListTemp.includes(
            parsedData[i][
              rosterProductImportExcelSheetMandatoryFieldsForAllProductType
                .Location_Id
            ]
          )
        ) {
          parsedData[i]["Location"] = leafLocationList.find(
            (e) =>
              e.id ===
              parsedData[i][
                rosterProductImportExcelSheetMandatoryFieldsForAllProductType
                  .Location_Id
              ]
          )?.name;
        } else {
          const cellErrorObj = {
            id: i,
            columnName:
              rosterProductImportExcelSheetMandatoryFieldsForAllProductType.Location_Id,
          };
          cellError.push(cellErrorObj);

          const locationInvalid =
            parsedData[i][
              rosterProductImportExcelSheetMandatoryFieldsForAllProductType
                .Location_Id
            ];
          const content = locationInvalid
            ? `Row No: ${
                i + 1
              }: Invalid Location, Please make sure location id - ${
                locationInvalid ? locationInvalid : ""
              } is exist!`
            : `Row No: ${i + 1} You have not specified location for product ${
                parsedData[i][
                  rosterProductImportExcelSheetMandatoryFieldsForAllProductType
                    .Product_Name
                ]
              }`;
          alerts.push({ severity: "error", content: content });
          setResetButtonContainer(true);
        }
      }
      // Location column validation end

      // Status column validation start
      for (let i = 0; i < parsedData.length; i++) {
        const currentStatus =
          parsedData[i][
            rosterProductImportExcelSheetMandatoryFieldsForAllProductType.Status
          ];
        if (
          currentStatus &&
          Object.values(rosterProductImportExcelSheetStatus).includes(
            currentStatus
          )
        ) {
        } else {
          const cellErrorObj = {
            id: i,
            columnName:
              rosterProductImportExcelSheetMandatoryFieldsForAllProductType.Status,
          };
          cellError.push(cellErrorObj);

          const content = `Row No: ${i + 1}: Invalid status - ${currentStatus}`;
          alerts.push({ severity: "error", content: content });
          setResetButtonContainer(true);
        }
      }
      // Status column validation end

      // Purchase Price Type column validation start
      for (let i = 0; i < parsedData.length; i++) {
        const currentPurchasePriceType =
          parsedData[i][
            rosterProductImportDefaultExcelSelectableColumn.Purchase_Price_Type
          ];
        if (currentPurchasePriceType) {
          if (
            Object.values(
              rosterProductImportExcelSheetPurchasePriceType
            ).includes(currentPurchasePriceType)
          ) {
          } else {
            const cellErrorObj = {
              id: i,
              columnName:
                rosterProductImportDefaultExcelColumn.Purchase_Price_Type,
            };
            cellError.push(cellErrorObj);

            const content = `Row No: ${i + 1}: Invalid ${
              rosterProductImportDefaultExcelSelectableColumn.Purchase_Price_Type
            } - ${currentPurchasePriceType}`;
            alerts.push({ severity: "error", content: content });
            setResetButtonContainer(true);
          }
        }
      }
      // Purchase Price Type column validation end

      //  Product type & category combine validation start
      for (let i = 0; i < parsedData.length; i++) {
        const productType =
          parsedData[i][rosterProductImportDefaultExcelColumn.Product_Type];
        const category =
          parsedData[i][rosterProductImportDefaultExcelColumn.Product_Category];
        const categoryId = categoryList.find((e) => e.name === category)?.id;
        const imspt = getDatabaseLabelForRosterProductType(productType);

        if (!imspt) {
          const cellErrorObj = {
            id: i,
            columnName: rosterProductImportDefaultExcelColumn.Product_Type,
          };
          cellError.push(cellErrorObj);
        } else {
          const supportedCategoryIdList = productTypeWithSupportedCategory.find(
            (e) => e.name === imspt
          )?.supportedCategoryIdList;

          if (supportedCategoryIdList?.includes(categoryId)) {
          } else {
            const cellErrorObj = {
              id: i,
              columnName:
                rosterProductImportDefaultExcelColumn.Product_Category,
            };
            cellError.push(cellErrorObj);

            const content = `Row No: ${
              i + 1
            }: The selected category is valid, but it is not supported for the ${productType} type.`;
            alerts.push({ severity: "error", content: content });
            setResetButtonContainer(true);
          }
        }
      }
      //  Product type & category combine validation end

      //   Applying date formatter start
      const dateColumnList = Object.values(
        rosterProductImportDefaultExcelDateColumn
      );
      for (const currentDateField of dateColumnList) {
        parsedData = parsedData.map((e) => {
          e[currentDateField] = e[currentDateField]
            ? excelDateToGeneralDate(e[currentDateField])
            : null;
          return e;
        });
      }
      //   Applying date formatter end

      // const set = new Set();

      // for(let i = 0; i < parsedData.length; i++) {
      //   const obj = {
      //     productType: parsedData[i][rosterProductImportDefaultExcelColumn.Product_Type],
      //     category: parsedData[i][rosterProductImportDefaultExcelColumn.Product_Category],
      //     name: parsedData[i][rosterProductImportDefaultExcelColumn.Product_Name],
      //     location: parsedData[i][rosterProductImportDefaultExcelColumn.Location_Id],
      //   };

      //   const objString = JSON.stringify(obj); // Avoid recomputation
      //   if(set.has(objString)) {
      //     parsedData[i] = {
      //       ...parsedData[i],
      //       isDuplicated: true,
      //     };
      //   } else {
      //     set.add(objString);
      //   }
      // }

      // Product exist in databse logic start
      if (!(cellError.length > 0)) {
        for (let i = 0; i < parsedData.length; i++) {
          const excelSheetCategory =
            parsedData[i][
              rosterProductImportDefaultExcelColumn.Product_Category
            ];
          const excelSheetProductType =
            parsedData[i][rosterProductImportDefaultExcelColumn.Product_Type];
          const excelSheetLocation =
            parsedData[i][rosterProductImportDefaultExcelColumn.Location_Id];
          const excelSheetAssetId =
            parsedData[i][
              rosterProductImportDefaultExcelColumn.AssetTag_Number
            ];
          const excelProductName =
            parsedData[i][rosterProductImportDefaultExcelColumn.Product_Name];

          const stringifyRow = JSON.stringify({
            productName: excelProductName?.toLowerCase(),
            productType: getDatabaseLabelForRosterProductType(
              excelSheetProductType
            ),
            categoryId: categoryList.find((e) => e.name === excelSheetCategory)
              .id,
            locationId: leafLocationList.find(
              (e) => e.id === excelSheetLocation
            ).id,
            // assetIdTagNumber: excelSheetAssetId
          });

          productWithAssetIdList.map((e) => {
            const customizedObject = {
              productName: e.productName.toLowerCase(),
              productType: e.productType,
              categoryId: e.categoryId,
              locationId: e.locationId,
            };

            const isExist = JSON.stringify(customizedObject) === stringifyRow;
            if (isExist) {
              parsedData[i] = {
                ...parsedData[i],
                isExistInDatabase: true,
                dbProductId: e?.dbProductId,
              };
            }
            return isExist;
          });
        }
      }
      // Product exist in databse logic start

      // AssetIt (local) checking logic start
      if (!(cellError.length > 0)) {
        const set = new Set();
        for (let i = 0; i < parsedData.length; i++) {
          const excelSheetAssetId =
            parsedData[i][
              rosterProductImportDefaultExcelColumn.AssetTag_Number
            ];

          if (excelSheetAssetId) {
            if (set.has(excelSheetAssetId)) {
              const cellErrorObj = {
                id: i,
                columnName:
                  rosterProductImportDefaultExcelColumn.AssetTag_Number,
              };
              cellError.push(cellErrorObj);

              const content = `Row No: ${
                i + 1
              }: Please set up unique asset id for each product!`;
              alerts.push({ severity: "error", content: content });
              setResetButtonContainer(true);
            } else {
              set.add(excelSheetAssetId);
            }
          }
        }
      }
      // AssetIt checking logic end

      // Product's asset-id exist in databse logic start
      if (!(cellError.length > 0)) {
        for (let i = 0; i < parsedData.length; i++) {
          if (
            parsedData[i][
              rosterProductImportDefaultExcelColumn.AssetTag_Number
            ] &&
            !parsedData[i]?.isExistInDatabase
          ) {
            const excelSheetAssetId =
              parsedData[i][
                rosterProductImportDefaultExcelColumn.AssetTag_Number
              ];

            const assetIdExistProducts = productWithAssetIdList.filter(
              (e) => e.assetIdTagNumber === excelSheetAssetId
            );

            if (assetIdExistProducts.length > 0) {
              const cellErrorObj = {
                id: i,
                columnName:
                  rosterProductImportDefaultExcelColumn.AssetTag_Number,
              };
              cellError.push(cellErrorObj);

              const locationId = productWithAssetIdList.find((e) => {
                return (
                  e["locationId"] === assetIdExistProducts[0]["locationId"]
                );
              })["locationId"];

              const content = `Row No: ${
                i + 1
              }: Asset Id ${excelSheetAssetId} is already exist for product ${
                assetIdExistProducts[0].productName
              } of location ${getLocationById(locationId)}!`;
              alerts.push({ severity: "error", content: content });
              setResetButtonContainer(true);
            }
          }
        }
      }
      // Product's asset-id exist in databse logic end

      parsedData = parsedData.map((e, rowIndex) => {
        const cellErrors = cellError.filter((c) => c.id === rowIndex);
        let errorColumnList = [];
        for (const { id: rowIndex, columnName } of cellErrors) {
          errorColumnList.push(columnName);
        }

        const obj = {
          ...e,
          hasError: cellErrors.length > 0 ? true : false,
          errorColumnList,
          id: uuidv1(),
          override: e["isExistInDatabase"] ? true : false, // this is default selected option
        };
        return obj;
      });

      const existingProductsWithTableTemplate = [];
      const newerProductsWithTableTemplate = [];

      for (const product of parsedData) {
        if (product["isExistInDatabase"]) {
          existingProductsWithTableTemplate.push(product);
        } else {
          newerProductsWithTableTemplate.push(product);
        }
      }

      // Existing Products exist only
      if (
        existingProductsWithTableTemplate.length > 0 &&
        newerProductsWithTableTemplate.length === 0
      ) {
        setProductExistMode(true);
        // setData(existingProductsWithTableTemplate)
      }

      if (parsedData.length === 0) {
        setIsExcelValid(false);
        setResetButtonContainer(true);
        const content = `Invalid Excel Sheet or no record found!`;
        alerts.push({ severity: "error", content: content });
      } else {
        // Sheet is valid or not
        if (cellError.length > 0) {
          setIsExcelValid(false);
        }

        if (!(cellError.length > 0)) {
          setResetButtonContainer(true);
          setIsExcelValid(true);
          if (
            existingProductsWithTableTemplate.length > 0 &&
            newerProductsWithTableTemplate.length === 0
          ) {
            const content = `Please choose an option: either override the existing product or add it as a new product.`;
            alerts.push({ severity: "success", content: content });
          } else {
            const content = `Success! The Excel sheet has been validated and is ready to be submitted.`;
            alerts.push({ severity: "success", content: content });
          }
        }
      }

      setData(parsedData);
      setFileExtractingLoader(false);
      setShowDataTable(true);
      setShowUploadHeader(false);
    };
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const onClickDownloadSampleProductFile = () => {
    const excelFileUrl = rosterExcelFile; // Update this path
    const anchor = document.createElement("a");
    anchor.href = excelFileUrl;
    anchor.download = "Roster_Demo_Product_Sheet.xlsx";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }; 

  function excelDateToGeneralDate(excelDate) {
    // Excel date starts from January 1, 1900, so create a reference date
    const excelStartDate = new Date(Date.UTC(1899, 11, 30)); // 30 Dec 1899 to adjust for Excel date system

    // Add the Excel date as days to the reference date
    const utcDate = new Date(excelStartDate.getTime() + excelDate * 86400000); // 86400000 ms in a day

    // Return the date in UTC format without the time part
    return utcDate.toISOString().split("T")[0];
  }

  const fetchResourcesForRosterProductImport = async () => {
    try {
      // Category Fetching
      const respone = await getCategoryListForRosterProductImport();
      setCategoryList(respone?.data);

      const locationResponse =
        await fetchRosterChildLocationListForRosterProductImport(params?.id,'');
      setLeafLocationList(locationResponse?.data);

      const productTypeWithSupportedCategory =
        await fetchProductTypeAndItsSupportedCategoryForRosterProductImport();
      setProductTypeWithSupportedCategory(
        productTypeWithSupportedCategory.data
      );

      const productWithAssetIdResponse =
        await fetchProductAndAssetIdListForOrganization(params?.id);
      setProductWithAssetIdList(productWithAssetIdResponse.data);
    } catch (error) {
      alert("Error in fetching Roster Product Resources!");
    }
  };

  const ProductAction = ({ row, setData, id }) => {
    const handleChange = (value) => {
      setData((pre) => {
        return pre.map((e) => {
          if (e.id === id) {
            return { ...e, override: value === "override" ? true : false };
          } else {
            return { ...e };
          }
        });
      });
    };

    return (
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="action"
          name="actionType"
          value={row.override ? "override" : "add"}
          onChange={(e) => handleChange(e.target.value)}
        >
          <FormControlLabel
            value="add"
            control={<Radio />}
            label="Add as New"
          />
          <FormControlLabel
            value="override"
            control={<Radio />}
            label="Override Product"
          />
        </RadioGroup>
      </FormControl>
    );
  };

  // Define conditional row styling based on row data
  const conditionalRowStyles = [
    // {
    //   when: (row) => row.isExistInDatabase, // Condition: if age is less than 25
    //   style: {
    //     backgroundColor: "", // Apply green background
    //     color: "black",
    //   },
    // },
  ];

  const productDataTable = [
    {
      name: "#",
      // selector: (row, index) => row.override ? 'override':'new' ,
      selector: (row, index) => index + 1,
      width: "100px",
      // conditionalCellStyles: [
      //   {
      //     when: (row) =>
      //       row.isDuplicated, // Condition for red border
      //     style: {
      //       // border: "1px solid red", // Apply red border
      //       // borderRadius:'10px'
      //       color: "white",
      //       backgroundColor: "red",
      //     },
      //   },
      // ],
    },
    {
      name: "Upload Status",
      cell: (row) => <ProductAction row={row} setData={setData} id={row.id} />,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "300px",
      omit: productExistMode ? false : true,
    },
    {
      name: "Product Type*",
      selector: (row) => row["Product Type"],
      width: "135px",
      conditionalCellStyles: [
        {
          when: (row) =>
            row.hasError && row.errorColumnList.includes("Product Type"), // Condition for red border
          style: {
            // border: "1px solid red", // Apply red border
            // borderRadius:'10px'
            color: "red",
            backgroundColor: "yellow",
          },
        },
      ],
    },
    {
      name: "Product Category*",
      selector: (row) => row["Product Category"],
      width: "210px",
      conditionalCellStyles: [
        {
          when: (row) =>
            row.hasError && row.errorColumnList.includes("Product Category"), // Condition for red border
          style: {
            // border: "1px solid red", // Apply red border
            // borderRadius:'10px'
            color: "red",
            backgroundColor: "yellow",
          },
        },
      ],
    },
    {
      name: "Location Id*",
      selector: (row) => row["Location Id"],
      width: "350px",
      wrap: true,
      conditionalCellStyles: [
        {
          when: (row) =>
            row.hasError && row.errorColumnList.includes("Location Id"), // Condition for red border
          style: {
            // border: "1px solid red", // Apply red border
            // borderRadius:'10px'
            color: "red",
            backgroundColor: "yellow",
          },
        },
      ],
    },
    {
      name: "Location*",
      selector: (row) => row["Location"],
      width: "350px",
      wrap: true,
      conditionalCellStyles: [
        {
          when: (row) =>
            row.hasError && row.errorColumnList.includes("Location"), // Condition for red border
          style: {
            // border: "1px solid red", // Apply red border
            // borderRadius:'10px'
            color: "red",
            backgroundColor: "yellow",
          },
        },
      ],
    },
    {
      name: "Manufacturer*",
      selector: (row) => row["Manufacturer"],
      width: "210px",
      conditionalCellStyles: [
        {
          when: (row) =>
            row.hasError && row.errorColumnList.includes("Manufacturer"), // Condition for red border
          style: {
            // border: "1px solid red", // Apply red border
            // borderRadius:'10px'
            color: "red",
            backgroundColor: "yellow",
          },
        },
      ],
    },

    {
      name: "Product Name*",
      selector: (row) => `${row["Product Name"]}`,
      width: "210px",
      conditionalCellStyles: [
        {
          when: (row) =>
            (row.hasError && row.errorColumnList.includes("Product Name")) ||
            row?.isDuplicated, // Condition for red border
          style: {
            // border: "1px solid red", // Apply red border
            // borderRadius:'10px'
            color: "red",
            backgroundColor: "yellow",
          },
        },
      ],
    },
    {
      name: "Status*",
      selector: (row) => row["Status"],
      width: "210px",
      conditionalCellStyles: [
        {
          when: (row) => row.hasError && row.errorColumnList.includes("Status"), // Condition for red border
          style: {
            // border: "1px solid red", // Apply red border
            // borderRadius:'10px'
            color: "red",
            backgroundColor: "yellow",
          },
        },
      ],
    },
    {
      name: "Model Number",
      selector: (row) => row["Model Number"],
      width: "210px",
    },
    {
      name: "Serial Number",
      selector: (row) => row["Serial Number"],
      width: "200px",
    },
    {
      name: "Manufacturer Product Id",
      selector: (row) => row["Manufacturer Product Id"],
      width: "200px",
    },
    {
      name: "Vendor Product Id",
      selector: (row) => row["Vendor Product Id"],
      width: "200px",
    },
    {
      name: "Purchase Date",
      selector: (row) => row["Purchase Date"],
      width: "200px",
    },
    {
      name: "Warranty Start Date",
      selector: (row) => row["Warranty Start Date"],
      width: "200px",
    },
    {
      name: "Warranty End Date",
      selector: (row) => row["Warranty End Date"],
      width: "200px",
    },
    {
      name: "Purchase Price Type",
      selector: (row) => row["Purchase Price Type"],
      width: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            row.hasError && row.errorColumnList.includes("Purchase Price Type"), // Condition for red border
          style: {
            // border: "1px solid red", // Apply red border
            // borderRadius:'10px'
            color: "red",
            backgroundColor: "yellow",
          },
        },
      ],
    },
    {
      name: "Purchase Price",
      selector: (row) => row["Purchase Price"],
      width: "200px",
    },
    {
      name: "AssetTag Number",
      selector: (row) => row["AssetTag Number"],
      width: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            row.hasError && row.errorColumnList.includes("AssetTag Number"), // Condition for red border
          style: {
            // border: "1px solid red", // Apply red border
            // borderRadius:'10px'
            color: "red",
            backgroundColor: "yellow",
          },
        },
      ],
    },
    {
      name: "Consultant Organization",
      selector: (row) => row["Consultant Organization"],
      width: "200px",
    },
    {
      name: "Integrator Organization",
      selector: (row) => row["Integrator Organization"],
      width: "200px",
    },
    {
      name: "Vendor Organization",
      selector: (row) => row["Vendor Organization"],
      width: "200px",
    },
    {
      name: "Expected Replacement Date",
      selector: (row) => row["Expected Replacement Date"],
      width: "200px",
    },
    {
      name: "End of Life Date",
      selector: (row) => row["End of Life Date"],
      width: "200px",
    },
    {
      name: "Version",
      selector: (row) => row["Version"],
      width: "200px",
    },
  ];

  useEffect(() => {
    setColumns(productDataTable);

    fetchResourcesForRosterProductImport();
  }, []);

  useEffect(() => {
    setColumns(productDataTable);
  }, [productExistMode]);

  const ExtractingDataLoader = () => {
    return (
      <Box
        sx={{
          width: "100%",
          height: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: "white",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Extracting Excel Data
        </Typography>
        <CircularProgress />
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          Please wait while we process the file...
        </Typography>
      </Box>
    );
  };

  const handleResetClick = () => {
    setAlerts([]);
    setData([]);
    setShowUploadHeader(true);
    setShowDataTable(false);
    setResetButtonContainer(false);
    setShowExcelGuidelines(true);
    setProductExistMode(false);
    fetchResourcesForRosterProductImport();
  };

  const handleSubmit = async () => {
    const newerProducts = [];
    const existingProducts = [];

    const newerProductsWithTableTemplate = [];
    const existingProductsWithTableTemplate = [];

    for (const product of data) {
      const obj = {
        locationId: product[rosterProductImportDefaultExcelColumn.Location_Id],
        productName:
          product[rosterProductImportDefaultExcelColumn.Product_Name],
        categoryId: product["categoryId"],
        productType: getDatabaseLabelForRosterProductType(
          product[rosterProductImportDefaultExcelColumn.Product_Type]
        ),
        status: getRosterProdutStatusCode(
          product[rosterProductImportDefaultExcelColumn.Status]
        ),

        modelNumber:
          product[rosterProductImportDefaultExcelColumn.Model_Number] || null,
        serialNumber:
          product[rosterProductImportDefaultExcelColumn.Serial_Number] || null,
        manufacturerProductId:product[rosterProductImportDefaultExcelColumn.Manufacturer_Product_Id].toString() || null,
        vendorProductId:product[rosterProductImportDefaultExcelColumn.Vendor_Product_Id].toString() || null,
        purchaseDate:
          product[rosterProductImportDefaultExcelColumn.Purchase_Date] || null,
        warrantyStartDate:
          product[rosterProductImportDefaultExcelColumn.Warranty_Start_Date] ||
          null,
        warrantyEndDate:
          product[rosterProductImportDefaultExcelColumn.Warranty_End_Date] ||
          null,
        expectedReplacementDate:
          product[
            rosterProductImportDefaultExcelColumn.Expected_Replacement_Date
          ] || null,
        endOfLifeDate:
          product[rosterProductImportDefaultExcelColumn.End_of_Life_Date] ||
          null,

        version: product[rosterProductImportDefaultExcelColumn.Version] || null,
        purchasePriceType:
          product[rosterProductImportDefaultExcelColumn.Purchase_Price_Type] ||
          null,
        purchasePrice:
          product[rosterProductImportDefaultExcelColumn.Purchase_Price] || null,

        assetTagNumber:
          product[rosterProductImportDefaultExcelColumn.AssetTag_Number] ||
          null,

        manufacturer:
          product[rosterProductImportDefaultExcelColumn.Manufacturer] || null,
        consultant:
          product[
            rosterProductImportDefaultExcelColumn.Consultant_Organization
          ] || null,
        integrator:
          product[
            rosterProductImportDefaultExcelColumn.Integrator_Organization
          ] || null,
        vendor:
          product[rosterProductImportDefaultExcelColumn.Vendor_Organization] ||
          null,

        isExistInDatabase: product["isExistInDatabase"] || false,
        override: product["override"],
        dbProductId: product["dbProductId"] || null,
      };

      if (product["isExistInDatabase"]) {
        existingProducts.push(obj);
        existingProductsWithTableTemplate.push(product);
      } else {
        newerProducts.push(obj);
        newerProductsWithTableTemplate.push(product);
      }
    }

    // Newer Products exist only
    if (newerProducts.length > 0 && existingProducts.length === 0) {
      try {
        await saveProducts(newerProducts);
        handleResetClick();
      } catch (error) {
          // Log the error for debugging
          console.error("Error saving products:", error);

          // Display a user-friendly error message
          const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred while saving the products.';
          alert(errorMessage)
      }
    }

    // If both are exist - Newer & Existing
    if (existingProducts.length > 0 && newerProducts.length > 0) {
      try {
        await saveProducts(newerProducts); // saving newer products
        setData(() => existingProductsWithTableTemplate);
        setProductExistMode(true);
        const content = `Please choose an option: either override the existing product or add it as a new product.`;
        alerts.push({ severity: "success", content: content });
      } catch (error) {
          // Log the error for debugging
        console.error("Error saving products:", error);

        // Display a user-friendly error message
        const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred while saving the products.';
        alert(errorMessage)
      }
    }

    if (existingProducts.length > 0 && newerProducts.length === 0) {
      try {
        await saveProducts(existingProducts);
        handleResetClick();
      } catch (error) {
        // Log the error for debugging
        // console.error("Error saving products:", error);

        // Display a user-friendly error message
        const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred while saving the products.';
        alert(errorMessage)
      }
    }
  };

  const saveProducts = async (data) => {
      // Asset-id checking logic start
      // If product is new then asset-id should be blank or unique

      const newerProducts = data.filter(
        (e) => e["override"] === false && e["assetTagNumber"]
      );

      for (const { assetTagNumber, productName } of newerProducts) {
        const assetIdExistProducts = productWithAssetIdList.filter(
          (e) => e.assetIdTagNumber === assetTagNumber
        );

        if (assetIdExistProducts.length > 0) {
          const message = `Asset Id for your newer product ${productName} is already registered! Please add newer asset number or leave it blank & re-upload your excel sheet!`;
          throw new Error(message);
        }
      }
      // Asset-id checking logic end

      const response = await saveImportedRosterProduct(data, params?.id);

      if (response.data?.status === "success") {
        alert("Saved successfully");
        // Optionally, you can return here to prevent further execution
        return;
      } else {
        // Handle specific failure reasons if available
        const errorMessage =
          response.data?.message || "Failed to save the product.";
        throw new Error(errorMessage);
      }
   
  };

  const onClickExportLeafLocation = () => {
    // Create a worksheet from the leafLocations data
    const ws = XLSX.utils.json_to_sheet(leafLocationList);

    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Leaf Locations");

    // Generate a binary string representing the workbook
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Create a Blob from the binary string and trigger download
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Leaf_Locations.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const SubmitResetButtonContainer = () => {
    return (
      <Box
        display={"flex"}
        sx={{
          gap: 2,
          backgroundColor: "white",
          justifyContent: "center",
          p: 2,
          borderRadius: 2,
        }}
      >
        <Button
          component="label"
          role={undefined}
          variant="outlined"
          disabled={isExcelValid ? false : true}
          tabIndex={-1}
          startIcon={<CloudUploadSharp />}
          color="success"
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>

        <Button
          component="label"
          role={undefined}
          variant="outlined"
          tabIndex={-1}
          startIcon={<RestartAlt />}
          color="error"
          onClick={handleResetClick}
        >
          Reset
        </Button>
      </Box>
    );
  };

  const ExcelGuidelines = () => {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
        <Card sx={{ width: "auto", boxShadow: 3 }}>
          <CardContent>
            <Typography variant="h5" component="div" gutterBottom>
              Excel Guidelines
            </Typography>
            <Divider />
            <List>
              <ListItem>
                <ListItemText
                  primary="Guideline 1"
                  secondary="This is a placeholder for the first Excel guideline."
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary="Guideline 2"
                  secondary="This is a placeholder for the second Excel guideline."
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary="Guideline 3"
                  secondary="This is a placeholder for the third Excel guideline."
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary="Guideline 4"
                  secondary="This is a placeholder for the fourth Excel guideline."
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Box>
    );
  };

  return (
    <>
      {showUploadHeader ? (
        <>
          {" "}
          <Box sx={{ background: "white", mb: 1, px: 2, py: 3, boxShadow: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                startIcon={<CloudUpload />}
                // style={{ width: "25%" }}
                color="info"
              >
                Upload product file
                <VisuallyHiddenInput
                  type="file"
                  accept=".xls,.xlsx,.csv"
                  onChange={handleFileupload}
                />
              </Button>
              <Button
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                startIcon={<CloudDownload />}
                color="info"
                onClick={onClickExportLeafLocation}
              >
                Export location id list
              </Button>
              <Button
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                startIcon={<CloudDownload />}
                color="info"
                onClick={onClickDownloadSampleProductFile}
              >
                Download Demo Product Sheet
              </Button> 
            </Box>
            <Box></Box>
          </Box>
        </>
      ) : (
        <></>
      )}

      {fileExtractingLoader ? <ExtractingDataLoader /> : <></>}

      {showDataTable ? (
        <DataTable
          data={data}
          columns={columns}
          conditionalRowStyles={conditionalRowStyles}
          fixedHeader
        />
      ) : (
        <></>
      )}

      {showExcelGuidelines ? (
        <>
          <ExcelGuidelines />
        </>
      ) : (
        <></>
      )}

      {alerts?.map(({ severity, content }) => {
        return (
          <Alert sx={{ my: 1 }} severity={severity}>
            {content}
          </Alert>
        );
      })}

      {resetButtonContainer ? (
        <>
          {" "}
          <SubmitResetButtonContainer />{" "}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
