import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router";
import { deleteImsProduct, fetchAllConsumerProduct } from "api/api";
import CosnumerProductViewModal from "./ProductViewModal";
import CosnumerProductViewModalForView from "./ProductviewModalForView";
import Loader from "ui-component/Loader";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { AlertDialog, isWarrantyExpired } from "utils/common";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";


const defaultHeaders = [
  "No",
  "Product Category",
  "Location",
  "Manufacturer",
  "Product Name",
  // "Version",
  // "Model Number",
  // "Serial Number",
  // "Purchase Date",
  // "Warrenty Start Date",
  // "Warrenty End Date",
  // "Active",
  // "Status",
  // "Purchase Price Type",
  // "Purchase Price",
  // "Asset-Tag Number",
  // "Consultant Organization",
  // "Integrator Orgnization",
  // "Vendor Organization",
  // "Valid/Error",
  "View product",
];

export default function ConsumerProductViewTable() {
  const [pageSize, setPageSize] = useState(10);
  const [totalcount, setTotalCount] = useState(0);
  const [consumerProductData, setConsumerProduct] = useState([]);
  const [modalOpen, setModalOpenForView] = useState(false);
  const [singleData, setSingleProductData] = useState([]);

  const params = useParams();

  const [loader, setLoader] = useState(false);
  const [columns, setColumns] = useState([]);

  const [data, setData] = useState([]);

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const [deleteStatus, setDeleteStatus] = useState("all");
  const [periodSelect, setPeriodSelect] = useState("All");
  const [searcgOption, setSearchOption] = useState([]);
  const [searcValueByuser, setSearchValueByuser] = useState("");

  const [alertDialogopen, setAlertDialogopen] = React.useState(false);
  const [alertDialogData, setAlertDialogData] = React.useState({});

  const handleClickAlertDialogOpen = (data) => {
    setAlertDialogopen(true);
    setAlertDialogData(data);
  };

  const handleCloseAlertDialog = ()=>{
    setAlertDialogopen(false);
    setAlertDialogData({});
  }

  const handleActionsOnAlertDialog = async (data,action) => {

    switch(action){

      case 'Disagree': 
      
      handleCloseAlertDialog();
      break;
      case 'Agree': 
        
      try {
        const orgId = params?.id;
        const response = await deleteImsProduct(orgId, data.id);
        if(response.data.status==='success'){
          toast.success("Success", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          handleCloseAlertDialog()
          getConsumerProduct(); // to refresh the list
        }
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        alert(errorMessage)
      }
        
      break;
      
      default:
        alert('Action not defined!')
      }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPageSize(newPerPage);
  };

  const handleEventMode = (e) => {
    setDeleteStatus(e);
  };
  const handleEventPeriod = (e) => {
    setPeriodSelect(e);
  };

  const handleModalOpen = (rowdata) => {
    setSingleProductData(rowdata);
    setModalOpenForView(true);
  };

  const getConsumerProduct = async () => {
    setLoader(true);
    try {
      const response = await fetchAllConsumerProduct(
        params?.id,
        page - 1,
        pageSize
      );
      setData(response.data.consumerProductData);
      setTotalRows(response.data.counter);
    } catch (error) {}
    setLoader(false);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div
        style={{
          display: "flex",
          // columnGap: "30px",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <TextField
          id="outlined-basic"
          label="Search by organization name & email"
          variant="outlined"
          style={{
            display: "flex",
            width: "40%",

            justifyContent: "flex-start",
          }}
        />

        <FormControl
          sx={{ m: 1, minWidth: "20%" }}
          // size="small"
        >
          <InputLabel id="demo-select-small-label">
            Organization Type
          </InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            label="Organization Type"
          >
            <MenuItem value={"All"}>All</MenuItem>
            <MenuItem value={"Consumer"}>Consumer</MenuItem>
            <MenuItem value={"Producer"}>Producer</MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  }, []);

  const HoverCellForLocationName = ({ row }) => {
    return (
      <span className="hover-cell">
        {`${row.parentlocation} ${row.locationName}`}
      </span>
    );
  };


  const getWarrantyStatus = (warrantyEndDate) => {
    if (!warrantyEndDate) {
      return {
        label: `Unknown`,
        color: "primary",
      };
    } else if (isWarrantyExpired(warrantyEndDate)) {
      return {
        label: "Expired",
        color: "error",
      };
    } else {
      return {
        label: "In Warranty",
        color: "success",
      };
    }
  };

  useEffect(() => {
    getConsumerProduct();
  }, [params?.id, page, pageSize]);

  useEffect(() => {
    setColumns([
      {
        name: "#",
        selector: (row, index) => index + 1,
        width: "50px",
      },
      {
        name: "Location Name",
        cell: (row) =>
          `${row.parentlocation}${row.parentlocation ? "/" : ""}${
            row.locationName
          }`, // Use the custom cell component'
        width: "40%",
      },
      {
        name: "Product Name",
        selector: (row) => `${row.productName}`,
      },
      {
        name: "Product Type",
        selector: (row) => (
          <>
            <Chip
              label={row.productType || "N/A"} // Display the product type or 'N/A' if undefined
              color="info" // You can adjust the color as needed
              variant="outlined" // Optional: use 'filled' for a solid chip
              size="small" // Adjust the size (small, medium)
            />
          </>
        ),
      },
      {
        name: "Warranty Status",
        selector: (row) => (
          <>
            <Chip
              label={getWarrantyStatus(row?.warrantyEndDate)?.label} // Display the product type or 'N/A' if undefined
              color={getWarrantyStatus(row?.warrantyEndDate)?.color} // You can adjust the color as needed
              variant="outlined" // Optional: use 'filled' for a solid chip
              size="small" // Adjust the size (small, medium)
            />
          </>
        ),
      },
      {
        name: "Category",
        selector: (row) => row.category,
      },
      {
        name: "Actions",
        selector: (row) => (
          <Box display={"flex"} gap={2}>
            <Link
              to={`/dashboard/organization/${params?.id}/roster/product/${row.id}`}
              state={{ url: "/dashboard/organization-list" }}
              style={{ textDecoration: "none" }}
            >
              <VisibilityIcon
                color="primary" // Changes the icon color to primary theme color
                fontSize="medium" // Sets the icon size
                style={{ cursor: "pointer" }} // Adds a pointer cursor on hover
              />
            </Link>
            <DeleteIcon
              onClick={() => {
                handleClickAlertDialogOpen(row);
              }}
              color="error" // Changes the icon color to red
              fontSize="medium" // Increases the icon size
              style={{ cursor: "pointer" }} // Adds pointer cursor on hover
            />
          </Box>
        ),
        ignoreRowClick: true, // Prevents row selection when clicking the button
        allowOverflow: true, // Allows the button to overflow if needed
        button: true, // Indicates this column is intended for a button
      },
    ]);
  }, []);

  return (
    <>
      {loader && <Loader />}
      <>
        <Box mt={1} overflow={"hidden"}>
          <DataTable
            customStyles={{
              subHeader: {
                style: {},
              },
              header: {
                style: {
                  borderTopLeftRadius: "10px", // Top left corner
                  borderTopRightRadius: "10px", // Top right corner
                },
              },
            }}
            title={<p>{"Roster Products"}</p>}
            columns={columns}
            data={data}
            pagination
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            fixedHeader
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover
          />
        </Box>

        <CosnumerProductViewModalForView
          modalOpen={modalOpen}
          setModalopen={setModalOpenForView}
          singleData={singleData}
        />

        <AlertDialog
          title={"Roster Product Delete Confirmation"}
          content={
            "Do you really want to delete this product? Please confirm your action."
          }
          onClose={handleCloseAlertDialog}
          open={alertDialogopen}
          actions={[
            {
              label: "No",
              onClick: handleActionsOnAlertDialog,
              action: "Disagree",
            },
            {
              label: "Yes",
              onClick: handleActionsOnAlertDialog,
              autoFocus: true,
              action: "Agree",
            },
          ]}
          data={alertDialogData}
        />
      </>
    </>
  );
}
