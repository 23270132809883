import React from "react";

import "./orgStyle.css";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  activateOrganizationByAdmin,
  deactivateOrganizationByAdmin,
  deleteAccount,
  FetchOrgDetailsList,
} from "api/api";
import { useState } from "react";
import {
  Alert,
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Loader from "ui-component/Loader";
import {
  formatBytes,
  handleDateTime,
  modalStyleForImageView,
} from "utils/common";
import { organizationActiveStatuses } from "utils/constant";
import { toast } from "react-toastify";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Fade from "@mui/material/Fade";

function OrgDetails() {
  const params = useParams();
  const [orgData, setOrgData] = useState();
  const [loader, setLoader] = useState(true);
  const { state } = useLocation();
  const navigate = useNavigate()

  const [openDialogue, setOpenDialogue] = useState(false);
  const [dialogueData, setDialogueData] = useState({});

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const handleSettingMenuClick = ()=>{
    navigate(`/dashboard/organization-setting/${params.id}`)
  }

  const [actions, setActions] = useState({
    deactivateOrganizationAccount: `deactivateOrganizationAccount`,
    activateOrganizationAccount: `activateOrganizationAccount`,
  });

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        color: "white",
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  const handleOpenDialogue = (orgData, action) => {
    switch (action) {
      case actions.activateOrganizationAccount:
        setDialogueData({
          expectedLabel: `Activate organization ${orgData.name}`,
          action,
          showAlert: false,
          severity: "error",
          alertText: ``,
          organizationId: orgData?.organizationId || null,
          dialogueStatus: dialogueStatuses.activateOrganizationAccount,
        });
        break;

      case actions.deactivateOrganizationAccount:
        setDialogueData({
          expectedLabel: `Deactivate organization ${orgData.name}`,
          action,
          showAlert: false,
          severity: "error",
          alertText: ``,
          organizationId: orgData?.organizationId || null,
          dialogueStatus: dialogueStatuses.deactivateOrganizationAccount,
        });
        break;

      default:
        break;
    }
    setOpenDialogue(true);
  };

  const dialogueStatuses = {
    deactivateOrganizationAccount: `deactivateOrganizationAccount`,
    activateOrganizationAccount: `activateOrganizationAccount`,
  };

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
    setDialogueData({});
  };
  const profileImage =
    "https://www.pngall.com/wp-content/uploads/5/Profile-PNG-Images.png";

  const fetchOrgDetails = async () => {
    await FetchOrgDetailsList(params.id)
      .then((res) => {
        setOrgData(res.data);
        console.log("fetch org details response", res);
        setLoader(false);
      })
      .catch((err) => {
        console.log("fetch org details err", err);
      });
  };

  const avatarTypes = {
    organizationProfileImage: `organizationProfileImage`,
  };

  const handleAvatarClick = (url, inputAvatarTypes) => {
    switch (inputAvatarTypes) {
      case avatarTypes.organizationProfileImage:
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    fetchOrgDetails();
  }, [params.id]);

  return (
    <>
      {loader === true && <Loader />}
      {loader ? (
        <>
          <Box sx={{ backgroundColor: "white", padding: "10px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Stack direction="row" alignItems="center" spacing={2} mb={2}>
                <Skeleton variant="circular" width={60} height={60} />
                <Typography variant="h1">
                  {loader ? <Skeleton width={200} /> : "h1"}
                </Typography>
              </Stack>
            </Box>
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              width={"100%"}
              height={60}
            />
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              width={"100%"}
              height={60}
            />
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              width={"100%"}
              height={60}
            />
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              width={"100%"}
              height={60}
            />
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              width={"100%"}
              height={60}
            />
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              width={"100%"}
              height={60}
            />
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              width={"100%"}
              height={60}
            />
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ backgroundColor: "white", padding: "10px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Stack direction="row" alignItems="center" spacing={2} mb={2}>
                <Avatar
                  src={orgData.profileImage}
                  alt={orgData.name}
                  onClick={() => {
                    handleAvatarClick(
                      orgData.profileImage,
                      avatarTypes.organizationProfileImage
                    );
                  }}
                  sx={{ width: 60, height: 60, cursor: "pointer" }}
                />
                <Typography variant="h3">{orgData.name}</Typography>
                {orgData.organizationActive ? (
                  <Box
                    sx={{
                      width: 10,
                      height: 10,
                      backgroundColor: "green", // Color for active status
                      borderRadius: "50%", // Makes the box a circle
                      display: "inline-block",
                    }}
                  />
                ) : (
                  <></>
                )}
                {!orgData.organizationActive &&
                orgData?.organizationActiveStatus ===
                  organizationActiveStatuses.ORGANIZATION_DEACTIVATED_BY_ADMIN ? (
                  <>
                    <Chip
                      color="error"
                      label="Deactivated Organization by Admin"
                    ></Chip>
                  </>
                ) : (
                  <></>
                )}
              </Stack>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <AvatarGroup total={orgData?.orgUserImageCounter}>
                  {orgData?.orgUserImageList?.map((e) => {
                    if (e?.userProfileImage) {
                      return <Avatar alt="...." src={e?.userProfileImage} />;
                    } else {
                      return <Avatar {...stringAvatar(e?.userName)} />;
                    }
                  })}
                </AvatarGroup>
                <div>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    "aria-labelledby": "fade-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  <MenuItem onClick={handleSettingMenuClick}>Setting</MenuItem>
                 
                </Menu> 
              </div>
              </Box>
      
            
            </Box>

            <Divider textAlign="left" sx={{ mb: 2 }}>
              General detail
            </Divider>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Role:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {orgData.role}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Created At:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {handleDateTime(orgData.createdAt)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Admin Verified :
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {orgData.role === "Producer" && orgData.verified ? "Yes" : ""}
                  {orgData.role === "Producer" && !orgData.verified
                    ? "Not Yet"
                    : ""}
                  {orgData.role === "Consumer" && orgData.verified
                    ? "Default Verified"
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  SubRole :
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {orgData.subRole}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Admin Created/Imported:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {orgData.createdBySystemAdmin ? "Yes" : "No"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Self Registered Organization:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {orgData.createdBySystemAdmin ? "No" : "Yes"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Has Owner:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {orgData.ownership}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Category Sponsor:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {orgData.isSponsor ? "Yes" : "No"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Featured Organization:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {orgData.isFeatured ? "Yes" : "No"}
                </Typography>
              </Grid>
            </Grid>

            <Divider textAlign="left" sx={{ mb: 2, mt: 2 }}>
              Organization details
            </Divider>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Employee size:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {orgData.employeeSize ? orgData.employeeSize : "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Years in Business:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {orgData.yearInBusiness ? orgData.yearInBusiness : "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Website url:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {orgData.websiteUrl ? orgData.websiteUrl : "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Main phone number:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {orgData.mainPhoneNumber ? orgData.mainPhoneNumber : "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  About:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {orgData.about ? orgData.about : "N/A"}
                </Typography>
              </Grid>
            </Grid>

            <Divider textAlign="left" sx={{ mb: 2, mt: 2 }}>
              Organization address
            </Divider>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Address line 1:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {orgData.line1 ? orgData.line1 : "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Address line 2:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {orgData.line2 ? orgData.line2 : "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Postal Code:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {orgData.postalCode ? orgData.postalCode : "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  City:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {orgData.city ? orgData.city : "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  State:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {orgData.state ? orgData.state : "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Country:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {orgData.country ? orgData.country : "N/A"}
                </Typography>
              </Grid>
            </Grid>

            <Divider textAlign="left" sx={{ mb: 2, mt: 2 }}>
              Organization Data/Counters
            </Divider>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Total user:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {orgData._count.user ? orgData._count.user : "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Active user:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {orgData.activeUserCounter
                    ? orgData.activeUserCounter
                    : "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Pending member user:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {orgData._count.memberUser
                    ? orgData._count.memberUser
                    : "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Event:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {orgData._count.event ? orgData._count.event : "N/A"}
                </Typography>
              </Grid>
              {orgData.role === "Producer" ? (
                <>
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <Typography variant="subtitle1" color="text.secondary">
                      Booth:
                    </Typography>
                    <Typography
                      sx={{
                        paddingLeft: "4px",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {orgData._count.Booth ? orgData._count.Booth : "N/A"}
                    </Typography>
                  </Grid>
                </>
              ) : (
                <></>
              )}
              {orgData.role === "Producer" ? (
                <>
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <Typography variant="subtitle1" color="text.secondary">
                      Product:
                    </Typography>
                    <Typography
                      sx={{
                        paddingLeft: "4px",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {orgData._count.product ? orgData._count.product : "N/A"}
                    </Typography>
                  </Grid>
                </>
              ) : (
                <></>
              )}
              {orgData.role === "Consumer" ? (
                <>
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <Typography variant="subtitle1" color="text.secondary">
                      Roster product:
                    </Typography>
                    <Typography
                      sx={{
                        paddingLeft: "4px",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {orgData._count.IMSProduct
                        ? orgData._count.IMSProduct
                        : "N/A"}
                    </Typography>
                  </Grid>
                </>
              ) : (
                <></>
              )}

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Newswire post:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {orgData._count.NewsWirePost
                    ? orgData._count.NewsWirePost
                    : "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  RSS feed:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {orgData._count.RSSFeed ? orgData._count.RSSFeed : "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Connection:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {orgData.totalConnections ? orgData.totalConnections : "N/A"}
                </Typography>
              </Grid>
            </Grid>

            <Divider textAlign="left" sx={{ mb: 2, mt: 2 }}>
              Organization's default person
            </Divider>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Sales User:
                </Typography>
                <Stack
                  direction={"row"}
                  spacing={2}
                  sx={{
                    mt: 1,
                    cursor: orgData.salesUser ? "default" : "default",
                  }}
                >
                  <Avatar
                    src={orgData.salesUser?.userProfileImage}
                    alt={orgData.name}
                    sx={{ width: 60, height: 60 }}
                  />
                  <Typography
                    sx={{
                      paddingLeft: "4px",
                      fontWeight: "bold",
                      fontSize: "16px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {orgData.salesUser
                      ? `${orgData.salesUser.userName}`
                      : "N/A"}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Support User:
                </Typography>
                <Stack
                  direction={"row"}
                  spacing={2}
                  sx={{
                    mt: 1,
                    cursor: orgData.supportUser ? "default" : "default",
                  }}
                >
                  <Avatar
                    src={orgData.supportUser?.userProfileImage}
                    alt={orgData.name}
                    sx={{ width: 60, height: 60 }}
                  />
                  <Typography
                    sx={{
                      paddingLeft: "4px",
                      fontWeight: "bold",
                      fontSize: "16px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {orgData.supportUser
                      ? `${orgData.supportUser.userName}`
                      : "N/A"}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>

            <Divider textAlign="left" sx={{ mb: 2, mt: 2 }}>
              Market segments
            </Divider>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Market Segment:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {orgData.marketSegment.name || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Targeted market vericals:
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "4px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {orgData.marketSegment.marketVerticals.length === 0
                    ? "N/A"
                    : ""}
                  <Stack
                    spacing={1}
                    direction={"row"}
                    useFlexGap
                    sx={{ flexWrap: "wrap" }}
                  >
                    {orgData.marketSegment.marketVerticals?.map((e) => {
                      return <Chip key={e} variant="filled" label={e}></Chip>;
                    })}
                  </Stack>
                </Typography>
              </Grid>
            </Grid>

            {orgData.role === "Consumer" ? (
              <>
                <Divider textAlign="left" sx={{ mb: 2, mt: 2 }}>
                  Roster storage
                </Divider>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <Typography variant="subtitle1" color="text.secondary">
                      Storage used:
                    </Typography>
                    <Typography
                      sx={{
                        paddingLeft: "4px",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {orgData.rosterStorageDetails?.currentUsedStorageInBytes
                        ? formatBytes(
                            orgData.rosterStorageDetails
                              ?.currentUsedStorageInBytes
                          )
                        : "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <Typography variant="subtitle1" color="text.secondary">
                      Total storage:
                    </Typography>
                    <Typography
                      sx={{
                        paddingLeft: "4px",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {orgData.rosterStorageDetails?.totalStorageLimitInBytes
                        ? formatBytes(
                            orgData.rosterStorageDetails
                              ?.totalStorageLimitInBytes
                          )
                        : "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <Typography variant="subtitle1" color="text.secondary">
                      Max file upload size:
                    </Typography>
                    <Typography
                      sx={{
                        paddingLeft: "4px",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {orgData.rosterStorageDetails?.maxFileUploadSizeInBytes
                        ? formatBytes(
                            orgData.rosterStorageDetails
                              ?.maxFileUploadSizeInBytes
                          )
                        : "N/A"}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}

            <Divider textAlign="left" sx={{ mb: 2, mt: 2 }}>
              Actions on organization
            </Divider>
            <Grid container spacing={2}>
              {orgData?.organizationActive ? (
                <>
                  {" "}
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <Button
                      onClick={() => {
                        handleOpenDialogue(
                          orgData,
                          actions.deactivateOrganizationAccount
                        );
                      }}
                      color="error"
                      variant="outlined"
                    >
                      Deactivate organization
                    </Button>
                  </Grid>
                </>
              ) : (
                <></>
              )}

              {!orgData.organizationActive &&
              orgData?.organizationActiveStatus ===
                organizationActiveStatuses.ORGANIZATION_DEACTIVATED_BY_ADMIN ? (
                <>
                  {" "}
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <Button
                      onClick={() => {
                        handleOpenDialogue(
                          orgData,
                          actions.activateOrganizationAccount
                        );
                      }}
                      color="error"
                      variant="outlined"
                    >
                      Activate organization
                    </Button>
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Box>

          <Dialog
            open={openDialogue}
            onClose={handleCloseDialogue}
            sx={{ width: "800px", margin: "auto" }}
            PaperProps={{
              component: "form",
              onSubmit: (event) => {
                event.preventDefault();
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries(formData.entries());
                const confirmationTextByUser = formJson.confirmationText;
                const expectedConfirmationText = dialogueData.expectedLabel;
                if (expectedConfirmationText === confirmationTextByUser) {
                  // On text matches..........

                  // deactivateOrganizationAccount
                  if (
                    dialogueData.dialogueStatus ===
                    dialogueStatuses.deactivateOrganizationAccount
                  ) {
                    // API Call to delete account
                    const deactivateOrganizationAccountAPICall = async () => {
                      setLoader(true);
                      await deactivateOrganizationByAdmin(
                        dialogueData?.organizationId
                      )
                        .then((res) => {
                          if (res.data && res.data.status === "success") {
                            toast.success(`Deactivated successfully!`, {
                              position: "top-right",
                              autoClose: 3000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "colored",
                            });
                            // this is just for refreshing the model
                            fetchOrgDetails();
                          } else {
                            toast.error(`Not able to deactivate account!`, {
                              position: "top-right",
                              autoClose: 3000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "colored",
                            });
                          }
                        })
                        .catch((err) => {
                          toast.error(`Not able to deactivate account!`, {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                          });
                        });
                      // setLoader(false);
                    };

                    deactivateOrganizationAccountAPICall()
                      .then(() => {})
                      .catch(() => {})
                      .finally(() => {
                        handleCloseDialogue();
                      });
                  }

                  // deactivateOrganizationAccount
                  if (
                    dialogueData.dialogueStatus ===
                    dialogueStatuses.activateOrganizationAccount
                  ) {
                    // API Call to delete account
                    const activateOrganizationAccountAPICall = async () => {
                      setLoader(true);
                      await activateOrganizationByAdmin(
                        dialogueData?.organizationId
                      )
                        .then((res) => {
                          if (res.data && res.data.status === "success") {
                            toast.success(`Activated successfully!`, {
                              position: "top-right",
                              autoClose: 3000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "colored",
                            });
                            // this is just for refreshing the model
                            fetchOrgDetails();
                          } else {
                            toast.error(`Not able to activate account!`, {
                              position: "top-right",
                              autoClose: 3000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "colored",
                            });
                          }
                        })
                        .catch((err) => {
                          toast.error(`Not able to activate account!`, {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                          });
                        });
                      // setLoader(false);
                    };

                    activateOrganizationAccountAPICall()
                      .then(() => {})
                      .catch(() => {})
                      .finally(() => {
                        handleCloseDialogue();
                      });
                  }
                } else {
                  toast.error(`Hmm, Wrong text`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  });
                }
              },
            }}
          >
            {loader ? (
              <></>
            ) : (
              <>
                {dialogueData.showAlert ? (
                  <Alert
                    sx={{ fontSize: "20px" }}
                    severity={dialogueData.severity}
                  >
                    {dialogueData.alertText}
                  </Alert>
                ) : (
                  <></>
                )}
                <DialogTitle fontSize={"20px"}>
                  {" "}
                  {dialogueData.expectedLabel}{" "}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText fontSize={"15px"}>
                    To verify, type{" "}
                    <span style={{ color: "red" }}>
                      {dialogueData.expectedLabel}
                    </span>{" "}
                    below:
                  </DialogContentText>
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    name="confirmationText"
                    label=""
                    type="text"
                    fullWidth
                    variant="standard"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseDialogue}>Cancel</Button>
                  <Button type="submit">
                    {loader ? "Validating..." : "Submit"}
                  </Button>
                </DialogActions>
              </>
            )}
          </Dialog>
        </>
      )}
    </>
  );
}

export default OrgDetails;
