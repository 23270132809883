import {
  Alert,
  Autocomplete,
  Fab,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  createUpdateRSSFeedByAdmin,
  DeleteErrorLogs,
  deleteRSSFeedByAdmin,
  fetchPostTypeListForNewswire,
  getErrorList,
  getNewswirePostsWithFilters,
  getProductList,
  getRSSFeedWithFilters,
  getUnverifiedProducer,
} from "api/api";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import AddIcon from "@mui/icons-material/Add";

import Button from "@mui/material/Button";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import { BASE_URL } from "utils/constant";
import {
  Chip,
  Divider,
  Grid,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Loader from "ui-component/Loader";
import { useLocation, useNavigate, useParams } from "react-router";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  fetchOrganizationNewswirePosts,
  fetchOrganizationPlans,
} from "api/api";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import { AlertDialog, handleDateTime } from "utils/common";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { Settings } from "@mui/icons-material";

export default function OrganizationManageMainPage() {
  const [columns, setColumns] = useState([]);
  const [loader, setLoading] = useState(false)
  const [data, setData] = useState([]);

  const [pending, setPending] = React.useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [postTypeList, setPostTypeList] = useState([]);

  const [filterRssFeedType, setFilterRssFeedType] = useState("all");
  const [filterSearchText, setFilterSearchText] = useState("");

  const [metaData, setMetaData] = useState({});
  const [rssModalData, setRssModalData] = useState({});
  const [feedForm, setFeedForm] = useState({ name: "", url: "", id: null });

  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const theme = useTheme();

  // Media query to check screen size, e.g., smaller screens (600px and below)
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));

  // Extract filters from query params
  const organizationRoleFilter = searchParams.get("organizationRole") || "All";
  const organizationSubRoleFilter = searchParams.get("organizationSubRole") || "All";
  const orgPlanFilter = searchParams.get("orgPlan") || "All";

  const [searchText,setSearchText] = useState('')


  // State for filter values and the data from the API
  const [filterValues, setFilterValues] = useState({
    organizationRole:organizationRoleFilter,
    organizationSubRole:organizationSubRoleFilter,
    orgPlan:orgPlanFilter
  });

  // Function to update query parameters and trigger API call
  const handleFilterChange = (key, value) => {
    setFilterValues((prev) => ({ ...prev, [key]: value }));
    searchParams.set(key, value);
    navigate({
      pathname: "/dashboard/organizations",
      search: `?${searchParams.toString()}`,
    });
  };


  const [showFilterAlert, setShowFilterAlert] = useState(true);

  // Function to hide the filter alert
  const handleCloseFilterAlert = () => {
    setShowFilterAlert(false);
  };

  const handleOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setFeedForm({ name: "", url: "" });
    setOpen(false);
  };

  const [alertDialogopen, setAlertDialogopen] = React.useState(false);
  const [alertDialogData, setAlertDialogData] = React.useState({});

  const location = useLocation();

  const handleClickAlertDialogOpen = (data) => {
    setAlertDialogopen(true);
    setAlertDialogData(data);
  };

  const handleCloseAlertDialog = () => {
    setAlertDialogopen(false);
    setAlertDialogData({});
  };

  const handleActionsOnAlertDialog = async (data, action) => {
    switch (action) {
      case "Disagree":
        handleCloseAlertDialog();
        break;
      case "Agree":
        try {
          const response = await deleteRSSFeedByAdmin(data?.id);
          if (response.data.status === "success") {
            toast.success("Success", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            handleCloseAlertDialog();
          }
        } catch (error) {
          const errorMessage = error?.response?.data?.message;
          alert(errorMessage);
        }

        break;

      default:
        alert("Action not defined!");
    }
  };

  const handleRSSFeedAddNewButtonClick = () => {
    handleOpen();
    setRssModalData({
      title: `Add a new global RSS feed`,
      alertContent: `Note: This RSS feed is not tied to any specific organization. It is intended for global or VTC-level.`,
      showAlert: true,
    });
  };

  const handleModalSubmitButton = async () => {
    if (!(feedForm.name.length > 0 || feedForm.url.length > 0)) {
      alert("Enter data!");
    }

    const data = {
      id: feedForm.id || null,
      name: feedForm.name,
      url: feedForm.url,
      organizationId: metaData.organizationId,
    };

    try {
      const response = await createUpdateRSSFeedByAdmin({ ...data });
      if (response.data?.status === "success") {
        handleClose();
        toast.success("Success", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.log({ error });
      const errorMessage = error.response?.data?.message || "Error!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  // Fetch data from the server when filter values change
  const fetchOrganizationFilteredData = async () => {
    setLoading(true);

    try {
      const res = await axios.get("/system-admin/organizations", {
        params: {
          searchText: searchText || '',
          page: page - 1,
          pageSize: perPage,
          role:filterValues.organizationRole,
          subRole:filterValues.organizationSubRole,
          plan:filterValues.orgPlan
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
        },
        baseURL: BASE_URL,
      });

      setData(res.data.data);
      setTotalRows(res.data.totalCount);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRSSFeedEditClick = (data) => {
    handleOpen();
    setRssModalData({
      title: `Update rss feed`,
      alertContent: ``,
      showAlert: false,
    });
    setFeedForm({ name: data.feedName, url: data.url, id: data.id });
  };

  useEffect(() => {
    // Trigger any function or state update when query params change
     console.log('Query params changed:', location.search);
     // Create a URLSearchParams object with the search string
     const searchParams = new URLSearchParams(location.search);

     // Extract values from the query parameters
     const organizationRole = searchParams.get('organizationRole') || 'All';
     const organizationSubRole = searchParams.get('organizationSubRole') || 'All';
     const orgPlan = searchParams.get('orgPlan') || 'All';
    
    setFilterValues((pre)=>{
      return {
        organizationRole,
        organizationSubRole,
        orgPlan,
      }
    })

    // Any logic that you want to run on query param changes
    // For example, fetch data based on new query params
  }, [location.search]); // This dependency will re-run the effect when query params change

  const handleRowClick = (row) => {
    // Perform any actions based on the clicked row

    navigate(`/dashboard/organization/details/${row.id}`)
  };

  useEffect(() => {
    setColumns([
      {
        name: "Profile",
        selector: (row) => (
          <Avatar
            src={row.profileImage} // Adjust this key based on your data
            alt="Profile"
            sx={{ width: 40, height: 40 }}
          />
        ),
        maxWidth: "80px",
      },
      {
        name: "Organization",
        selector: (row) => row.name,
        sortable: false,
        maxWidth: "600px",
      },
      {
        name: "Role",
        selector: (row) => row.role,
        sortable: false,
      },
      {
        name: "Sub-role",
        selector: (row) => row.subRole,
        sortable: false,
      },
      {
        name: "Plan",
        selector: (row) => row.plan,
        sortable: false,
      },
      {
        name: "Created At",
        selector: (row) => handleDateTime(row.createdAt),
        sortable: true,
        maxWidth: "600px",
      },
      {
        name: (
          <Typography sx={{ fontFamily: "Roboto,sans-serif" }} variant="h5">
            {"Setting"}
          </Typography>
        ),
        cell: (row) => (
          <Link to={`/dashboard/organization-setting/${row.id}`}>
            <Settings
              color="action"
              sx={{ cursor: "pointer" }}
              // onClick={() => {
              //   setOpenModal(true);
              //   setUnverifiedId(row.organizationId);
              // }}
            />
          </Link>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
     
    ]);
  }, []);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleTableSorting = (columnName,sortDirection)=>{
    
  }

  // Fetch data when component mounts or query parameters change
  useEffect(() => {
    fetchOrganizationFilteredData();
  }, [page, perPage, searchText,filterValues.orgPlan,filterValues.organizationRole,filterValues.organizationSubRole]);

  return (
    <>
     
      <Box sx={{ display: "flex", gap: 2, mb: 2, background: "white", p: 2 }}>

      {/* Search Bar */}
      <FormControl fullWidth variant="outlined">
          <TextField
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "0px", // Make the border square
              },
            }}
            size="small"
            id="outlined-basic"
            label="Search"
            variant="outlined"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </FormControl>
        {/* Filter 1: Organization Role */}
        <FormControl fullWidth variant="outlined">
          <InputLabel>Organization Role</InputLabel>
          <Select
            label="Organization Role"
            size="small"
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "0px", // Make the border square
              },
            }}
            value={filterValues.organizationRole}
            onChange={(e) => handleFilterChange("organizationRole", e.target.value)}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Consumer">Consumer</MenuItem>
            <MenuItem value="Producer">Producer</MenuItem>
          </Select>
        </FormControl>

        {/* Filter 2: Organization Sub Role */}
        <FormControl fullWidth variant="outlined">
          <InputLabel>Organization Sub Role</InputLabel>
          <Select
            label="Organization Sub Role"
            size="small"
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "0px", // Make the border square
              },
            }}
            value={filterValues.organizationSubRole}
            onChange={(e) => handleFilterChange("organizationSubRole", e.target.value)}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="K-12">K-12</MenuItem>
            <MenuItem value="College">College</MenuItem>
            <MenuItem value="Team">Team</MenuItem>
            <MenuItem value="Venue">Venue</MenuItem>
            <MenuItem value="Vendor">Vendor</MenuItem>
            <MenuItem value="Consultant">Consultant</MenuItem>
            <MenuItem value="Vendor/Consultant">Vendor/Consultant</MenuItem>
            <MenuItem value="Freelancer">Freelancer</MenuItem>
            <MenuItem value="Property Manager">Property Manager</MenuItem>
            <MenuItem value="Event Organizer">Event Organizer</MenuItem>
            <MenuItem value="Architect">Architect</MenuItem>
          </Select>
        </FormControl>

        {/* Filter 3: Organization Subscription Plan */}
        <FormControl fullWidth variant="outlined">
          <InputLabel>Organization Subscription Plan</InputLabel>
          <Select
            value={filterValues.orgPlan}
            onChange={(e) => handleFilterChange("orgPlan", e.target.value)}
            label="Organization Subscription Plan"
            size="small"
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "0px", // Make the border square
              },
            }}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="VTCP1">Essential</MenuItem>
            <MenuItem value="VTCP2">Plus</MenuItem>
          </Select>
        </FormControl>
        

      </Box>



      <Box mt={1} overflow={"hidden"}>
        {loader ? <Loader/>:
        (
          <DataTable
          customStyles={{
            subHeader: {
              style: {},
            },
            header: {
              style: {
                borderTopLeftRadius: "10px", // Top left corner
                borderTopRightRadius: "10px", // Top right corner
              },
            },
          }}
          // title={<p>{"Organizations"}</p>}

          columns={columns}
          data={data}
          pagination
          onRowClicked={handleRowClick}
          // subHeader={true}
          // subHeaderComponent={subHeaderComponentMemo2}
          persistTableHead
          fixedHeader
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          highlightOnHover
          pointerOnHover
          onSort={(column, sortDirection) => handleTableSorting(column.name, sortDirection)} // Listen to sort event
          
        />
        )}
      </Box>

      <AlertDialog
        title={"Feed Delete Confirmation"}
        content={
          "Do you really want to delete this RSS feed? Please confirm your action."
        }
        onClose={handleCloseAlertDialog}
        open={alertDialogopen}
        actions={[
          {
            label: "No",
            onClick: handleActionsOnAlertDialog,
            action: "Disagree",
          },
          {
            label: "Yes",
            onClick: handleActionsOnAlertDialog,
            autoFocus: true,
            action: "Agree",
          },
        ]}
        data={alertDialogData}
      />
    </>
  );
}
