import React from "react";
import DataViewtable from "./DataViewtable";
import { ToastContainer } from "react-toastify";
import { borderBottom, Box, fontFamily, padding, styled } from "@mui/system";
import { Avatar, Button, Tab, Tabs, Typography } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LocationImport from "./LocationImport";
import ProductImport from "./ProductImport";
import LocationViewtable from "./LocationView";
import ConsumerProductViewTable from "./viewProductTable";
import CosnumerProductViewModal from "./ProductViewModal";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";

import { AppBar, Toolbar } from "@mui/material";
import { NavLink } from "react-router-dom";
import ImportLocationIcon from "@mui/icons-material/LocationOn";
import ImportProductIcon from "@mui/icons-material/LocalShipping";
import ViewLocationIcon from "@mui/icons-material/Map";
import ViewProductIcon from "@mui/icons-material/ViewList";
import { useEffect } from "react";
import { useState } from "react";
import { getOrganizationDetailsForRosterManagement } from "api/api";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function RosterImportcomponent() {

  const [data,setData] = useState({})

  const params = useParams()
 
  const navStyle = {
    textDecoration: 'none',
    fontSize:14,
    padding:2,
    color:'inherit',
    fontWeight:'bold',
  }
  
  const activeStyle = {
    ...navStyle,
    color: 'blue',
    borderBottom:'2px solid blue',
    
  };

  const inactiveStyle = {
    ...navStyle,
  };

  const fetchData = async()=>{
    try {
      const response = await getOrganizationDetailsForRosterManagement(params.id);
      setData(response.data);
    } catch (error) {
      alert('Error')
    }
  }

  useEffect(()=>{
    fetchData()
  },[])

  return (
    <div>
      <Box display="flex" alignItems={'center'}  justifyContent={'space-between'} sx={{backgroundColor:'white',mb:1,borderRadius:2}} gap={2}>
        <Box display="flex" alignItems={'end'} gap={2} sx={{padding:2,borderRadius:1}}>
          <NavLink
            to="."
            end
            style={({ isActive }) => isActive ? activeStyle : inactiveStyle}
          >
            Import Location
          </NavLink>
          <NavLink
            to="import-product"
            style={({ isActive }) => isActive ? activeStyle : inactiveStyle}
          >
            Import Product
          </NavLink>
          <NavLink
            to="view-location"
            style={({ isActive }) => isActive ? activeStyle : inactiveStyle}
          >
            View Location
          </NavLink>
          <NavLink
            to="view-product"
            style={({ isActive }) => isActive ? activeStyle : inactiveStyle}
          >
            View Product
          </NavLink>
          <NavLink
            to="roster-report"
            style={({ isActive }) => isActive ? activeStyle : inactiveStyle}
          >
            Roster Report
          </NavLink>
        </Box>
        <Box display={'flex'} alignItems={'center'} px={2} gap={1}>
          <Avatar src={data?.organizationProfileImage}></Avatar>
          <Typography variant="h5">{data?.organizationName}</Typography>
        </Box>
      </Box>

      <Outlet />
    </div>
  );
}
